import React, { Component } from 'react'
import { View } from 'react-native'
import base64 from 'react-native-base64'
import { Button } from 'react-native-elements'
// import { CreditCardInput } from "react-native-credit-card-input";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { connect } from 'react-redux'
import { EventService } from '../services/events'
import Alert from './Alert'
import LoadingModal from './LoadingModal'
import Cards from 'react-credit-cards';
import "react-credit-cards/es/styles-compiled.css";
import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
    formatFormData
  } from "../utils/ccutils";
import { Input } from 'react-native-elements/src/input/Input'
import Text from '../components/WebWedText'

class Checkout extends Component {
    state = {
        showCheckOutBtn: false,
        showCheckoutForm: false,
        loading: false,
        event: {},
        addons: [],
        ccName: '',
        ccNumber: '',
        ccExpiry: '',
        ccCvc: '',

    }

    componentDidMount = () => {
        const { event, addons, packageDetail } = this.props.route.params
        this.setState({ event, addons, packageDetail })
    }

    createEvent = async () => {

        // pay with
        const coverName = await base64.encode(this.state.event.eventTitle + "-" + this.props.user.uid)
        const eventData = {
            title: this.state.event.eventTitle,
            event_time: this.state.event.eventDate + " " + this.state.event.eventTime,
            package_id: this.state.packageDetail.id,
            addons: Object.values(this.state.addons).map((v, i) => (v.id)),
            cover_name: this.state.event.eventCover ? coverName : '',
            ccname: this.state.ccName,
            ccnumber: this.state.ccNumber,
            ccmonth: this.state.ccExpiry.split("/")[0],
            ccyear: this.state.ccExpiry.split("/")[1],
            cccvc: this.state.ccCvc,
        }

        this.setState({ loading: true, showCheckOutBtn: false })
        EventService.create(eventData).then(res => res.json()).then(json => {
            if (json.message) {
                if (this.state.event.eventCover) {

                    const uf = JSON.parse(json.upload_url)
                    EventService.uploadCover(uf.url, this.state.event.eventCover, uf.fields,'photo',coverName).then(res => res.text()).then(t => {
                        this.setState({ loading: false })

                        Alert.alert("Success!!", json.message, [
                            {
                                text: "OK",
                                onPress: () => {
                                    this.props.navigation.replace('Dashboard')
                                }
                            }
                        ]);

                    }).catch(err => {
                        this.setState({ loading: false })

                        console.log(err)
                    })
                }
                else {
                    this.setState({ loading: false })

                    Alert.alert("Success!!", json.message, [
                        {
                            text: "OK",
                            onPress: () => {
                                this.setState({ loading: false })

                                this.props.navigation.replace('Dashboard')
                            }
                        }
                    ]);

                }
            }
            else {
                this.setState({ loading: false })
                if (json.Message) {
                    Alert.alert("Failed!!", "Please try after sometime");
                }
                else {
                    Alert.alert("Failed!!", JSON.stringify(json));
                }
            }
        }).catch(err => {
            console.log(err);
            Alert.alert("Failed!!", "Something is not right as of now, please try again.", [{
                text: 'Okay', onPress: () => {
                    this.setState({ loading: false, showCheckOutBtn: true })
                }
            }])
        })

    }

    handleccName = t => {
        const isCCDetailsValid = t && this.state.ccNumber && this.state.ccExpiry && this.state.ccCvc
        this.setState({
            ccName: t,
            showCheckOutBtn: isCCDetailsValid
        })
    }

    handleccNumber = t => {
        const isCCDetailsValid = this.state.ccName && t && this.state.ccExpiry && this.state.ccCvc
        this.setState({
            ccNumber: formatCreditCardNumber(t),
            showCheckOutBtn: isCCDetailsValid
        })
    }

    handleccExpiry = t => {
        const isCCDetailsValid = this.state.ccName && this.state.ccNumber && t && this.state.ccCvc
        this.setState({
            ccExpiry: formatExpirationDate(t),
            showCheckOutBtn: isCCDetailsValid
        })
    }

    handleccCvc = t => {
        const isCCDetailsValid = this.state.ccName && this.state.ccNumber && this.state.ccExpiry && t
        this.setState({
            ccCvc: formatCVC(t),
            showCheckOutBtn: isCCDetailsValid
        })
    }

    ccChange = form => {
        this.setState({
            showCheckOutBtn: form.valid,
            ccName: form.values.name,
            ccNumber: form.values.number,
            ccExpiry: form.values.expiry,
            ccCvc: form.values.cvc,
            ccType: form.values.type,
        })
    }
    render() {
        return (
            <View style={{ position: "relative", flex: 1 }}>
                {this.state.loading && <LoadingModal />}
                
                    <View style={{ backgroundColor: "#f1f1f1", marginBottom: 40 }}>
                        <View style={{ justifyContent: "center", alignItems: "center", backgroundColor: "#FFF", paddingHorizontal: 10 }}>

                            <Text style={{ marginVertical: 20, fontSize: 12 }}>Enter payment details below to complete the checkout.</Text>
                        </View>
                        <View style={{ justifyContent: "center", alignItems: "center", paddingVertical: 20 }}>
                            {/* <CreditCardInput
                                horizontal={false}
                                focused={true}
                                labelStyle={{ marginTop: 10 }}
                                inputContainerStyle={{ marginBottom: 10, borderBottomWidth: 1 }}
                                expiryContainerStyle={{ flexDirection: "column" }}
                                cvcContainerStyle={{ flexDirection: "column" }}
                                requiresName={true}
                                onChange={this.ccChange} /> */}

                            <Cards
                                cvc={this.state.ccCvc}
                                expiry={this.state.ccExpiry}
                                focused={this.state.focus}
                                name={this.state.ccName}
                                number={this.state.ccNumber}
                            />

                            <Input inputContainerStyle={{ borderBottomWidth: 0 }} containerStyle={{marginTop:20}}
                        inputStyle={{ backgroundColor: 'white', padding: 10 }} placeholder="card holder name" keyboardType="default" onChangeText={this.handleccName} value={this.state.ccName}/>
                            <Input inputContainerStyle={{ borderBottomWidth: 0 }}
                        inputStyle={{ backgroundColor: 'white', padding: 10 }} placeholder="card number" keyboardType="number-pad" onChangeText={this.handleccNumber} value={this.state.ccNumber}/>
                            <Input inputContainerStyle={{ borderBottomWidth: 0 }}
                        inputStyle={{ backgroundColor: 'white', padding: 10 }} placeholder="card expiry" keyboardType="numbers-and-punctuation" onChangeText={this.handleccExpiry} value={this.state.ccExpiry}/>
                            <Input inputContainerStyle={{ borderBottomWidth: 0 }}
                        inputStyle={{ backgroundColor: 'white', padding: 10 }} placeholder="card cvc" keyboardType="numbers-and-punctuation" onChangeText={this.handleccCvc} value={this.state.ccCvc}/>
                        </View>
                    </View>
            
                <View style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
                    <Button disabled={!this.state.showCheckOutBtn} disabledStyle={{ backgroundColor: "#5aabd3" }} disabledTitleStyle={{ color: "#FFF" }} titleStyle={{ color: "#FFF" }} buttonStyle={{ backgroundColor: "#075193", paddingVertical: 15, borderRadius: 0 }} containerStyle={{ borderRadius: 0 }} title="CHECKOUT" onPress={this.createEvent} />
                </View>
            </View>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps)(Checkout);