import React, { Component } from 'react'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import EventDetail from '../pages/EventDetail';
import Participants from '../components/Participants';
import Guests from '../components/Guests';

const Tab = createMaterialTopTabNavigator();

class EventDetailTabs extends Component {
    
    
    render() {

        return (
            <Tab.Navigator
                tabBarOptions={{ labelStyle: { color: "#FFF",fontSize:12 }, style: { backgroundColor: "#5aabd3" } }}
                initialRouteName="Details"
                backBehavior="initialRoute">
                <Tab.Screen name="Details" component={EventDetail} />
                <Tab.Screen name="Participants"  component={Participants} options={{ params: { name: this.props.route.params.name } }} />
                <Tab.Screen name="Guests" component={Guests} />
            </Tab.Navigator>
        )
    }
}


export default EventDetailTabs;
