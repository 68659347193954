import React, { Component } from 'react'
import { Modal } from 'react-native'
import { View } from 'react-native'
import Timekeeper from 'react-timekeeper';

export default class TimeModal extends Component {
    state = { timeSelected: '10:00', timeForForm: '' }
    render() {
        return (
            <Modal
                visible={this.props.visible}
                transparent={true}
            >
                <View style={{ justifyContent: "center", alignItems: "center", flex: 1 }}>

                    <Timekeeper
                        switchToMinuteOnHourSelect={true}
                        time={this.state.timeSelected}
                        onChange={d => this.setState({ timeSelected: d.formatted, timeForForm: d })}
                        onDoneClick={() => this.props.selectTime(this.state.timeForForm)}
                    />
                </View>

            </Modal>
        )
    }
}
