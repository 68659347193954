import * as firebase from 'firebase';

var firebaseConfig = {
    apiKey: "AIzaSyAzgR_73_Kq5ap5diElOJ4Sj1LLfF3iRh4",
    authDomain: "webwedmobile-45835.firebaseapp.com",
    projectId: "webwedmobile-45835",
    storageBucket: "webwedmobile-45835.appspot.com",
    messagingSenderId: "156238855483",
    appId: "1:156238855483:web:7f9211df5b8b0a16f1cb1e",
    measurementId: "G-YT3T5TFWDN"
  };

export default firebase.initializeApp(firebaseConfig);
