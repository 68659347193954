import { confirmAlert } from 'react-confirm-alert'; // Import

export default class Alert {
    static alert(title,message,buttons){

        if(buttons){
            const btns = buttons.map((v,i) => ({ label: v.text,onClick: v.onPress }))
            confirmAlert({
                title: title, message: message, buttons: btns
            })    
        }
        else{
            confirmAlert({
                title: title, message: message,
                buttons:[{label:'Okay'}]
            })
    
        }
    }
}

