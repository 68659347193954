import * as Contacts from 'expo-contacts'
import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { connectActionSheet } from '@expo/react-native-action-sheet'
import { Button, Icon, ListItem } from 'react-native-elements'
import { ScrollView, Switch } from 'react-native'
import { connect } from 'react-redux'
import { color3, primaryColor } from '../configs/themeOptions'
import { EventService } from '../services/events'
import { getEventDetail, refreshEvent } from '../stores/actions'
import LoadingInfo from './LoadingInfo'
import Text from '../components/WebwedText'
import { TouchableOpacity } from 'react-native'
import Alert from '../components/Alert'

class Guests extends Component {
    state = {
        contacts: [],
        name: '',
        email: '',
        phone: '',
        event_status: true,
        showVideoInviteForm: false
    }


    openSheet = () => {
        const destructiveButtonIndex = 1;
        const cancelButtonIndex = 1;
        const options = ['Add manually', 'Cancel']
        this.props.showActionSheetWithOptions({ options, cancelButtonIndex, destructiveButtonIndex, },
            index => {
                switch (index) {
                    case 0:
                        console.log("Ev........", this.props.eventDetail)
                        this.props.navigation.push('ManualInvite', { title: 'Add Contact', is_participant: false, eventDetail: this.props.eventDetail, refreshEvent: this.refreshEvent })
                        break;
                    case 1:
                        this.importContact()

                        break;
                    case 2:
                        break;
                }
            }
        )
    }

    toggleEventStatus = status => {
        EventService.toggleStatus(this.props.eventDetail.id).then(res => res.json()).then(json => {
            this.props.getEventDetail(this.props.eventDetail.id)

        }).catch(err => {
            this.setState({ loading: false });
        })
    }

    saveInvite = guest => {
        const eventID = this.props.eventDetail.id
        if (!guest) {

            guest = {
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                is_participant: false
            }
        }
        EventService.inviteGuest(eventID, guest).then(res => res.json()).then(json => {
            if (json.message) {
                // this.props.getEventDetail(eventID);

                // this.props.update current event with guest 

                this.props.getEventDetail(eventID);
                Alert.alert("Success!", json.message, [
                    { text: "Okay" }
                ]);
            }
            else {
                Alert.alert("Error!", json.error);
            }
        }).catch(err => {
            Alert.alert("Oops!", "Something was not expected");
        })
    }

    getStatusIconName = status => {
        console.log(status)
        switch (status) {
            case 'EventInvites.accepted':
                return 'done-all'
            case 'EventInvites.declined':
                return 'clear'
            case 'EventInvites.not_accepted':
            default:
                return 'done'
        }
    }

    getStatusName = status => {

        if (status) {


            const statusText = status.split(".").pop()
            if (statusText == 'not_accepted') {
                return false
            }
            else {
                return statusText
            }
        }
        else {
            return false
        }
    }

    getStatusColorName = status => {
        switch (status) {
            case 'EventInvites.accepted':
                return 'green'
            case 'EventInvites.declined':
                return 'tomato'
            case 'EventInvites.not_accepted':
            default:
                return 'gray'
        }
    }

    importContact = async () => {

        this.setState({ showContacts: true })
        const { status } = await Contacts.requestPermissionsAsync();
        if (status === 'granted') {
            const { data } = await Contacts.getContactsAsync();
            const contacts = data.filter((v, i) => (v.emails && v.firstName && v.lastName))
            this.props.navigation.navigate('ImportContact', { contacts, title: 'Import Contacts', is_participant: false })
        }

    }

    selectContact = contact => {

        const phone = contact.phoneNumbers ? contact.phoneNumbers[0].number : '';
        this.setState({
            email: contact.emails[0].email,
            name: contact.firstName + " " + contact.lastName,
            phone: phone
        });
        this.saveInvite({
            email: contact.emails[0].email,
            name: contact.firstName + " " + contact.lastName,
            phone: phone,
            is_participant: false
        })
    }

    refreshEvent = eventDetail => {
        this.setState({
            guests: eventDetail.guests
        })
    }

    openVideoInvite = () => {
        if (this.props.eventDetail.guests.length > 0) {
            this.props.navigation.push("VideoInvite", { eventID: this.props.eventDetail.id })
        }
        else {
            Alert.alert("Failed!!", "No guests added, please invite guests first.")
        }
    }

    removeGuest = guest => {
        Alert.alert("Confirm", `Are you sure to remove ${guest.name}?`, [
            {
                text: 'Remove',
                onPress: () => {
                    EventService.removeParticipant(this.props.eventDetail.id, guest.id).then(res => res.json()).then(json => {
                        Alert.alert("Success!!", `${guest.name} removed from guest list`, [{
                            text: "Okay", onPress: () => {
                                this.props.getEventDetail(this.props.eventDetail.id)
                            }
                        }])
                    }).catch(err => console.log(err))
                }
            },
            {
                text: 'Cancel',
                cancel: 'cancel'
            }
        ])
    }

    render() {
        return (
            <View style={{ flex: 1 }}>
                <ScrollView style={styles.container}>
                    <ListItem>
                        <ListItem.Content>
                            <ListItem.Title style={{ fontFamily: '"Roboto","Helvetica Neue", sans-serif' }}>
                                Make Public
                            </ListItem.Title>
                        </ListItem.Content>
                        {this.state.loading ? <LoadingInfo spinColor={color3} /> : <Switch value={this.props.eventDetail.is_public ? true : false} onValueChange={this.toggleEventStatus} />}
                    </ListItem>

                    <View style={styles.total}>
                        <Text style={styles.totalText}>{this.props.eventDetail.guests.length} Total Guests</Text>
                    </View>
                    <View style={styles.total}>
                        <Button title="Invite Guest" buttonStyle={{ paddingVertical: 5, borderRadius: 10 }} titleStyle={{ fontFamily: '"Roboto","Helvetica Neue", sans-serif' }} onPress={this.openSheet} />
                    </View>

                    <View>
                        {this.props.eventDetail.guests.map(
                            (guest, i) => guest.email ? (<ListItem key={i} bottomDivider>
                                {/* <Icon type="materialicon" name={this.getStatusIconName(guest.invite_status)} color={this.getStatusColorName(guest.invite_status)} /> */}

                                <ListItem.Content>
                                    <ListItem.Title style={{ fontFamily: '"Roboto","Helvetica Neue", sans-serif' }}>
                                        {guest.name}
                                    </ListItem.Title>
                                    <ListItem.Subtitle style={{ fontFamily: '"Roboto","Helvetica Neue", sans-serif' }}>
                                        {guest.email}
                                    </ListItem.Subtitle>
                                    <ListItem.Subtitle style={{ fontFamily: '"Roboto","Helvetica Neue", sans-serif' }}>
                                        {guest.phone}
                                    </ListItem.Subtitle>
                                </ListItem.Content>

                                <TouchableOpacity onPress={() => this.removeGuest(guest)}>
                                    <Icon
                                        name="md-close"
                                        reverse
                                        color="#F051B9"
                                        reverseColor="#FFF"
                                        containerStyle={{ padding: 0, margin: 0, height: 20, width: 20, borderRadius: 10, justifyContent: "center", alignItems: "center" }}
                                        iconStyle={{ margin: 0, padding: 0, fontWeight: "700" }}
                                        size={18}
                                        type="ionicon" />
                                </TouchableOpacity>
                            </ListItem>) : <></>
                        )}
                    </View>

                </ScrollView>

                <View style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
                    <Button title="Video Invite" onPress={this.openVideoInvite} titleStyle={{ fontSize: 18, fontFamily: '"Roboto","Helvetica Neue", sans-serif' }} buttonStyle={{ backgroundColor: primaryColor, borderRadius: 0, padding: 15 }} containerStyle={{ borderRadius: 0 }} />
                </View>
            </View>
        )
    }
}

const mapStateToProps = (state) => ({
    eventDetail: state.eventDetail
})

const mapDispatchToProps = dispatch => {
    return {
        refreshGuestList: eventDetail => dispatch(refreshEvent(eventDetail)),
        getEventDetail: eventID => dispatch(getEventDetail(eventID))

    }
}

const connectedParticipants = connectActionSheet(Guests)

export default connect(mapStateToProps, mapDispatchToProps)(connectedParticipants);

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    pbBox: {
        padding: 10,
        backgroundColor: "#FFF"
    },
    total: {
        flex: 1,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginBottom: 20
    },
    totalText: {
        fontSize: 16,
        marginTop: 10,
        // fontWeight:"bold"
    },
    subtitle: {
        color: "gray"
    }

})
