import * as actionTypes from "./actionTypes";
const intialState = {
    loading: false,
    message: '',
    orders: [],
    packages: [{ title: '', price: '' }],
    roles: [],
    courts: [],
    addons: [],
    notifications: [],
    events: [],
    archives: [],
    eventDetail: { addons: [], title: '', participants: [], guests: [], host: { uid: '' },invitations:[] },
    isAuthenticated: false,
    user: {},
    officiants: [],
    profile: {},
    cameraPosition: 'front',
    err: "",
};
export default (state = intialState, action) => {
    switch (action.type) {
        case actionTypes.START_LOADING:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.HTTP_FAILED:
            return {
                ...state,
                loading: false,
                err: action.err
            }
        case actionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                user: action.user
            };
        case actionTypes.LOGIN_FAILED:
            return {
                ...state,
                loading: false,
                err: action.err,
            };
        case actionTypes.LOGOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
            };

        case actionTypes.PACKAGE_ADDONS_SUCCESS:
            return {
                ...state,
                loading: false,
                packages: action.packages,
                addons: action.addons
            }
        case actionTypes.PACKAGE_ADDONS_FAILED:
            return {
                ...state,
                loading: false,
                err: action.err
            }
        case actionTypes.EVENT_CREATE_SUCCESS:
            return {
                ...state,
                message: action.message,
                loading: false
            }
        case actionTypes.EVENT_CREATE_FAILED:
            return {
                ...state,
                loading: false,
                err: action.err
            }
        case actionTypes.COVER_UPLOAD_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actionTypes.COVER_UPLOAD_FAILED:
            return {
                ...state,
                loading: false,
                err: action.err
            }

        case actionTypes.GET_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                orders: action.orders
            }
        case actionTypes.NOTIFICATIONS_SUCCESS:

            return {
                ...state,
                loading: false,
                notifications: action.notifications
            }
        case actionTypes.GET_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                events: action.events
            }
        case actionTypes.EVENT_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                eventDetail: action.eventDetail
            }
        case actionTypes.ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                roles: action.roles
            }
        case actionTypes.COURT_SUCCESS:
            return {
                ...state,
                loading: false,
                courts: action.courts
            }

        case actionTypes.REFRESH_EVENT:

            return {
                ...state,
                loading: false,
                eventDetail: action.eventDetail
            }
        case actionTypes.BLANK_ERR:

            return {
                ...state,
                loading: false,
                err: action.err
            }
        case actionTypes.USER_PROFILE:
            return {
                ...state,
                loading: false,
                is_officiant:action.is_officiant,
                profile: action.profile
            }
        case actionTypes.TOGGLE_VIDEO:
            return {
                ...state,
                cameraPosition: action.cameraPosition
            }
        case actionTypes.ARCHIVES_SUCCESS:
            return {
                ...state,
                archives: action.archives
            }
        case actionTypes.OFFICIANT_LIST:

            return {
                ...state,
                officiants: action.officiants,
                loading: false
            }
        case actionTypes.FLIP_CAMERA:
            return {
                ...state,
                cameraPosition: action.cameraPosition
            }
        default:
            return state;
    }
};
