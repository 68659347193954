import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
import { connect } from 'react-redux'
import AddParticipant from './AddParticipant'
import ParticipantItem from './ParticipantItem'
import { connectActionSheet } from '@expo/react-native-action-sheet'
import Text from '../components/WebWedText'
import * as Contacts from 'expo-contacts';
import { refreshEvent } from '../stores/actions'
import { ScrollView } from 'react-native'

class Participants extends Component {

    state = {
        roles: [],
        contacts: [],
        showContacts: false,
        modalVisible: false,
        officiantsModal: false,
        officiantProfileModal: false,
        participantName: '',
        participantEmail: '',
        role: '',
        loading: false,
        officiants: [],
        participants: [],
        currentOfficiant: {
            user: {
                full_name: '',
                profile: {
                    phone: '',
                    address: '',
                    mailing_address: ''
                }
            }
        }
    }

    componentDidMount() {
        // this.remainingRoles();
        this.setState({ participants: this.props.eventDetail.participants })
        
    }

    refreshParticipants = eventDetail => {
        this.props.refreshEvent(eventDetail)
    }
    openSheet = () => {
        const destructiveButtonIndex = 0;
        const cancelButtonIndex = 2;
        const options = ['Add manually', 'Pre-approved officiants', 'Cancel']

        this.props.showActionSheetWithOptions({ options, cancelButtonIndex, destructiveButtonIndex, },
            index => {
                switch (index) {
                    case 0:
                        this.props.navigation.push('ManualInvite', { title: 'Add Contact', is_participant: true, eventDetail: this.props.eventDetail, refreshEvent: this.refreshParticipants })
                        return;
                    case 1:
                        this.props.navigation.push('FindOfficiant', 'Find Officiant')
                        return;
                    case 2:
                        return;
                }
            }
        )
    }

    importContact = async () => {

        this.setState({ showContacts: true })
        const { status } = await Contacts.requestPermissionsAsync();
        if (status === 'granted') {

            // this.setState({ contacts: contacts.filter((v, i) => (v.emailAddresses.length > 0)), showContacts: true })

            const { data } = await Contacts.getContactsAsync();
            const contacts = data.filter((v, i) => (v.emails && v.firstName && v.lastName))
            this.props.navigation.navigate('ImportContact', { contacts, title: 'Import Contacts', is_participant: true })
        }



    }

    render() {
        // this.remainingRoles();
        console.log(this.props.eventDetail)
        const addForms = []
        const addSize = 4 - this.props.eventDetail.participants.length;
        for (let i = 0; i < addSize; i++) {
            addForms.push(<AddParticipant key={i} onPress={this.openSheet} />)
        }

        return (
            <ScrollView style={styles.container}>
                <Text style={styles.title}> Select upto 4 additional participants </Text>
                <Text style={styles.subTitle}> <Text style={{ fontWeight: "bold" }}>Important:</Text> Participants should be added for live  video events only. If you intend to use a single broadcast you should skip this step, regardless of the participants in your actualy party.</Text>
                {/* <Text onPress={this.openSheet}>Open actionsheet</Text> */}

                <View style={{ flexDirection: "row", flexWrap: "wrap", alignItems: "center", justifyContent: "center", margin: 20 }}>

                    {this.props.eventDetail.participants.map((v, i) => {
                        return (<ParticipantItem key={i} onPress={this.openSheet} participant={v} eventID={this.props.eventDetail.id} />)
                    })}

                    {addForms}

                </View>

                {/* <ActionSheet
                    ref={o => this.ActionSheet = o}
                    title={'Add participants'}
                    options={['Add manually', 'Add from contacts', 'Pre-approved officiants', 'Cancel']}
                    // cancelButtonIndex={2}
                    destructiveButtonIndex={3}
                    cancelButtonIndex={3}
                    onPress={(index) => {
                        switch (index) {
                            case 0:
                                this.props.navigation.push('ManualInvite',{title:'Add Contact',is_participant:true,eventDetail:this.props.eventDetail,refreshEvent: this.refreshParticipants})
                                return;
                            case 1:
                                this.importContact()
                                return;
                            case 2:
                                this.props.navigation.push('FindOfficiant','Find Officiant')
                                return;
                            case 3:
                                return;
                        }
                    }}
                /> */}

            </ScrollView>
        )
    }
}

const mapStateToProps = (state) => ({
    eventDetail: state.eventDetail,
    roles: state.roles,
})

const mapDispatchToProps = dispatch => {
    return {
        refreshEvent: eventDetail => dispatch(refreshEvent(eventDetail))
    }
}
const connectedParticipants = connectActionSheet(Participants)
export default connect(mapStateToProps, mapDispatchToProps)(connectedParticipants)

const styles = StyleSheet.create({

    container: {
        flex: 1,
        padding: 10,
        backgroundColor: '#FFF'
    },
    title: {
        fontSize: 16,
        marginVertical: 10,
        alignContent: "center",
        alignSelf: "center",
        fontWeight: "400"
    },
    subTitle: {
        marginVertical: 10
    }
})