import React, { Component } from 'react';
import { ScrollView, View } from 'react-native';
import { Button, ListItem } from 'react-native-elements';
import { color4, primaryColor, secondaryColor } from '../configs/themeOptions';
import Text from '../components/WebwedText'

export default class OrderReview extends Component {

    state = {
        event: {},
        addons: [],
        packageDetail: {}
    }

    componentDidMount = () => {
        const { event, addons, packageDetail } = this.props.route.params
        this.setState({ event, addons, packageDetail })
    }

    calculatePrice = () => {

        let price = this.state.packageDetail.price

        if (this.state.addons.length > 0) {
            price += this.state.addons.reduce(
                (cnt, n) => (parseFloat(cnt + n.price)), 0)
        }

        return price
    }
    goToCheckout = () => {
        this.props.navigation.navigate('Checkout', {
            event: this.state.event,
            addons: this.state.addons,
            packageDetail: this.state.packageDetail,
        })
    }
    render() {
        return (
            <View style={{ position: "relative", flex: 1 }}>
                <ScrollView style={{ backgroundColor: "#f1f1f1" }}>
                    <View>
                        <View style={{ justifyContent: "center", alignItems: "center", backgroundColor: "#FFF", paddingHorizontal: 10 }}>

                            <Text style={{ marginVertical: 20, fontSize: 12 }}>All the participants will be granted access to modify details and manage guest list on your behalf.</Text>
                        </View>

                        <Text style={{color:"#a2a2a2",fontSize:14,margin:10}}>ORDER DETAIL</Text>

                        {this.state.packageDetail && <ListItem style={{ backgroundColor: secondaryColor }} bottomDivider>
                                <ListItem.Content>
                                    <ListItem.Title style={{ color: primaryColor,fontFamily:'"Roboto","Helvetica Neue", sans-serif' }}>
                                        {this.state.packageDetail.title}
                                    </ListItem.Title>

                                </ListItem.Content>
                                <ListItem.Subtitle>
                                    {"$ " + this.state.packageDetail.price}
                                </ListItem.Subtitle>
                            </ListItem>
                        }

                        {this.state.addons.map((v, i) => {
                            return (

                                <ListItem key={i} style={{ backgroundColor: secondaryColor }} bottomDivider>
                                    <ListItem.Content>
                                        <ListItem.Title style={{ color: primaryColor,fontFamily:'"Roboto","Helvetica Neue", sans-serif' }}>
                                            {v.title}
                                        </ListItem.Title>

                                    </ListItem.Content>
                                    <ListItem.Subtitle style={{fontFamily:'"Roboto","Helvetica Neue", sans-serif'}}>
                                        {"$ " + v.price}
                                    </ListItem.Subtitle>
                                </ListItem>)
                        })}

                        <ListItem containerStyle={{ backgroundColor: secondaryColor }} bottomDivider>
                            <ListItem.Content>
                                <ListItem.Title style={{ color: primaryColor,fontFamily:'"Roboto","Helvetica Neue", sans-serif' }}>
                                    Order Total
                                </ListItem.Title>
                            </ListItem.Content>
                            <ListItem.Subtitle style={{fontFamily:'"Roboto","Helvetica Neue", sans-serif'}}>
                                {"$ " + this.calculatePrice()}
                            </ListItem.Subtitle>
                        </ListItem>
                    </View>
                </ScrollView>
                <View style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
                    <Button disabledStyle={{ backgroundColor: "#5aabd3" }} disabledTitleStyle={{ color: "#FFF" }} titleStyle={{ color: "#FFF",fontFamily:'"Roboto","Helvetica Neue", sans-serif' }} buttonStyle={{ backgroundColor: "#075193", paddingVertical: 15, borderRadius: 0 }} containerStyle={{ borderRadius: 0 }} title="SUBMIT" onPress={this.goToCheckout} />
                </View>
            </View>
        )
    }
}
