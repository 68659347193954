import React, { Component } from 'react'
import { View } from 'react-native'
import { Icon } from 'react-native-elements'
import { TouchableOpacity } from 'react-native'

export default class NavigationRightIcons extends Component {

    openNotifications = () => {
        this.props.navigation.navigate('Notifications', { name: 'Notifications' })
    }

    openDashboard = () => {
        this.props.navigation.navigate('Dashboard')
    }
    openProfile = () => {
        this.props.navigation.navigate('ProfileTabs', { screen: 'Profile', name: "My Profile" })
    }

    render() {
        return (
            <View style={{
                paddingVertical: 10,
                paddingHorizontal: 10,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            }}>
                <TouchableOpacity onPress={this.openDashboard}>
                    <Icon type="ionicon" name="md-heart" size={18} style={{ marginHorizontal: 10 }} color="white" />
                </TouchableOpacity>
                <TouchableOpacity onPress={this.openNotifications}>
                    <Icon type="ionicon" name="md-notifications" size={18} style={{ marginHorizontal: 10 }} color="white" />
                </TouchableOpacity>
                <TouchableOpacity onPress={this.openProfile} >
                    <Icon type="ionicon" name="md-person" size={18} style={{ marginHorizontal: 10 }} color="white" />
                </TouchableOpacity>
            </View>
        )
    }
}
