import React, { Component } from 'react'
import { Alert, ImageBackground, RefreshControl, StyleSheet, View } from 'react-native'
import { Button, SearchBar } from 'react-native-elements'
import { ScrollView } from 'react-native'
import { connect } from 'react-redux'
import EventItem from '../components/EventItem'
import NoEvent from '../components/NoEvent'
import { secondaryColor } from '../configs/themeOptions'
import { EventService } from '../services/events'
import { getEvents, updateCurrentEvent } from '../stores/actions'
import Text from '../components/WebwedText'

const img = require('../assets/images/background.png');

class EventList extends Component {

    state = {
        searchText: '',
        refreshing: false,
        disabled: false
    }

    onRefresh = () => {
        this.props.getEvents();
    }

    handleSearch = () => {
        const code = this.state.searchText;
        EventService.getEventByCode(code).then(res => res.json()).then(json => {
            console.log(json)
            if (json.event_details) {
                const eventDetail = json.event_details
                if (eventDetail.host.uid == this.props.user.uid) {
                    this.props.navigation.navigate('EventDetailTabs', { name: eventDetail.title, screen: 'Details', params: { eventID: eventDetail.id } });
                }
                else {
                    const participant_emails = eventDetail.participants.filter((v, i) => (v.email == this.props.user.email))
                    if (participant_emails.length > 0) {
                        this.props.navigation.navigate('LiveStream', { name: eventDetail.title, 'eventDetail': eventDetail, 'eventID': eventDetail.id, isParticipant: true });
                    }
                    else {
                        this.props.navigation.navigate("Viewer", { viewerName: this.props.user.email, eventID: eventDetail.code });
                    }
                }
                // this.props.navigation.push('EventDetail', { screen: 'EventDetailTabs', params: { name: eventDetail.title, screen: 'Details', params: { eventID: eventDetail.id } }, eventID: eventDetail.id });

                // this.props.navigation.push('EventDetail',{eventID:json.event_details.id})
            }
            else {
                Alert.alert("Not invited!!", "You are not invited.");
                this.setState({ searchText: "" })
            }

        }).catch(err => {
            Alert.alert("Failed!!", "Something went wrong."+err.message)
        });
    }

    updateSearch = (e) => {
        this.setState({
            searchText: e
        })
    }

    componentDidMount() {
        this.props.getEvents();
    }

    gotoCreateEvent = () => {
        this.props.navigation.push('CreateEvent');
    }
    render() {
        return (
            <>
                <ScrollView style={[styles.container,{marginBottom: this.props.is_officiant ? 0 : 40}]} refreshControl={
                    <RefreshControl refreshing={this.props.loading} onRefresh={this.onRefresh} />}  >
                    <View style={styles.containerInner}>
                        <ImageBackground source={img} style={styles.bgImage}>
                            <View style={{ flex: 1 }}>
                                <View style={{ justifyContent: "center", flex: 1, alignItems: "center" }}>
                                    <Text style={{ marginVertical: 15, fontSize: 18, fontWeight: "300", color: "#FFF" }}>Join An Event</Text>
                                </View>

                                <View style={{ flex: 1 }}>
                                    <SearchBar placeholder="Know the event ID#?"
                                        // keyboardType="numeric"
                                        returnKeyLabel="Join"
                                        returnKeyType="join"
                                        onChangeText={this.updateSearch}
                                        onSubmitEditing={this.handleSearch}
                                        containerStyle={{
                                            backgroundColor: "transparent",
                                            borderBottomColor: 'transparent',
                                            borderTopColor: 'transparent'
                                        }}
                                        inputContainerStyle={{ backgroundColor: "#FFF" }}
                                        value={this.state.searchText} />
                                </View>
                            </View>
                        </ImageBackground>
                    </View>
                    <View style={styles.eventList}>
                        {
                            (this.props.events && this.props.events.length > 0) ? this.props.events.map((v, i) => (<EventItem key={i} event={v} navigation={this.props.navigation} />)) : <NoEvent />
                        }
                    </View>

                </ScrollView>
                {!this.props.is_officiant && <View style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
                    <Button onPress={this.gotoCreateEvent} title="CREATE AN EVENT" titleStyle={{ fontSize: 18, fontFamily: '"Roboto","Helvetica Neue", sans-serif' }} buttonStyle={{ backgroundColor: secondaryColor, borderRadius: 0, padding: 15 }} containerStyle={{ borderRadius: 0 }} />
                </View>}
            </>
        )
    }
}

const mapStateToProps = state => ({
    events: state.events,
    user: state.user,
    is_officiant:state.is_officiant,
    loading: state.loading
})

const mapDispatchToProps = dispatch => {
    return {
        getEvents: () => dispatch(getEvents()),
        updateCurrentEvent: json => dispatch(updateCurrentEvent(json))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventList);

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#d3dbdf',
    },
    containerInner: {
        height: 200,
        // alignItems:"center",
        backgroundColor: 'rgb(94, 172, 209)'
    },
    bgImage: {
        flex: 1,
        resizeMode: "center"
    },
    eventList: {
        marginBottom: 40
    },
    searchContainer: { backgroundColor: 'white', borderBottomWidth: 0, alignSelf: 'center', borderRadius: 5 },
    searchInput: { backgroundColor: 'white', borderRadius: 5, marginBottom: 0 },
    searchIcon: { paddingHorizontal: 10 }
}) 
