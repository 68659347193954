
import React from 'react';
import { StyleSheet } from 'react-native';
import { Provider } from 'react-redux';

import { store } from './stores';
import Layout from './pages/Layout';

import './configs/firebase';
import { StatusBar } from 'expo-status-bar';
import {
  ActionSheetProvider
} from '@expo/react-native-action-sheet';
import { AppLoading } from 'expo';
import {
  useFonts,
  Roboto_100Thin
} from '@expo-google-fonts/roboto';

export default function App() {
  let [fontsLoaded] = useFonts({
    Roboto_100Thin,
  });
  if (!fontsLoaded) {
    return <AppLoading />;
  } else {
    return (
      <Provider store={store}>
        <ActionSheetProvider>
          <Layout />
        </ActionSheetProvider>
        <StatusBar />
      </Provider>
    );
  }
}
