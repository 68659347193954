import React, { Component } from 'react'
import { View, Image } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { Icon } from 'react-native-elements';

export default class NavigationDrawerIcon extends Component {
    toggle = () => {
        this.props.navigationProps.toggleDrawer();
    }
    render() {
        return (
            <View>
                <TouchableOpacity onPress={this.toggle}>

                    {   
                        this.props.icon ? (
                            <Icon type="ionicon" name={this.props.icon} size={this.props.iconSize} style={{marginLeft:10}} />
                        ) : (<Image
                            source={require('../assets/images/drawerWhite.png')}
                            style={{ width: 25, height: 25, marginLeft: 5, marginRight: 0 }}
                        />)
                    }

                </TouchableOpacity>
            </View>
        )
    }
}
