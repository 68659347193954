import React, { Component } from 'react';
import { View } from 'react-native';
import { Button, Input } from 'react-native-elements';
import { ScrollView } from 'react-native-gesture-handler';
import { EventService } from '../services/events';
import Alert from './Alert';
import WebwedPicker from './WebwedPicker';

export default class VideoInviteForm extends Component {

    state = {
        eventCover: '',
        subject: '',
        body: '',
        subjectErr: '',
        bodyErr: '',
        loading: false
    }

    handleSubject = v => {
        this.setState({ subject: v, subjectErr: (!v ? "Subject required" : '') })
    }

    handleBody = v => {
        this.setState({ body: v, bodyErr: (v.length < 20 ? "Body required" : '') })
    }

    saveInvite = async () => {
        this.setState({ loading: true })
        EventService.sendVideoInvite(
            this.props.route.params.eventID,
            { subject: this.state.subject, body: this.state.body, video: this.state.eventCover })
            .then(res => res.json()).then(json => {
                this.setState({ loading: false });
                const d = new Date()
                const vn = this.props.route.params.eventID+"-invite-"+d.getSeconds()
                EventService.uploadCover(json.url.url, this.state.eventCover, json.url.fields,'video',vn).then(res => res.text()).then(json => {
                }).catch(err => {
                    console.log("Err...", err)
                })

                if (json.message) {
                    Alert.alert("Success", json.message, [{
                        text: "Okay", onPress: () => {
                            this.props.navigation.goBack()
                        }
                    }]);

                }
            }).catch(err => {
                this.setState({ loading: false });

                console.log("Errr...", err)
            })

    }
    render() {
        return (

            <View style={{ position: "relative", flex: 1 }}>
                <ScrollView>

                    <WebwedPicker navigation={this.props.navigation} video={true} containerStyle={{ marginBottom: 10 }} icon="md-videocam" color="white" title="Add Video" onPress={eventCover => { this.setState({ eventCover: eventCover }) }} />

                    <View>
                        <Input
                            placeholder="Subject"
                            onChangeText={this.handleSubject}
                            value={this.state.subject}
                            renderErrorMessage
                            errorMessage={this.state.subjectErr} />
                        <Input
                            placeholder="Message body"
                            multiline
                            numberOfLines={10}
                            textAlignVertical="top"
                            onChangeText={this.handleBody}
                            value={this.state.body}
                            renderErrorMessage
                            errorMessage={this.state.bodyErr}
                        />
                    </View>

                </ScrollView>
                <View style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
                    <Button buttonStyle={{ padding: 20, backgroundColor: "#00bcd4" }} title="SAVE AND CONTINUE" onPress={this.saveInvite} />
                </View>
            </View>

        )
    }
}
