import React, { Component } from 'react';
import { View } from 'react-native';
import { Button, Input } from 'react-native-elements';
import RNPickerSelect from 'react-native-picker-select';
import { connect } from 'react-redux';
import Alert from '../components/Alert';
import { primaryColor } from '../configs/themeOptions';
import { EventService } from '../services/events';
import { MastersService } from '../services/masters';
import { getEventDetail, refreshEvent } from '../stores/actions';
import { ucWords } from '../utils/convrters';


class ManualInvite extends Component {
    state = { roles: [], role: {}, participantName: '', participantEmail: '', phone: '', is_participant: false }
    handlePhone = phone => {
        this.setState({ phone: phone });
    }

    handleEmail = email => {
        this.setState({ participantEmail: email })
    }

    handleName = name => {
        this.setState({ participantName: name })
    }

    chooseRole = (itemValue) => {
        const role = this.state.roles.filter( v => v.title==itemValue)[0]
        this.setState({ role: role})
    }

    saveInvite = () => {
        const eventID = this.props.route.params.eventDetail.id
        let guest = {
            name: this.state.participantName,
            email: this.state.participantEmail,
            phone: this.state.phone,
            is_participant: this.state.is_participant
        }

        if (this.state.role != 'select role') {
            guest["role"] = this.state.role.title;
        }
        else {
            guest["role"] = this.state.roles[0];
        }

        if (!guest.name || !guest.email || !guest.phone) {
            Alert.alert("Alert!", "Please enter valid values")
        }
        else {
            EventService.inviteGuest(eventID, guest).then(res => res.json()).then(json => {
                if (json.message) {

                    let eventDetail = this.props.route.params.eventDetail;

                    this.remainingRoles();
                    Alert.alert("Success!", json.message, [
                        {
                            text: "Okay", onPress: () => {
                                this.props.getEventDetail(eventDetail.id)
                                this.props.navigation.goBack()
                            }
                        }
                    ]);

                }
                else {
                    Alert.alert("Error!", json.error);
                }
            }).catch(err => {
                Alert.alert("Oops!", `Something was not expected ${err}`);
            })
        }
    }

    remainingRoles = () => {
        let roles = ["participant1", "participant2", "bride", "groom"];

        const existing_roles = this.props.route.params.eventDetail.participants.map((v, i) => (v.role));
        for (let i = 0; i < existing_roles.length; i++) {
            const index = roles.indexOf(existing_roles[i]);
            if (index > -1) {
                roles.splice(index, 1);
            }
        }
        this.setState({ roles: roles })
    }
    getRoles = () => {
        MastersService.getRoles().then(res => res.json()).then(json => {
            this.setState({ roles: json.roles })
        }).catch(err => console.log(err))
    }

    componentDidMount = () => {
        this.setState({ is_participant: this.props.route.params.is_participant })
        // this.remainingRoles()
        this.getRoles()
    }
    render() {
        const roles = this.state.roles.map((v, i) => ({ label: ucWords(v.title), value: v.title,color: primaryColor }))
        return (
            <View style={{ flex: 1, padding: 10 }}>

                <Input placeholder="Name" textContentType="nickname" keyboardType="name-phone-pad" value={this.state.participantName} onChangeText={this.handleName} />
                <Input placeholder="Phone" keyboardType="phone-pad" keyboardType="phone-pad" value={this.state.phone} onChangeText={this.handlePhone} />
                <Input placeholder="email" textContentType="emailAddress" keyboardType="email-address" autoCapitalize="none" value={this.state.participantEmail} onChangeText={this.handleEmail} />
                <View style={{ marginLeft: 10 }}>
                    {this.state.is_participant &&
                        <RNPickerSelect
                            style={{
                                inputWeb: {
                                    height: 40,
                                    color: primaryColor
                                }
                            }}
                            placeholder={{ label: "Select role" }}
                            onValueChange={this.chooseRole}
                            items={roles}
                        />
                    }
                </View>

                <View style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
                    <Button containerStyle={{ backgroundColor: "#00bcd4" }} buttonStyle={{ padding: 10 }} title="SAVE AND CONTINUE" onPress={this.saveInvite} />
                </View>
            </View>
        )
    }
}


const mapDispatchToProps = dispatch => {
    return {
        getEventDetail: eventID => dispatch(getEventDetail(eventID))
    }
}

export default connect(null, mapDispatchToProps)(ManualInvite)