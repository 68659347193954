import React, { Component } from 'react';
import { Alert, Dimensions, Image, PermissionsAndroid, Platform, StyleSheet, View } from 'react-native';
import { Icon } from 'react-native-elements';
import { TouchableOpacity } from 'react-native';
import RecordScreen from 'react-native-record-screen';
import { connect } from 'react-redux';
import { EventService } from '../services/events';
import { Storage } from '../services/storage';
import Text from '../components/WebwedText'
import "../assets/stream.css"

const d = Dimensions.get('screen')

class JoinShared extends Component {

    state = {
        sessionID: '',
        apiKey: '',
        token: '',
        eventNotStarted: true,
        notInvited: false,
        eventOver: false,
        publishAudio: true,
        publishVideo: true,
        videoSource: 'camera',
        totalViewers: 0,
        totalGuests: 0,
        signal: {
            data: 0,
            type: '',
        },
        text: '',
        messages: [],
        session: {},
        eventID: '',
        eventDetail: { addons: [], title: '', participants: [], guests: [], host: { uid: '' } },
        archive: false,
        isScreenRecording: false,
        recordedVideos: false,
        remainingMinutes: 360,
        loading: true,
        isParticipant: true,
        pub: {
            width: '100%',
            height: '100%',
            rowBasis: '100%'
        }
    }

    constructor(props) {
        super(props)
        this.vRef = React.createRef()
    }

    sessionEventHandlers = {
        archiveStarted: archive => {
            this.setState({ archive: true })
        },
        archiveStopped: archive_id => {
            this.setState({ archive: false })

        },
        streamCreated: event => {
        },
        connectionCreated: event => {
            this.setState({
                eventNotStarted: false
            })
        },
        sessionConnected: () => {
        },
        signal: event => {
            if (event.data) {

                const myConnectionId = this.session.getSessionInfo().connection.connectionId;

                if (event.data == 'EVENTCOMPLETE') {
                    this.props.navigation.push('EventCompleted', { eventID: this.state.eventDetail.id })
                }
                else {
                    if (event.data == 'EVENTOVER') {
                        this.props.navigation.replace('EventCompleted', { eventID: this.state.eventDetail.id })
                    }
                    else {
                        const myConnectionId = this.session.getSessionInfo().connection.connectionId;
                        const oldMessages = this.state.messages;
                        const messages = event.connectionId === myConnectionId ? [...oldMessages, { data: `Me: ${event.data}` }] : [...oldMessages, { data: `Other: ${event.data}` }];
                        this.setState({
                            messages,
                        });
                    }
                }
            }
        }
    };

    publisherEventHandlers = {
        streamCreated: event => {
        },
        streamDestroyed: event => {
        }
    };
    componentDidMount() {
        this.startSession()
    }

    startSession = () => {
        const eventCode = this.props.route.params.eventCode

        EventService.joinSharedSession(eventCode).then(res => res.json()).then(json => {
            if (json.status) {
                switch (json.status) {
                    case 1:

                        confirmAlert({
                            title: "Event Over!!", message: json.error, buttons: [{
                                label: "Okay",
                                onClick: () => this.props.navigation.replace('Login'),
                            },
                            ]
                        })

                        break;

                    case 2:
                        // event is not started yet
                        // show modal with message event not started yet

                        // confirmAlert({
                        //     title: "Event Over!!", message: json.error, buttons: [{
                        //         label: "Okay",
                        //         onClick: () => this.props.navigation.replace('Login'),
                        //     },
                        //     ]
                        // })

                        this.initSession(json, json.event_details.id, true)


                        break;
                    case 3:
                        // you are not invited
                        // show modal with message event not started yet
                        Alert.alert("Not Invited", "Oops! seems you are not invited", [{
                            text: "Okay",
                            onPress: () => this.props.navigation.replace('Login'),
                            style: "cancel"
                        },
                        ])
                        break;
                    case 4:
                        // event is over
                        // show modal with message event is over
                        Alert.alert("Event Over", "Oh! you are late, event is over.", [{
                            text: "Cancel",
                            onPress: () => this.props.navigation.replace('Login'),
                            style: "cancel"
                        },
                        ])
                        break;
                    case 5:
                        // server error
                        // show alert and redirect to details page
                        Alert.alert("Failed!", "Something went wrong", [{
                            text: "Cancel",
                            onPress: () => this.props.navigation.push('Login'),
                            style: "cancel"
                        },
                        ])
                        break;
                    default:
                        break;
                }
            }
            else {

                this.props.navigation.setOptions({ title: json.event_details.title })
                this.initSession(json, json.event_details.id)

                const remainingMinutes = parseInt(json.event_details.package.allotted_time) * 60 - json.event_details.consumed_seconds
                setTimeout(() => {
                    this.setState({
                        signal: {
                            type: '',
                            data: 'EVENTOVER',
                        },
                        text: '',
                    });
                }, remainingMinutes * 1000)
            }
        }).catch(err => {
            console.log(err)
        })

    }

    initSession = (json, eventID) => {
        let subidx = 0

        this.setState({
            sessionID: json.session_details.session_id,
            apiKey: json.session_details.key,
            token: json.session_details.token,
            eventID: eventID
        })
        this.session = OT.initSession(json.session_details.key, json.session_details.session_id);

        this.session.on('streamCreated', event => {

            console.log("Stream created")
            const size = this.getSubscriberSize(subidx)

            const subs = this.session.subscribe(event.stream, 'subscribers', {
                insertMode: 'append',
                width: size.width,
                height: size.height
            }, err => console.log("Err", err));

            subidx += 1

            subs.on('connected', event => {

                const conectionData = JSON.parse(event.data)

                this.setState({
                    totalViewers: this.state.totalViewers + 1,
                    totalGuests: conectionData.type == 'guest' ? (this.state.totalGuests + 1) : this.state.totalGuests
                })
                this.getPublisherSize()
            })

            subs.on('destroyed', event => {

                const conectionData = JSON.parse(event.data)

                this.setState({
                    totalViewers: this.state.totalViewers - 1,
                    totalGuests: conectionData.type == 'guest' ? (this.state.totalGuests - 1) : this.state.totalGuests
                })
                this.getPublisherSize()
            })
        });

        this.session.on('connectionCreated', event => {
        })

        this.session.on('signal', event => {
            if (event.data) {

                if (event.data == 'EVENTCOMPLETE') {
                    this.props.navigation.replace('EventCompleted', { eventID: this.state.eventDetail.id })
                }
            }
        })

        this.session.connect(json.session_details.token, (error) => {
            // If the connection is successful, publish to the session
            if (error) {
                console.log(error);
            }
            else {
                this.session?.publish(this.publisher, err => console.log("Err", err));
                this.setState({ publisherInitiated: true })
            }
        });


        if (!this.state.publisherInitiated) {
            this.publisher = OT.initPublisher('publisher', {
                insertMode: 'append',
                width: '100%',
                height: '100%'
            }, err => console.log(err));
        }
    }

    renderSubscribers = (subscribers) => {

        let w = 100
        let h = 100

        return subscribers.map((streamId) => (
            < TouchableOpacity key={streamId} style={[styles.subWrapper, { width: w, height: h, borderRadius: 5, margin: 10 }]} >
                <OTSubscriberView streamId={streamId} style={{ width: '100%', height: '100%', borderRadius: 5 }} />
            </TouchableOpacity >
        )
        );
    };

    toggleVideo = () => {
        const pv = !this.state.publishVideo
        this.setState({ publishVideo: pv })
        this.publisher.publishVideo(pv)
    }

    toggleAudio = () => {
        const av = !this.state.publishAudio
        this.setState({ publishAudio: av })
        this.publisher.publishAudio(av)

    }

    hasAndroidPermission = async () => {
        const permission = PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE;

        const hasPermission = await PermissionsAndroid.check(permission);
        if (hasPermission) {
            return true;
        }

        const status = await PermissionsAndroid.request(permission);
        return status === 'granted';
    }

    takeShot = async () => {
        // captureRef(this.vRef, {
        //     format: "jpg",
        //     quality: 0.8
        // }).then(uri => {
        //     if (Platform.OS === 'android' && !this.hasAndroidPermission()) {
        //         return;
        //     }
        //     CameraRoll.save(uri, { type: "photo" }).then(v => {
        //         Alert.alert("Success!", "Screenshot saved successfully.")
        //     }).catch(err => {
        //         console.log("Failed ro save screenshot..", err)
        //     })
        // }).catch(err => {
        //     console.log("Could not capture...", err)
        // })
    }

    goBack = () => {

        // this.setState({ eventNotStarted: false })
        this.props.navigation.goBack()
    }

    goToRegistry = () => {
        this.props.navigation.push('Registry')
    }

    // flipCamera = () => {
    //     const cameraPosition = this.state.cameraPosition == 'front' ? 'back' : 'front';
    //     console.log(cameraPosition)
    //     this.setState({ cameraPosition })
    // }
    toggleRecording = async () => {

        if (!this.state.isScreenRecording) {
            if (Platform.OS == 'android') {
                const permission = PermissionsAndroid.PERMISSIONS.RECORD_AUDIO;

                const hasPermission = await PermissionsAndroid.check(permission);
                if (!hasPermission) {
                    Alert.alert('Failed', "Needs recording permission");
                    return;
                }
                const status = await PermissionsAndroid.request(permission);
                if (status == 'granted') {
                    await RecordScreen.startRecording().catch(err => console.log(err))
                    this.setState({ isScreenRecording: true })
                }
            }
            else {
                await RecordScreen.startRecording().catch(err => console.log(err))

                this.setState({ isScreenRecording: true })
            }
        }
        else {
            const res = await RecordScreen.stopRecording().catch(err => console.log(err))
            if (res) {

                const recorded = await Storage.getRecording()
                const d = new Date()

                recorded.push({ title: `Recording-${d.toISOString()}`, uri: res.result.outputURL })
                Storage.setRecording(recorded)
                // const fileName = res.result.outputURL.split("/").pop()

                // if (RNFetchBlob.fs.exists(RNFetchBlob.fs.dirs.DocumentDir + '/' + fileName)) {
                //     const d = new Date()
                //     RNFetchBlob.fs.mv(res.result.outputURL, RNFetchBlob.fs.dirs.DocumentDir + '/' + d.getMilliseconds() + "-" + fileName).then(() => alert("Video saved in the documents")).catch(err => alert("Failed to save " + err.message))
                // }
                // else {
                //     RNFetchBlob.fs.mv(res.result.outputURL, RNFetchBlob.fs.dirs.DocumentDir + '/' + fileName).then(() => alert("Video saved in the documents")).catch(err => alert("Failed to save " + err.message))
                // }

                this.setState({ isScreenRecording: false, recordedVideos: true })
            }
        }
    }

    leaveEvent = () => {
        if (this.state.isParticipant) {
            Alert.alert("Confirm!!", "Are you sure to leave this event? nevermind you can join later.", [
                { text: "Leave", onPress: () => navigation.replace('Login') },
                {
                    text: "Cancel",
                    // onPress: () => this.props.navigation.pop(),
                    style: "cancel"
                }
            ])
        }
        else {
            Alert.alert("Are you sure?", "Event will be over and others will also drop.", [
                {
                    text: "Leave", onPress: () => {
                        // complete event then move to completed event
                        // 
                        EventService.completeEvent(this.state.eventID).then(res => res.json()).then(json => {
                            if (json.message) {
                                this.setState({
                                    signal: {
                                        type: '',
                                        data: 'EVENTCOMPLETE',
                                    },
                                    text: '',
                                });
                                // this.props.navigation.replace('EventCompleted')
                            }
                            else {
                                Alert.alert("Failed to complete the event")
                            }
                        })
                    }
                },
                {
                    text: "Cancel",
                    // onPress: () => this.props.navigation.pop(),
                    style: "cancel"
                }
            ])
        }
    }

    eventNotStartedOrHost = () => {
        return !this.state.eventNotStarted
    }

    gotoRecordingList = () => {
        this.props.navigation.navigate('RecordVideoList')
    }

    getPublisherSize = () => {
        const dim = Dimensions.get('window')
        const totalViewers = this.state.totalViewers - this.state.totalGuests

        if (totalViewers == 0) {

            this.setState({ pub: { width: dim.width, height: dim.height } })
        }
        else {
            switch (totalViewers) {
                case 1:
                case 2:
                    console.log("1 viewer...")
                    this.setState({ pub: { width: dim.width, height: dim.height / 2, rowBasis: '100%' } })
                    break;
                case 3:
                case 4:
                    this.setState({ pub: { width: dim.width / 2, height: dim.height / 2, rowBasis: '50%' } })
                    break;

                case 5:
                case 6:
                case 7:
                    this.setState({ pub: { width: dim.width / 2, height: dim.height / 3, rowBasis: '50%' } })
                    break;

                case 8:
                case 9:
                    this.setState({ pub: { width: dim.width / 3, height: dim.height / 3, rowBasis: '33%' } })
                    break;

                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                    this.setState({ pub: { width: dim.width / 4, height: dim.height / 4, rowBasis: '25%' } })
                    break;

                default:
                    if (totalViewers > 15 && totalViewers < 20) {
                        this.setState({ pub: { width: dim.width / 4, height: dim.height / 5, rowBasis: '25%' } })
                    }
                    else {
                        this.setState({ pub: { width: dim.width / 5, height: dim.height / 5, rowBasis: '25%' } })
                    }
            }
        }
    }


    getSubscriberSize = (idx) => {
        const dim = Dimensions.get('window')
        const totalViewers = this.state.totalViewers - this.state.totalGuests

        switch (totalViewers) {
            case 1:
            case 0:
                return { width: dim.width, height: dim.height / 2, rowBasis: '100%' }
            case 2:
            case 3:
                return { width: dim.width / 2, height: dim.height / 2, rowBasis: '50%' }
            case 4:
                if (idx == 0) {
                    return { width: dim.width / 2, height: dim.height / 2, rowBasis: '50%' }
                }
                else {
                    return { width: dim.width / 3, height: dim.height / 2, rowBasis: '33%' }
                }
            case 5:
                return { width: dim.width / 2, height: dim.height / 3, rowBasis: '50%' }
            case 6:
                if (idx < 3) {
                    return { width: dim.width / 2, height: dim.height / 3, rowBasis: '50%' }
                }
                else {
                    return { width: dim.width / 3, height: dim.height / 3, rowBasis: '33%' }
                }
            case 7:
                if (idx == 0) {
                    return { width: dim.width / 2, height: dim.height / 3, rowBasis: '50%' }
                }
                else {
                    return { width: dim.width / 3, height: dim.height / 3, rowBasis: '33%' }
                }
            case 8:
                return { width: dim.width / 3, height: dim.height / 3, rowBasis: '33%' }

            case 9:
                if (idx < 5) {
                    return { width: dim.width / 3, height: dim.height / 3, rowBasis: '33%' }
                }
                else {
                    return { width: dim.width / 4, height: dim.height / 3, rowBasis: '33%' }
                }
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
                return { width: dim.width / 4, height: dim.height / 4, rowBasis: '25%' }
            default:
                if (id > 15 && idx < 20) {
                    return { width: dim.width / 4, height: dim.height / 5 }
                }
                else {
                    return { width: dim.width / 5, height: dim.height / 5, rowBasis: '25%' }
                }

        }
    }

    render() {
        return (
            <View style={{ flex: 1, justifyContent: "center" }}>
                {/* {this.state.eventNotStarted && <EventNotStartedModal eventDetail={this.props.route.params.eventDetail} />} */}
                {/* {!this.state.eventNotStarted && <> */}
                <View nativeID="publisher" style={{ width: this.state.pub.width, height: this.state.pub.height, rowBasis: this.state.pub.rowBasis }}></View>
                <View nativeID="subscribers"></View>

                <View style={{ position: "absolute", top: 30, left: 10, flexDirection: "row" }}>
                    <Icon color="white" size={18} type="material-icon" name="group" /><Text style={{ color: "#FFF", marginLeft: 5 }}>
                        {parseInt(this.state.totalViewers)}</Text>
                </View>
                <View style={{ position: "absolute", bottom: 100, right: 10, width: 100, height: 100, flexDirection: "row" }}>
                    <Image source={require('../assets/images/WebWed-Logo.png')} style={{ width: 100, height: 100 }} resizeMode="center" />
                </View>
                <View style={{ position: "absolute", bottom: 70, left: 0, right: 0, backgroundColor: "rgba(90,171,211,0.3)", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", borderRadius: 50, marginHorizontal: 10 }}>
                    <TouchableOpacity onPress={this.toggleVideo}>
                        <Icon reverseColor={this.state.publishVideo ? "#023C6F" : '#FFF'} color={this.state.publishVideo ? "#FFF" : '#023C6F'} size={18} reverse type="octicon" name="eye-closed" />
                    </TouchableOpacity>

                    <TouchableOpacity onPress={this.leaveEvent}>
                        <Image source={require("../assets/images/heart_active.png")} style={{ width: 50, height: 50 }} />
                        {/* <Icon reverseColor="red" color="#FFF" size={18} reverse type="ionicon" name="md-heart" onPress={this.completeEvent} /> */}
                    </TouchableOpacity>
                    <TouchableOpacity onPress={this.toggleAudio}>
                        <Icon reverseColor={this.state.publishAudio ? "#023C6F" : '#FFF'} color={this.state.publishAudio ? "#FFF" : '#023C6F'} size={18} reverse type="ionicon" name="md-mic-off" />
                    </TouchableOpacity>

                </View>

            </View>
        )
    }
}


const mapStateToProps = state => {
    return {
        cameraPosition: state.cameraPosition,

    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(JoinShared);

const styles = StyleSheet.create(
    {
        container: { flex: 1, justifyContent: "center", alignItems: "center" },
        session: { flex: 1 },
        subscriber: { width: 100, height: 100 },
        publisher: { width: '100%', height: '100%' },
        subWrapper: {
            borderWidth: 2,
            borderRadius: 5,
            borderColor: "#023C6F"
        }
    }
)

