export const START_LOADING = 'START_LOADING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const PACKAGE_ADDONS_SUCCESS = 'PACKAGE_ADDONS_SUCCESS';
export const PACKAGE_ADDONS_FAILED = 'PACKAGE_ADDONS_FAILED';

export const EVENT_CREATE_SUCCESS = 'EVENT_CREATE_SUCCESS';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const EVENT_CREATE_FAILED = 'EVENT_CREATE_FAILED';

export const COVER_UPLOAD_SUCCESS = 'COVER_UPLOAD_SUCCESS';
export const COVER_UPLOAD_FAILED = 'COVER_UPLOAD_FAILED';

export const HTTP_FAILED = 'HTTP_FAILED';

export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';

export const NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS';
export const EVENT_DETAIL_SUCCESS = 'EVENT_DETAIL_SUCCESS';
export const ROLES_SUCCESS = 'ROLES_SUCCESS';
export const COURT_SUCCESS = 'COURT_SUCCESS';

export const REFRESH_EVENT = 'REFRESH_EVENT';
export const BLANK_ERR = 'BLANK_ERR';
export const USER_PROFILE = 'USER_PROFILE';
export const USER_PROFILE_FAILED = 'USER_PROFILE_FAILED';


export const FLIP_CAMERA = 'FLIP_CAMERA'; 
export const ARCHIVES_SUCCESS = 'ARCHIVES_SUCCESS'; 

export const OFFICIANT_LIST = 'OFFICIANT_LIST';
export const TOGGLE_VIDEO='TOGGLE_VIDEO';