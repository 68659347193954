import React, { Component } from 'react'
import { Text } from 'react-native'

export default class WebWedText extends Component {
    render() {
        return (
            <Text style={[this.props.style,{fontFamily:'"Roboto","Helvetica Neue", sans-serif'}]} >{this.props.children}</Text>
        )
    }
}
