import * as firebase from 'firebase';
import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, Input } from 'react-native-elements';
import { TouchableOpacity } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { connect } from 'react-redux';
import LoadingModal from '../components/LoadingModal';
import WebwedLogo from '../components/WebwedLogo';
import { autoLogin, blankErr, login } from '../stores/actions';
import Text from '../components/WebwedText'
import Alert from '../components/Alert'
class Login extends Component {

    state = {
        loading: false,
        email: '',
        password: '',
        errors: { email: '',password:'' },
        retry: 0
    }

    handleEmailChange = (e) =>  {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (reg.test(e) === false) {
            this.setState({ errors: { ...this.state.errors, email: "Invalid Email" }, email: e });
        }
        else {
            this.setState({ errors: { ...this.state.errors, email: "" }, email: e });
        }
    }

    handlePasswordChange = (e) => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (e.length < 6) {
            this.setState({ errors: { ...this.state.errors, password: "Invalid password" }, password: e });
        }
        else {
            this.setState({ errors: { ...this.state.errors, password: "" }, password: e });
        }
    }

    handleLogin() {

        console.log(this.state.password)
        if (!this.state.email || !this.state.password) {
            this.setState({ errors: { email: !this.state.email ? 'Email required' : '', password: !this.state.password ? 'Password required' : '' } })
        }
        else {

            this.setState({
                clicked: false,
                password: '',
                loading:true
            })
            // this.props.login(this.state.email, this.state.password);
            firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).catch(err => {
                this.setState({ error: err.message,
                    loading:false
                });
            });

            const user = firebase.auth().currentUser;
            if (user) {
                this.setState({loading:false})
                dispatch(loginSuccess(user));
            }
        }

    }

    componentDidMount() {
        this.props.autoLogin();
    }

    openJoinEvent = () => {
        this.props.navigation.push('JoinEvent')
    }

    forgotPass = () => {
        console.log("forgot...")
        if (!this.state.email) {
            Alert.alert("Forgot Password", "Enter an email address, and tap forgot password again to reset your password.");
        }
        else {
            this.setState({ loading: true })
            firebase.auth().sendPasswordResetEmail(this.state.email).then(() => {
                this.setState({ loading: false })

                Alert.alert("Forgot Password", "Please check your email to reset your password.", [{ text: "Okay", onPress: () => this.setState({ loading: false }) }])
            }).catch(err => {
                Alert.alert("Forgot Password", err.message, [{ text: "Okay", onPress: () => this.setState({ loading: false }) }])
                // this.setState({ loading: false })
            })
        }
    }
    gotToRegister = () => this.props.navigation.navigate('Signup')
    render() {

        return (
            <View style={styles.container}>
                <KeyboardAwareScrollView>
                    <WebwedLogo />
                    <View style={{ paddingBottom: 30, flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}>
                        <Text style={styles.loginText}> Sign in to existing account or</Text>
                        <TouchableOpacity onPress={this.gotToRegister}>
                            <Text style={[styles.loginText, { color: '#5aabd3', marginHorizontal: 5 }]}>Register</Text>
                        </TouchableOpacity>
                        <Text style={styles.loginText}>for a new one</Text>
                    </View>
                    {this.state.error && <View style={{ justifyContent: "center", alignItems: "center" }}>
                        <Text style={{ color: "red", margin: 10 }}>
                            {this.state.error}
                        </Text>
                    </View>}
                    <Input
                        inputContainerStyle={styles.inputContainer}
                        keyboardType="email-address"
                        autoCapitalize="none"
                        inputStyle={styles.inputStyle}
                        renderErrorMessage={true}
                        errorMessage={this.state.errors.email}
                        placeholder='Email'
                        value={this.state.email}

                        // leftIcon={<Icon name="email" size={24} color='black' />}
                        onChangeText={this.handleEmailChange}
                    />
                    <Input
                        inputContainerStyle={styles.inputContainer}
                        inputStyle={styles.inputStyle}
                        placeholder='Password'
                        textContentType="password"
                        secureTextEntry={true}
                        renderErrorMessage={true}
                        value={this.state.password}
                        errorMessage={this.state.errors.password}
                        onChangeText={e => { this.handlePasswordChange(e) }}
                    />
                    <View style={{ marginBottom: 20, padding: 10 }}>
                        <Button titleStyle={{ fontFamily: '"Roboto","Helvetica Neue", sans-serif' }} title="Continue" buttonStyle={{ padding: 10 }} style={{ paddingTop: 10, paddingBottom: 10 }} onPress={e => this.handleLogin()} />
                    </View>
                    <TouchableOpacity onPress={this.forgotPass}>
                        <Text style={styles.loginText}>Forgot Password?</Text>
                    </TouchableOpacity>

                    <View style={{ borderRadius: 5, padding: 10, marginBottom: 20 }}>
                        <Button titleStyle={{ fontFamily: '"Roboto","Helvetica Neue", sans-serif' }} title="Guest Login" buttonStyle={{ backgroundColor: "tomato" }} style={{ paddingTop: 10, paddingBottom: 10 }} onPress={this.openJoinEvent} />
                    </View>

                    {(this.state.loading || this.props.loading) && <LoadingModal title="Loading..." />}
                </KeyboardAwareScrollView>
            </View>
        )
    }
}
const mapStateToProps = (state) => ({
    err: state.err,
    loading: state.loading
})

const mapDispatchToProps = dispatch => ({
    loginSuccess: (user) => dispatch(loginSuccess(user)),
    autoLogin: () => dispatch(autoLogin()),
    blankErr: err => dispatch(blankErr(err))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);

const styles = StyleSheet.create({
    inputContainer: { backgroundColor: 'white', borderBottomWidth: 0,  paddingVertical: 5, paddingHorizontal: 10, borderRadius: 5 },
    inputStyle: { backgroundColor: 'white', margin: 5 },
    container: {
        flex: 1,
        backgroundColor: '#023C6F',
        justifyContent: "center",
        alignItems: "center"
    },
    img: {
        alignSelf: "center",
        marginBottom: 50
    },
    loginText: {
        fontSize: 20,
        textAlign: "center",
        color: 'white',
        fontFamily: '"Roboto","Helvetica Neue", sans-serif',
        marginBottom: 10,
    }
})
