import React, { Component } from 'react'
import { View, Image } from 'react-native'
import { ListItem } from 'react-native-elements'

export default class AddPlaceholder extends Component {
    render() {
        return (
            <ListItem titleStyle={{ color: "#043f7b" }} title={this.props.title} subtitle={this.props.subtitle} leftAvatar={
                <View style={{ width: 35, height: 35, borderRadius: 50, backgroundColor: "#075193", justifyContent: "center", alignItems: "center" }}>
                    <Image style={{ width: 35, height: 35, borderRadius: 50 }} source={require('../assets/logo.png')} />
                </View>
            } />
        )
    }
}
