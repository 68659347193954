import React, { Component } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { Button, Icon, ListItem } from 'react-native-elements'
import { Storage } from '../services/storage'
// import RNFetchBlob from 'rn-fetch-blob';
import { primaryColor } from '../configs/themeOptions';
import Text from '../components/WebWedText'

export default class RecordVideoList extends Component {
    state = { videos: false }
    componentDidMount() {
        Storage.getRecording().then(res => {
            console.log(res)
            this.setState({
                videos: res
            })
        }).catch(
            err => console.log(err)
        )
    }

    playRecord = v => {
        this.props.navigation.navigate('PlayRecord', { uri: v.uri })
    }

    saveVideo = video => {
        const fileName = video.uri.split("/").pop()
        // alert(video.uri)
        // if (RNFetchBlob.fs.exists(RNFetchBlob.fs.dirs.DocumentDir + '/' + fileName)) {
        //     const d = new Date()
        //     RNFetchBlob.fs.mv(video.uri, RNFetchBlob.fs.dirs.DocumentDir + '/' + d.getMilliseconds() + "-" + fileName).then(() => alert("Video saved in the documents")).catch(err => alert("Failed to save " + err.message))
        // }
        // else {
        //     RNFetchBlob.fs.mv(video.uri, RNFetchBlob.fs.dirs.DocumentDir + '/' + fileName).then(() => alert("Video saved in the documents")).catch(err => alert("Failed to save " + err.message))
        // }
    }
    render() {
        return (
            <View>
                {this.state.videos && this.state.videos.map((v, i) =>
                    <ListItem onPress={() => this.playRecord(v)} key={i}>
                        <ListItem.Content />
                        <Text>{v.title}</Text>
                        <TouchableOpacity onPress={() => this.saveVideo(v)}>
                            <Text style={{ color: primaryColor }}>Save to gallery</Text>
                        </TouchableOpacity>
                        <TouchableOpacity>
                            <Icon type="material-community" name="chevron-right" />
                        </TouchableOpacity>
                    </ListItem>)}
            </View>
        )
    }
}
