import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React, { Component } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { TouchableOpacity } from 'react-native';
import { Icon, ThemeProvider } from 'react-native-elements';
import { connect } from 'react-redux';
import '../assets/datepicker.css';
import WebwedDrawer from '../components/WebwedDrawer';
import { color1, color4, primaryColor } from '../configs/themeOptions';
import MainStack from '../screen-stacks/MainStack';
import { loginSuccess } from '../stores/actions';
import EventCompleted from './EventCompleted';
import JoinEvent from './JoinEvent';
import JoinShared from './JoinShared';
import Login from './Login';
import Signup from './Signup';
import Viewer from './Viewer';

const theme = {
    Input: {
        placeholderTextColor: 'grey'
    },
    Button: {
        disabledStyle: { backgroundColor: "#5aabd3" },
        disabledTitleStyle: { color: "#FFF" },
        titleStyle: { color: "#FFF" },
        buttonStyle: { backgroundColor: "#075193", paddingVertical: 15, borderRadius: 0 },
        containerStyle: { borderRadius: 0 },
    }
}

const config = {
    screens: {
        JoinShared: 'join/:eventCode',
        MainStack: {
            // path: 'main',
            screens: {
                Dashboard: {
                    // path: 'dashboard',
                    screens: {
                        Events: 'events',
                        PastEvents: 'archives'
                    }
                },
                EventCompleted: 'event-over',
                CreateEvent: 'create-event',
                ReviewOrder: 'review-order',
                Checkout: 'checkout',
                EventDetailTabs: 'event-detail',
                Orders: 'orders',
                MarriageEducation: 'marriage-education',
                MarriageApplication: 'marriage-application',
                Registry: 'marriage-registry',
                LiveStream: 'live-stream',
                Viewer: 'guest-stream',
                ProfileTabs: 'account',
                Notifications: 'notifications',
                PlayArchive: 'play-archive',
                VideoInvite: 'video-invite',
                ManualInvite: 'manual-invite',
                ImportContact: 'import-contact',
                FindOfficiant: 'officiant-list',
                OfficiantDetail: 'officiant-detail',
                MarriageApplicationCongrats: 'applied',
                RecordVideoList: 'recordings',
                PlayRecord: 'play-recording',
            }
        },
        NotFound: "*"
    },
};

const linking = {
    prefixes: ['https://webwed.adesh.me', 'webwed://'],
    config,
};


const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

class Layout extends Component {

    render() {
        return (
            <ThemeProvider theme={theme}>
                <NavigationContainer linking={linking}>
                    {
                        this.props.isAuthenticated ? (
                            <Drawer.Navigator
                                drawerContentOptions={{ contentContainerStyle: { backgroundColor: primaryColor, position: "relative" } }}
                                drawerStyle={{ backgroundColor: "#FFF" }}
                                // hideStatusBar={true}
                                drawerContent={props => <WebwedDrawer {...props} />}
                                initialRouteName="MainStack"
                            >
                                <Drawer.Screen name="MainStack" component={MainStack} />
                                <Stack.Screen name="JoinShared" component={JoinShared} options={({ route }) => ({
                                    headerShown: true,

                                    headerTitle: route.params?.name,
                                    headerStyle: {
                                        backgroundColor: '#023C6F',
                                        elevation: 0,
                                        shadowColor: "transparent"
                                    },
                                    headerLeftContainerStyle: { marginLeft: 0, marginRight: 0 },
                                    headerTitleAlign: "left",
                                    headerRight: () => (<TouchableOpacity onPress={this.flipCamera} style={{ marginRight: 10 }}>
                                        <Icon color={this.props.cameraPosition == 'back' ? "#5aabd3" : '#FFF'} size={18} type="material-community"
                                            name="camera-party-mode"
                                        />
                                    </TouchableOpacity>),
                                    headerLeft: () => {
                                        return (<TouchableOpacity onPress={() => { this.props.navigation.replace('Dashboard') }}>
                                            <Icon
                                                type="ionicon"
                                                name="md-arrow-back"
                                                size={24}
                                                color="white" />
                                        </TouchableOpacity>)
                                    },
                                    headerTintColor: '#fff',

                                })} />
                            </Drawer.Navigator>
                        )
                            :
                            (
                                <Stack.Navigator screenOptions={{
                                    headerShown: false
                                }}>
                                    <Stack.Screen name="login" component={Login} />
                                    <Stack.Screen name="Signup" component={Signup} />
                                    <Stack.Screen name="JoinShared" component={JoinShared} options={({ route, navigation }) => ({
                                        headerShown: true,
                                        headerTitle: route.params.name,
                                        headerStyle: {
                                            backgroundColor: '#023C6F',
                                            elevation: 0,
                                            shadowColor: "transparent"
                                        },
                                        headerLeftContainerStyle: { marginLeft: 0, marginRight: 0 },
                                        headerTitleAlign: "left",
                                        headerRight: () => (<TouchableOpacity onPress={this.flipCamera} style={{ marginRight: 10 }}>
                                            <Icon color={this.props.cameraPosition == 'back' ? "#5aabd3" : '#FFF'} size={18} type="material-community"
                                                name="camera-party-mode"
                                            />
                                        </TouchableOpacity>),
                                        headerLeft: () => {
                                            return (<TouchableOpacity onPress={() => { navigation.replace('Login') }}>
                                                <Icon
                                                    type="ionicon"
                                                    name="md-arrow-back"
                                                    size={24}
                                                    color="white" />
                                            </TouchableOpacity>)
                                        },
                                        headerTintColor: '#fff',

                                    })} />
                                    <Stack.Screen name="EventCompleted" component={EventCompleted} options={{ headerShown: false }} />

                                    <Stack.Screen name="JoinEvent" component={JoinEvent} options={{
                                        headerShown: true,
                                        // headerLeft:() => <BackButton navigation={this.props.navigation} />,
                                        headerBackTitleStyle: { color: "#FFF" },
                                        headerTintColor: "#FFF",
                                        title: "Join Event",
                                        headerStyle: { backgroundColor: color4, shadowColor: "transparent" },
                                        headerTitleStyle: { color: '#FFF', backgroundColor: color4 }
                                    }} />
                                    <Stack.Screen name="Viewer" component={Viewer} options={({ route, navigation }) => ({
                                        headerShown: true,
                                        headerTitle: route.params.name,
                                        headerStyle: {
                                            backgroundColor: color4,
                                            elevation: 0,
                                            shadowColor: "transparent"
                                        },
                                        headerLeftContainerStyle: { marginLeft: 15, marginRight: 0 },
                                        headerTitleAlign: "center",
                                        headerRight: () => (null),
                                        headerLeft: () => {
                                            return (<TouchableOpacity onPress={() => { navigation.goBack() }}>
                                                <Icon
                                                    type="ionicon"
                                                    name="md-arrow-back"
                                                    size={24}
                                                    color="white" />
                                            </TouchableOpacity>)
                                        },
                                        headerTintColor: '#fff',

                                    })} />
                                </Stack.Navigator>

                            )
                    }
                </NavigationContainer>
            </ThemeProvider>
        )
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.isAuthenticated,
    loading: state.loading
})

const mapDispatchToProps = dispatch => ({
    loginSuccess: user => dispatch(loginSuccess(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
