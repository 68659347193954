import React, { Component } from 'react'
import { View, RefreshControl, ImageBackground, StyleSheet } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { SearchBar, Button } from 'react-native-elements'
import { getEvents, getArchives } from '../stores/actions'
import { connect } from 'react-redux'
import LoadingModal from '../components/LoadingModal'
import { secondaryColor } from '../configs/themeOptions'
import ArchiveItem from '../components/ArchiveItem'
import Text from '../components/WebWedText'

const img = require('../assets/images/background.png');

class Archives extends Component {

    state = {
        searchText: '',
        refreshing: false
    }

    onRefresh = () => {
        this.props.getArchives();
    }

    updateSearch = (e) => {
        this.setState({
            searchText: e
        })
    }

    componentDidMount() {
        this.props.getArchives();
    }

    gotoCreateEvent = () => {
        this.props.navigation.push('CreateEvent');
    }

    render() {
        return (
            <>
            <ScrollView style={styles.container} refreshControl={
                <RefreshControl refreshing={this.props.loading} onRefresh={this.onRefresh} />}  >
                
                <View style={styles.eventList}>
                    {
                        this.props.archives ? this.props.archives.map((v, i) => (<ArchiveItem key={i} event={v} navigation={this.props.navigation} />)) : <LoadingModal />
                    }
                </View>
            </ScrollView>
            <View style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
                    <Button onPress={this.gotoCreateEvent} title="CREATE AN EVENT" titleStyle={{fontSize:18}} buttonStyle={{ backgroundColor: secondaryColor,borderRadius:0,padding:15 }} containerStyle={{borderRadius:0}} />
                </View>
            </>
        )
    }
}

const mapStateToProps = state => ({
    archives: state.archives,
    loading: state.loading
})

const mapDispatchToProps = dispatch => {
    return {
        getArchives: () => dispatch(getArchives())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Archives);

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#d3dbdf'
    },
    containerInner: {
        height: 200,
        // alignItems:"center",
        backgroundColor: 'rgb(94, 172, 209)'
    },
    bgImage: {
        flex: 1,
        resizeMode: "center"
    },
    searchContainer: { backgroundColor: 'white', borderBottomWidth: 0, alignSelf: 'center', borderRadius: 5 },
    searchInput: { backgroundColor: 'white', borderRadius: 5, marginBottom: 0 },
    searchIcon: { paddingHorizontal: 10 }
}) 