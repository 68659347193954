import React, { Component } from 'react'
import { View, Image, StyleSheet, TouchableOpacity } from 'react-native'
import * as ImagePicker from 'expo-image-picker';
import { Icon } from 'react-native-elements';
import { Video } from 'expo-av';
import { color2 } from '../configs/themeOptions';
import Text from '../components/WebWedText'

export default class WebwedPicker extends Component {

    state = {
        eventCover: ''
    }

    openPicker = async () => {
        
        const title = this.props.title || 'Choose Cover Photo';
        const response = await ImagePicker.launchImageLibraryAsync({
            title: title,
            mediaTypes: this.props.video ? ImagePicker.MediaTypeOptions.Videos : ImagePicker.MediaTypeOptions.Images ,
            videoQuality: 'low',
            quality: 0.5,
            maxWidth: 600,
            maxHeight: 600,

        });

        if (response.cancelled) {
            console.log("No image not selected");
        } else if (response.error) {
            console.log('ImagePicker Error: ', response.error);

        } else {
            // const source = { uri: response.uri };
           
            this.setState({
                eventCover: response,
            });
            this.props.onPress(response);
        }
    };

    render() {

        let cmp = false
        if (this.state.eventCover) {
            if (this.props.video) {
                cmp = <Video source={this.state.eventCover} style={styles.img} resizeMode="cover" />
            }
            else {
                cmp = <Image source={this.state.eventCover} style={styles.img} resizeMode="cover" />
            }
        }

        return (
            <View style={[styles.cover, this.props.containerStyle]}>
                {cmp && (cmp)}
                <TouchableOpacity onPress={this.openPicker} style={{ position: "absolute", justifyContent: "center", top: 0, left: 0, right: 0, bottom: 0 }}>
                    <Icon type={this.props.iconType} name={this.props.icon} size={48} color={this.props.color} style={styles.icon} />
                    <Text style={styles.title}>{this.props.title}</Text>
                </TouchableOpacity>

            </View>
        )
    }
}


const styles = StyleSheet.create({
    cover: { backgroundColor: "#075193", minHeight: 250, },
    img: { width: "100%", height: 250 },
    title: {
        alignSelf: "center",
        // fontWeight: "bold",
        color: color2
    },
    icon: {
        alignSelf: "center"
    }
})