import React, { Component } from 'react';
import { ActivityIndicator, TouchableOpacity } from 'react-native';
import { Icon, Image } from 'react-native-elements';
// import ImagePicker from 'react-native-image-picker';
import * as ImagePicker from 'expo-image-picker';
import { EventService } from '../services/events';
import Alert from './Alert';
import { color1, color2 } from '../configs/themeOptions';


export default class AddDocument extends Component {

  state = { image: false }

  openPicker = async () => {

    const response = await ImagePicker.launchImageLibraryAsync({
      title: "Select document",
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      maxWidth: 600,
      maxHeight: 600,

    });

    if (response.cancelled) {
      console.log("No image not selected");
    } else if (response.error) {
      console.log('ImagePicker Error: ', response.error);

    } else {
      // const source = { uri: response.uri };

      this.setState({
        image: response,
      });
      EventService.uploadDocument(this.props.eventID, this.props.documentID, this.props.type).then(res => res.json()).then(json => {
        console.log(json)
        const dn = this.props.eventID + "-" + this.props.type + "-" + this.props.documentID
        EventService.uploadCover(json.url.url, response, json.url.fields, 'photo', dn).then(res => res.text()).then(json => {
          // console.log("Uploaded...")
        }).catch(err => {
          console.log(err)
        })
      }).catch(err => console.log(err))
    }

  };

  componentDidMount = () => {
    console.log("Image...", this.props.image)
    if (this.props.image) {

      this.setState({ image: this.props.image })
    }
  }

  render() {

    return this.state.image ? (
      <TouchableOpacity style={{ width: 80, height: 80, justifyContent: "center", alignItems: "center" }} onPress={this.openPicker}>
        <Image source={this.state.image} resizeMode="contain" style={{ width: 80, height: 80, borderRadius: 40 }} PlaceholderContent={<ActivityIndicator />} />
      </TouchableOpacity>
    ) : (
        <TouchableOpacity style={{ width: 80, height: 80, borderRadius: 80, backgroundColor: color2, justifyContent: "center", alignItems: "center" }} onPress={this.openPicker}>
          <Icon name="plus" type="entypo" style={{ fontSize: 16, fontWeight: "bold" }} color="#FFF" />
        </TouchableOpacity>
      )
  }
}
