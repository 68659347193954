import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import React, { Component } from 'react';
import { Icon } from 'react-native-elements';
import OrdersTab from '../pages/OrdersTab';
import ProfileTab from '../pages/ProfileTab';
import OrdersStack from './OrdersStack';
import ProfileStack from './ProfileStack';


const Tab = createBottomTabNavigator();

export default class ProfileTabs extends Component {
    render() {

        return (

            <Tab.Navigator
                screenOptions={({ route }) => ({
                    tabBarIcon: ({ focused, color, size }) => {
                        let iconName;

                        if (route.name === 'Profile') {
                            iconName = focused
                                ? 'md-star'
                                : 'md-star-outline';
                        } else if (route.name === 'Orders') {
                            iconName = focused ? 'ios-list-box' : 'ios-list';
                        }

                        // You can return any component that you like here!
                        return <Icon type="ionicon" name={iconName} size={size} color={color} />;
                    },
                })}
                tabBarOptions={{
                    activeTintColor: '#023C6F',
                    inactiveTintColor: 'gray',
                }}>
                <Tab.Screen name="Profile" component={ProfileStack}></Tab.Screen>
                <Tab.Screen name="Orders" component={OrdersStack}></Tab.Screen>
            </Tab.Navigator>
        )
    }
}
