import React, { Component } from 'react'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Icon } from 'react-native-elements';
import EventList from './EventList';
import Archives from './Archives';

const Tab = createBottomTabNavigator();

export default class DashboardTabs extends Component {
    render() {

        return (

            <Tab.Navigator
                screenOptions={({ route }) => ({
                    tabBarIcon: ({ focused, color, size }) => {
                        let iconName;

                        if (route.name === 'Events') {
                            iconName = focused ? 'ios-list-box' : 'ios-list-box'
                        } else if (route.name === 'Past Events') {
                            iconName = focused ? 'ios-archive' : 'ios-archive';
                        }
                        // You can return any component that you like here!
                        return <Icon name={iconName} type='ionicon' size={size} color={color} />;
                    },
                })}
                tabBarOptions={{
                    activeTintColor: '#023C6F',
                    inactiveTintColor: 'gray',
                }}>
                <Tab.Screen name="Events" component={EventList}></Tab.Screen>
                <Tab.Screen name="Past Events" component={Archives}></Tab.Screen>
            </Tab.Navigator>
        )
    }
}
