import { createStackNavigator } from '@react-navigation/stack';
import React, { Component } from 'react';
import { TouchableOpacity } from 'react-native';
import { Image } from 'react-native';
import { Icon } from 'react-native-elements';
import NavigationDrawerIcon from '../components/NavigationDrawerIcon';
import NavigationRightIcons from '../components/NavigationRightIcons';
import { color4 } from '../configs/themeOptions';
import OrdersTab from '../pages/OrdersTab';


const Stack = createStackNavigator();

const img = require('../assets/images/logo-full.png');

export default class OrdersStack extends Component {
    render() {
        return (
            <Stack.Navigator screenOptions={{
                headerStyle: {
                    backgroundColor: color4,
                },
                headerLeftContainerStyle: { marginLeft: 15, marginRight: 0 },
                headerTitle: "My orders",
                headerTitleAlign: "left",
                headerRight: () => (<NavigationRightIcons navigation={this.props.navigation} />),
                headerLeft: () => {
                    return (<TouchableOpacity onPress={() => { this.props.navigation.goBack() }}>
                        <Icon
                            type="ionicon"
                            name="md-arrow-back"
                            size={24}
                            color="white" />
                    </TouchableOpacity>)
                },
                headerTintColor: '#fff',
            }}
            >
                <Stack.Screen component={OrdersTab} name="OrdersTab" />
            </Stack.Navigator>

        )
    }
}
