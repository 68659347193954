import React, { Component } from 'react';
import { View } from 'react-native';
import { TouchableOpacity } from 'react-native';
import { getInitials, randomColor, ucWords } from '../utils/convrters';
import Text from '../components/WebwedText'
import { Icon } from 'react-native-elements';
import { successColor } from '../configs/themeOptions';
import { EventService } from '../services/events';
import { getEventDetail } from '../stores/actions';
import { connect } from 'react-redux';
import Alert from '../components/Alert'
class ParticipantItem extends Component {

    getStatus = () => {
        const status = this.props.participant.invite_status.split(".").pop()
        switch (status) {
            case 'not_accepted':
                return false
            default:
                return status.toUpperCase()
        }
    }

    removeParticipant = () => {

        Alert.alert("Confirm", `Are you sure to remove ${this.props.participant.name} from participants ?`, [
            {
                text: 'Remove',
                onPress: () => {
                    EventService.removeParticipant(this.props.eventID, this.props.participant.id).then(res => res.json()).then(json => {
                        if(json.status){
                            Alert.alert("Success!!",`${this.props.participant.name} removed from the list`,[{text:'Okay!',onPress: () => {
                                this.props.getEventDetail(this.props.eventID)
                            } }]);
                        }
                    }).catch(err => {
                        console.log(err)
                    })
                }
            },
            {
                text: 'Cancel',
                cancel: 'cancel'
            }
        ])

    }

    render() {


        return (<View style={{ flexBasis: "50%", paddingVertical: 10, justifyContent: "center", alignItems: 'center' }}>
            <View>

                <View style={{ width: 80, height: 80, borderRadius: 50, backgroundColor: randomColor(), justifyContent: "center", alignItems: "center", position: "relative" }}>
                    <Text style={{ fontSize: 16, fontFamily: '"Roboto","Helvetica Neue", sans-serif', color: "#FFF" }}>{getInitials(this.props.participant.name)}</Text>
                    <TouchableOpacity onPress={this.removeParticipant} style={{ position: "absolute", top: 0, right: 5 }}>
                        <Icon name="md-close" reverse color="#F051B9" reverseColor="#FFF" containerStyle={{ padding: 0, margin: 0, height: 20, width: 20, borderRadius: 10, justifyContent: "center", alignItems: "center" }} iconStyle={{ margin: 0, padding: 0, fontWeight: "700" }} size={18} type="ionicon" />
                    </TouchableOpacity>

                    {this.getStatus() && <View style={{ position: "absolute", bottom: -5, left: 0, right: 0, alignItems: "center" }}>
                        <View style={{ backgroundColor: this.getStatus() == 'accepted' ? successColor : "#F051B9", borderRadius: 15, padding: 5 }}>
                            <Text style={{ fontSize: 10, fontWeight: "bold", color: "#FFF" }}>{this.getStatus()}</Text>
                        </View>
                    </View>}

                </View>
                <View style={{ justifyContent: "center", alignItems: "center", marginTop: 5 }}>
                    <Text style={{ marginVertical: 5 }}>{ucWords(this.props.participant.name)}</Text>
                    <Text style={{ fontSize: 10 }}>{ucWords(this.props.participant.role)}</Text>
                </View>
            </View>
        </View>)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getEventDetail: eventID => dispatch(getEventDetail(eventID))
    }
}
export default connect(null, mapDispatchToProps)(ParticipantItem)
