import AsyncStorage from "@react-native-community/async-storage"
import * as firebase from 'firebase';

const TOKEN_KEY = '@id_token';
const PROFILE_KEY = '@webwedprofile';
const RECORD_KEY = '@webwedrecording';

export const Storage = {

    setProfile: async (val) => {
        try {
            await AsyncStorage.setItem(PROFILE_KEY + ":" + val.uid, JSON.stringify(val));

        }
        catch (err) {
            console.log(err);
        }
    },
    getProfile: async () => {
        try {
            const profile = await AsyncStorage.getItem(PROFILE_KEY);
            return JSON.parse(profile);
        }
        catch (err) {
            console.log(err)
        }
    },
    getToken: async () => {
        try {
            const uid_token = await firebase.auth().currentUser.getIdToken();

            return uid_token;
        }
        catch (err) {
            return false;
        }
    },
    setToken: async token => {
        try {
            await AsyncStorage.setItem(TOKEN_KEY, token)
        }
        catch (err) {
            console.log("Failed to set token")
        }
    },
    getRecording: async () => {
        try {
            const recording = await AsyncStorage.getItem(RECORD_KEY)
            if(recording!=null){
                return JSON.parse(recording)
            }
            else{
                return []
            }
        }
        catch (err) {
            console.log("Failed to get recordings")
            return []
        }
    },
    setRecording: async (recordings) => {
        try {
            await AsyncStorage.setItem(RECORD_KEY,JSON.stringify(recordings))
        }
        catch (err) {
            console.log("Failed to get recordings")
        }
    },
}