export const validateEmail = (email) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return reg.test(email)
}

export const validatePassword = (password) => {
    return password.length >= 6;
}

export const validateFullName = name => {
    return name.length > 5 && name.split("").length > 1;
}