import React, { Component } from 'react';
import DatePicker from 'react-date-picker';
import { TouchableOpacity } from 'react-native';
import { Linking, StyleSheet, View } from 'react-native';
import { Button, ButtonGroup, CheckBox, Icon, Input, ListItem } from 'react-native-elements';
import { ScrollView } from 'react-native-gesture-handler';
import { connect } from 'react-redux';
import LoadingModal from '../components/LoadingModal';
import PackageList from '../components/PackageListItem';
import TimeModal from '../components/TimeModal';
import WebwedPicker from '../components/WebwedPicker';
import { color2, color3, primaryColor } from '../configs/themeOptions';
import { legalURL } from '../services/masters';
import { getEventSuccess, getPackageAddons, uploadCover } from '../stores/actions';
import Text from '../components/WebWedText'

const d = new Date();
class CreateEvent extends Component {

  state = {
    eventTitle: '',
    eventCover: '',
    eventDate: '',
    eventTime: '10:00',
    package: { title: '', price: '' },
    addons: [],
    timepickerTitle: 'Event Date',

    showDatePicker: false,
    mode: 'date',
    selectedIndex: 0,
    event_date_display: '',
    event_time_display: 'Select Time',
    showContinue: false,

    invalidForm: true,
    showModal: false,
    showCheckoutForm: false,
    showCheckOutBtn: false,
    isToday: true,

    ccName: '',
    ccNumber: '',
    ccExpiry: '',
    ccCvc: '',
    ccType: '',
  }

  componentDidMount() {
    this.props.getPackageAddons()
  }


  handleAddOn = (i) => {
    let addons = this.state.addons

    if (!addons[i]) {
      addons[i] = this.props.addonList[i]
    }
    else {
      delete addons[i];
    }
    this.setState({ addons: addons })
  }

  handleTitle = (t) => {
    if (t.length > 0) {
      this.setState({ eventTitle: t, showContinue: this.state.eventDate && this.state.eventTime });
    }
    else {
      this.setState({ eventTitle: t, showContinue: false });
    }
  }

  selectDate = d => {
    const n = new Date()

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const ymd = d.getUTCFullYear() + "-" + ("0" + (d.getUTCMonth() + 1)).slice(-2) + "-" + ("0" + d.getUTCDate()).slice(-2)

    this.setState({
      showDatePicker: false,
      event_date_display: d,
      eventDate: ymd,
      showContinue: this.state.eventTitle && this.state.eventTime,
      isToday: !(d.getFullYear() >= n.getFullYear() && d.getMonth() >= n.getMonth() && d.getDate() > n.getDate())
    })
  }

  selectTime = d => {

    this.setState({
      eventTime: d.formatted24,
      event_time_display: d.formatted12,
      showDatePicker: false,
      showContinue: this.state.eventTitle && this.state.eventDate
    })
  }

  handleDatePicker = (mode) => {
    this.setState({ showDatePicker: true, mode: mode, timepickerTitle: mode == 'date' ? 'Event Date' : 'Event Time' })
  }

  updateIndex = (newIndex) => {
    this.setState({ selectedIndex: newIndex, package: this.props.packageList[newIndex] })
  }

  openTerms = async () => {
    Linking.openURL(legalURL);
  }

  reviewOrder = () => {
    const packageDetails = this.props.packageList[this.state.selectedIndex]

    const event = {
      eventTitle: this.state.eventTitle,
      eventDate: this.state.eventDate,
      eventTime: this.state.eventTime,
      eventCover: this.state.eventCover
    }
    this.props.navigation.navigate('ReviewOrder', {
      event: event,
      title: 'Review Order',
      packageDetail: packageDetails,
      addons: this.state.addons
    })
  }


  calculatePrice = () => {

    let price = this.props.packageList ? this.props.packageList[this.state.selectedIndex].price || 0 : 0

    if (this.state.addons.length > 0) {
      price += this.state.addons.reduce(
        (cnt, n) => (parseFloat(cnt + n.price)), 0)
    }

    return price
  }

  ccChange = form => {
    this.setState({
      showCheckOutBtn: form.valid,
      ccName: form.values.name,
      ccNumber: form.values.number,
      ccExpiry: form.values.expiry,
      ccCvc: form.values.cvc,
      ccType: form.values.type,
    })
  }

  render() {

    let buttons = this.props.packageList ? this.props.packageList.map((v, i) => ({ element: () => (<PackageList color={i == this.state.selectedIndex ? "#FFF" : primaryColor} package={v} />) })) : [];

    return (

      <View>
        <WebwedPicker containerStyle={{ marginBottom: 20 }} iconType='simple-line-icon' icon="camera" color={color2} title="Add Photo" onPress={eventCover => { this.setState({ eventCover: eventCover }) }} />

        <Text style={styles.title}>EVENT DETAILS</Text>
        <View style={styles.viewGroup}>
          <Input
            inputStyle={styles.input}
            placeholderTextColor="gray"
            placeholder='Event Title'
            textContentType="name"
            leftIcon={<Icon type="ionicon" name="md-person" size={24} color='gray' />}
            onChangeText={t => this.setState({ eventTitle: t })}
          />
          <DatePicker
            onChange={this.selectDate}
            value={this.state.event_date_display}
          />

          <TouchableOpacity onPress={() => this.setState({ showDatePicker: true })}>
            <Text style={{paddingVertical:10,borderBottomWidth:1,borderBottomColor: primaryColor,margin:10}}>
              {this.state.event_time_display}
            </Text>
          </TouchableOpacity>

          <TimeModal visible={this.state.showDatePicker} selectTime={this.selectTime} />

          <Text style={styles.title}>LIVE STREAMING TIME</Text>
          <ButtonGroup
            onPress={this.updateIndex}
            selectedIndex={this.state.selectedIndex}
            buttons={buttons}
            innerBorderStyle={{ width: 0 }}
            containerStyle={{ borderWidth: 0, height: 60, backgroundColor: "transparent" }}
            // selectedButtonStyle={{}}
            selectedButtonStyle={{ backgroundColor: color3, borderRadius: 10 }}
          />
        </View>
        <Text style={styles.title}>PREMIUM ADD-ONS</Text>
        <View style={styles.title}>
          {
            this.props.addonList?.map((l, i) => (
              <ListItem
                key={i}
                bottomDivider
              >
                <CheckBox title={l.title} checked={this.state.addons[i] ? true : false} containerStyle={{ backgroundColor: "transparent", borderWidth: 0 }} onPress={() => this.handleAddOn(i)} />
                <ListItem.Content />
                <Text>${l.price}</Text>
              </ListItem>
            ))
          }
        </View>
        <Text style={styles.termsp}>By continuing I have read and understood the <Text style={styles.terms} onPress={this.openTerms}> TERMS OF SERVICE</Text></Text>
        <View style={{ flex: 1 }}>
          <Button buttonStyle={{ backgroundColor: color3 }} onPress={this.reviewOrder} disabled={!(this.state.eventTitle && this.state.eventDate && this.state.eventTime)} title="Continue" />
        </View>
        {this.props.loading && <LoadingModal title="Please wait..." />}
      </View>
    )
  }
}

const mapStateToProps = state => ({
  packageList: state.packages,
  addonList: state.addons,
  loading: state.loading,
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  getPackageAddons: () => dispatch(getPackageAddons()),
  getEventSuccess: (events) => dispatch(getEventSuccess(events)),
  uploadCover: (url, cover, fields) => dispatch(uploadCover(url, cover, fields))

})


export default connect(mapStateToProps, mapDispatchToProps)(CreateEvent);


const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  input: { marginBottom: 0, color: 'gray' },
  cover: { backgroundColor: "#075193", flex: 1, minHeight: 250, alignItems: "center", justifyContent: "center" },
  img: { flexDirection: "row", width: "100%", height: 250 },
  title: { margin: 10, fontSize: 16 },
  viewGroup: { backgroundColor: "#f7f7f7", marginBottom: 10 },
  termsp: {
    color: "gray",
    alignContent: "center",
    paddingHorizontal: 10,
    marginBottom: 20
  },
  terms: {
    color: "#023C6F"
  },
  datetime: { paddingBottom: 10, marginHorizontal: 10, marginBottom: 10, color: 'gray', backgroundColor: "transparent", borderBottomWidth: 1, borderColor: 'gray' },

})