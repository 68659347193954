import {
    DrawerContentScrollView,

    DrawerItem
} from '@react-navigation/drawer';
import React, { Component } from 'react';
import { Image, Linking, StyleSheet, View } from 'react-native';
import { Button, Icon } from 'react-native-elements';
import { connect } from 'react-redux';
import { primaryColor, secondaryColor } from '../configs/themeOptions';
import { signOut } from '../stores/actions';
import { getInitials, ucWords } from '../utils/convrters';
import Text from '../components/WebwedText'

class WebwedDrawer extends Component {

    openMail = () => {

        const mailData = {
            to: 'info@webwedmobile.com',
            subject: 'WebWedMobile - Become Officiant',
            body: 'Hello, I want to become an officiant.',
        }

        Linking.openURL("mailTo:" + mailData.to + "?subject=" + mailData.subject + "&body=" + mailData.body)
    }

    handleLogout = () => {
        this.props.signOut()
    }

    render() {
        return (
            <>
                <DrawerContentScrollView {...this.props} style={{ position: "relative" }}>

                    <View style={{ flex: 1, backgroundColor: primaryColor, marginTop: -5, paddingVertical: 10, justifyContent: "center", alignItems: 'center' }}>

                        {/* <Image source={require('../assets/images/logo-full.png')} style={{ alignSelf: 'center' }} /> */}
                        <View style={{ width: 100, height: 100, borderRadius: 50, backgroundColor: '#FFF', justifyContent: "center", alignItems: "center" }}>
                            <Text style={{ fontSize: 24, fontFamily: '"Roboto","Helvetica Neue", sans-serif', color: primaryColor }}>{getInitials(this.props.user.displayName)}</Text>
                        </View>
                        <Text style={{ fontSize: 18, color: 'white', marginVertical: 5 }}> {ucWords(this.props.user.displayName)}</Text>
                        <Button title="Sign out" type="clear" titleStyle={{color:secondaryColor,fontFamily:'"Roboto","Helvetica Neue", sans-serif'}}  buttonStyle={{backgroundColor:"transparent"}} onPress={this.handleLogout}></Button>
                    </View>
                    {/* <DrawerItemList {...this.props} /> */}
                    <View style={{ backgroundColor: "#FFF" }}>
                        <DrawerItem
                            label="Create an Event"
                            inactiveTintColor={primaryColor}
                            icon={({ focused, color, size }) => <Icon type="ionicon" name="md-contacts" color={color} size={size} />}
                            onPress={() => this.props.navigation.navigate('CreateEvent')}
                        />
                        <DrawerItem
                            label="My Events"
                            inactiveTintColor={primaryColor}
                            icon={({ focused, color, size }) => <Icon type="ionicon" name="md-heart" color={color} size={size} />}
                            onPress={() => this.props.navigation.navigate('Dashboard')}

                        /><DrawerItem
                            label="Order History"
                            inactiveTintColor={primaryColor}
                            icon={({ focused, color, size }) => <Icon type="ionicon" name="md-book" color={color} size={size} />}
                            onPress={() => this.props.navigation.navigate('ProfileTabs', { screen: 'Orders', name: 'My Orders' })}
                        /><DrawerItem
                            label="My Account"
                            inactiveTintColor={primaryColor}
                            icon={({ focused, color, size }) => <Icon type="ionicon" name="md-person" color={color} size={size} />}
                            onPress={() => this.props.navigation.navigate('ProfileTabs', { screen: 'Profile', name: "My Profile" })}
                        />
                        <DrawerItem
                            label="Become an officiant"
                            inactiveTintColor={primaryColor}
                            icon={({ focused, color, size }) => <Icon type="ionicon" name="md-school" color={color} size={size} />}
                            onPress={this.openMail}

                        />
                        <DrawerItem
                            label="Legal and Terms"
                            inactiveTintColor={primaryColor}
                            icon={({ focused, color, size }) => <Icon type="ionicon" name="md-star" color={color} size={size} />}
                            onPress={() => Linking.openURL('https://webwedmobile.com/legal')}
                        />
                    </View>
                </DrawerContentScrollView>
                <View style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
                    <View style={{backgroundColor: primaryColor,flexDirection:"row",height:50, justifyContent: "center", alignItems: "center"}}>
                        <Image source={require('../assets/images/WebWed-Logo.png')} resizeMode="contain" style={{width:50}} />
                    </View>
                </View>
            </>
        )
    }
}

const styles = StyleSheet.create({
    img: {
        width: 100,
        height:50,
    }
})


const mapStateToProps = state => {
    return {
        loading: state.loading,
        user: state.user
    }
};
const mapDispatchToProps = dispatch => {
    return {
        signOut: () => dispatch(signOut())
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(WebwedDrawer);
