import React, { Component } from 'react'
import { Animated } from 'react-native'
import { Icon } from 'react-native-elements'
import { color2 } from '../configs/themeOptions';

export default class AnimatingHeart extends Component {
    state = { fadeIn: new Animated.Value(0) };
    componentDidMount() {
        this.fadeIn()
    }
    fadeIn() {
        Animated.timing(
            this.state.fadeIn,
            {
                toValue: 1,
                duration: 1000,
                useNativeDriver: true
            }
        ).start(() => this.fadeOut());
    }

    fadeOut() {
        Animated.timing(
            this.state.fadeIn,
            {
                toValue: 0,
                duration: 1000,
                useNativeDriver: true

            }
        ).start( () => this.fadeIn());
    }
    render() {
        return (
            <Animated.View style={{ opacity: this.state.fadeIn}}>
                <Icon type="material-community" name="heart" reverse color="tomato" reverseColor="#FFF" />
            </Animated.View>
        )
    }
}
