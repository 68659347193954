import React, { Component } from 'react';
import { Alert, StyleSheet, View } from 'react-native';
import { Button, Input } from 'react-native-elements';
import { ScrollView } from 'react-native';
import { connect } from 'react-redux';
import LoadingModal from '../components/LoadingModal';
import WebwedLogo from '../components/WebwedLogo';
import { autoLogin, blankErr, login } from '../stores/actions';


class JoinEvent extends Component {

    state = {
        name: "",
        email: "",
        eventID: "",
        email_error: "",
        name_error: ""
    }

    handleEmail = (e) => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (reg.test(e) === false) {
            this.setState({ email_error: "Invalid Email", email: e });
        }
        else {
            this.setState({ email_error: "", email: e });
        }
    }

    handleName = (e) => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (e.length < 6) {
            this.setState({ name_error: "name should be at least 6 character long", name: e });
        }
        else {
            if (e.trim().indexOf(" ") == -1) {
                this.setState({ name_error: "please provide your firstname and lastname", name: e });
            }
            this.setState({ name_error: "", name: e });
        }
    }

    handleEventID = e => {
        this.setState({ eventID: e });
    }

    handleJoin = () => {
        if (this.state.name_error) {
            Alert.alert("Failed!!", "Provide valid name", [
                {
                    text: "Ok",
                    style: "cancel"
                },
            ]);
        }
        else {
            this.setState({
                clicked: false,
                password: ''
            })
            this.props.navigation.navigate('Viewer', { viewerName: this.state.name, eventID: this.state.eventID })
        }
    }

    componentDidMount(){
        this.setState({eventID:this.props.route.params?.event})
    }
    render() {
        return (
            <View style={styles.container}>
                <View style={{ flex: 1, justifyContent: "center" }}>
                    <WebwedLogo containerStyle={{ marginBottom: 10 }} />
                    <ScrollView>
                        <View>
                            <Input
                                inputContainerStyle={{ borderBottomWidth: 0 }}
                                inputStyle={{ backgroundColor: 'white', padding: 10 }}
                                placeholder='Full name eg. John Doe'
                                errorMessage={this.state.name_error}
                                renderErrorMessage={true}
                                onChangeText={this.handleName}
                            />

                            <Input
                                inputContainerStyle={{ borderBottomWidth: 0, width: '100%', alignSelf: 'center' }}
                                inputStyle={{ backgroundColor: 'white', padding: 10 }}
                                placeholder='Event Id'
                                value={this.state.eventCode}
                                keyboardType="number-pad"
                                errorMessage={this.state.event_error}
                                renderErrorMessage={true}
                                onChangeText={this.handleEventID}
                            />
                            <View style={{ padding: 10 }}>
                                <Button
                                    titleStyle={{ fontFamily: '"Roboto","Helvetica Neue", sans-serif' }}
                                    title="Continue"
                                    disabled={this.state.email_error.length > 0 || this.state.name_error > 0 || this.state.event_error > 0}
                                    buttonStyle={{ padding: 10 }}
                                    style={{ paddingTop: 10, paddingBottom: 10 }}
                                    onPress={e => this.handleJoin()} />
                            </View>
                        </View>
                    </ScrollView>
                </View>
                {this.props.loading && <LoadingModal title="Loading..." />}
            </View>
        )
    }
}
const mapStateToProps = (state) => ({
    err: state.err,
    loading: state.loading
})

const mapDispatchToProps = dispatch => ({
    login: (email, password) => dispatch(login(email, password)),
    autoLogin: () => dispatch(autoLogin()),
    blankErr: err => dispatch(blankErr(err))
})

export default connect(mapStateToProps, mapDispatchToProps)(JoinEvent);

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#023C6F',
        justifyContent: "center"
    },
    loginText: {
        fontSize: 16,
        color: 'white',
        fontFamily: '"Roboto","Helvetica Neue", sans-serif',
        marginBottom: 30,
        alignSelf: "center"
    }
})
