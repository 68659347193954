
import React, { Component } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
// import ViewPager from '@react-native-community/viewpager';
import { Button, Icon, Input } from 'react-native-elements';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import DateTimePickerModal from "react-native-modal-datetime-picker";
import DatePicker from 'react-date-picker';

import { connect } from 'react-redux';
import LoadingModal from '../components/LoadingModal';
import WebwedLogo from '../components/WebwedLogo';
import { blankErr, loginSuccess, signup } from '../stores/actions';
import { validateEmail, validateFullName, validatePassword } from '../utils/validators';
import Text from '../components/WebwedText'
import { primaryColor } from '../configs/themeOptions';
import * as firebase from 'firebase';
import { UserService } from '../services/user';

const d = new Date()
d.setFullYear(d.getFullYear() - 18)

class Signup extends Component {
    // viewPager: React.Ref<typeof ViewPager>;

    constructor(props) {
        super(props);
        this.viewPager = React.createRef();
    }

    state = {
        loading: false,
        error: false,
        email: '',
        password: '',
        address: '',
        full_name: '',
        mailing_address: '',
        validEmailPass: false,
        showDatePicker: false,
        dob: false,
        phone: '',
        dobYmd: '',
        errors: {
            fname: '',
            email: '',
            pass: ''
        }
    }

    handleEmail = value => {
        const isDataValid = this.emailPassValid(value, this.state.password, this.state.address, this.state.mailing_address, this.state.dobYmd, this.state.full_name)
        this.setState({ validEmailPass: isDataValid, email: value, errors: { ...this.state.errors, email: !validateEmail(value) ? 'Provide a valid email' : '' } });
    }

    handlePhone = value => {
        this.setState({ phone: value, errors: { ...this.state.errors, phone: value.length == 0 ? 'Phone required.' : '' } });
    }

    handlePassword = value => {
        const isDataValid = this.emailPassValid(this.state.email, value, this.state.address, this.state.mailing_address, this.state.dobYmd, this.state.full_name)
        this.setState({ validEmailPass: isDataValid, password: value, errors: { ...this.state.errors, pass: !validatePassword(value) ? 'Password must be at least 6 char long' : '' } });
    }

    emailPassValid(email, password, address, mailing_address, dobYmd, full_name) {
        return validateEmail(email) && validatePassword(password) && address && mailing_address && dobYmd && validateFullName(full_name);
    }

    handleContinue = () => {
        // register to firebase 

        const user = {
            full_name: this.state.full_name,
            email: this.state.email,
            address: this.state.address,
            mailing_address: this.state.mailing_address,
            phone: this.state.phone,
            birthday: this.state.dobYmd,
            password: this.state.password
        }
        this.signupNow(user)

        // create profile
    }

    handleName = value => {

        const isDataValid = this.emailPassValid(this.state.email, this.state.password, this.state.address, this.state.mailing_address, this.state.dobYmd, value)
        this.setState({ validEmailPass: isDataValid, full_name: value, errors: { ...this.state.errors, fn: !validateFullName(value) ? 'Provide first and last name ' : '' } })
    }

    handleDatePicker = () => {

        this.setState({ showDatePicker: true })
    }

    selectDoB = d => {

        const n = new Date()

        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const ymd = d.getUTCFullYear() + "-" + ("0" + (d.getUTCMonth() + 1)).slice(-2) + "-" + ("0" + d.getUTCDate()).slice(-2)
        const isDataValid = this.emailPassValid(this.state.email, this.state.password, this.state.address, this.state.mailing_address, ymd, this.state.full_name)
        this.setState({
            dob: d,
            dobYmd: ymd,
            validEmailPass: isDataValid,
            isToday: !(d.getFullYear() >= n.getFullYear() && d.getMonth() >= n.getMonth() && d.getDate() > n.getDate())
        })
    }

    handleAddress = value => {
        const isDataValid = this.emailPassValid(this.state.email, this.state.password, value, this.state.mailing_address, this.state.dobYmd, this.state.full_name)
        this.setState({ address: value, validEmailPass: isDataValid })
    }

    handleMailingAddress = value => {
        const isDataValid = this.emailPassValid(this.state.email, this.state.password, this.state.address, value, this.state.dobYmd, this.state.full_name)
        this.setState({ mailing_address: value, validEmailPass: isDataValid })
    }

    signupNow = user => {
        this.setState({ loading: true })
        firebase.auth().createUserWithEmailAndPassword(user.email, user.password).then(
            cred => {
                const userProfile = {
                    full_name: user.full_name,
                    email: user.email,
                    address: user.address,
                    mailing_address: user.mailing_address,
                    birthday: user.birthday,
                    uid: cred.user.uid,
                    phone: user.phone
                }

                firebase.auth().currentUser.updateProfile({
                    displayName: user.full_name
                }).then(function () {
                    // console.log("profile updated successfully")
                }).catch(function (error) {
                });

                UserService.createProfile(userProfile).then(res => res.json()).then(json => {
                    // console.log("Json......",json)
                    if (json.message) {
                        this.props.loginSuccess(cred.user)
                    }
                    else {
                        this.setState({ loading: false, error: json.error })
                    }
                }).catch(err => {
                    this.setState({ loading: false, error: err.message })
                })
            }
        ).catch(
            err => {
                console.log(err);
                this.setState({ loading: false, error: err.message })
            }
        )
    }

    render() {

        return (<View style={{ flex: 1 }}>
            <KeyboardAwareScrollView style={styles.container}>

                <WebwedLogo />
                <View style={{ flexDirection: "row", justifyContent: "center", marginVertical: 20 }}>
                    <Text style={styles.loginText}>Already have an account? </Text>
                    <TouchableOpacity onPress={() => this.props.navigation.goBack()}>
                        <Text style={[styles.loginText, { color: '#5aabd3', marginHorizontal: 5 }]}>Sign in</Text>
                    </TouchableOpacity>
                </View>
                {this.state.error && <View style={{ justifyContent: "center" }}>
                    <Text style={{ color: "red", margin: 10 }}>
                        {this.state.error}
                    </Text>
                </View>}
                <Input
                    inputContainerStyle={styles.inputContainer}
                    inputStyle={styles.inputStyle}
                    placeholder='Full Name'
                    textContentType="username"
                    autoCapitalize="words"
                    errorMessage={this.state.errors.fn}
                    renderErrorMessage={true}
                    leftIcon={<Icon type="ionicon" name="md-person" size={24} color={primaryColor} />}
                    onChangeText={this.handleName}

                />

                <Text style={{ marginHorizontal: 15, marginVertical: 10, fontSize: 16, color: "#FFF" }}>Date of Birth</Text>
                <DatePicker

                    onChange={this.selectDoB}
                    value={this.state.dob}
                />

                <Input
                    inputContainerStyle={styles.inputContainer}
                    inputStyle={styles.inputStyle}

                    placeholder='Home address'
                    textContentType="location"
                    errorMessage={this.state.errors.addr}
                    renderErrorMessage={true}
                    leftIcon={<Icon type="ionicon" name="md-pin" size={24} color={primaryColor} />}
                    onChangeText={this.handleAddress}


                />
                <Input
                    inputContainerStyle={styles.inputContainer}
                    inputStyle={styles.inputStyle}

                    placeholder='Mailing address'
                    textContentType="location"
                    errorMessage={this.state.errors.maddr}
                    renderErrorMessage={true}
                    leftIcon={<Icon type="ionicon" name="md-pin" size={24} color={primaryColor} />}
                    onChangeText={this.handleMailingAddress}

                />
                <Input
                    inputContainerStyle={styles.inputContainer}
                    inputStyle={styles.inputStyle}

                    placeholder='Email'
                    keyboardType="email-address"
                    autoCapitalize="none"
                    textContentType="emailAddress"
                    errorMessage={this.state.errors.email}
                    renderErrorMessage={true}
                    leftIcon={<Icon type="ionicon" name="md-mail" size={24} color={primaryColor} />}
                    onChangeText={this.handleEmail}

                />
                <Input
                    inputContainerStyle={styles.inputContainer}
                    inputStyle={styles.inputStyle}
                    placeholder='Phone'
                    keyboardType="phone-pad"
                    textContentType="telephoneNumber"
                    errorMessage={this.state.errors.phone}
                    renderErrorMessage={true}
                    leftIcon={<Icon type="ionicon" name="md-call" size={24} color={primaryColor} />}
                    onChangeText={this.handlePhone}

                />
                <Input
                    inputContainerStyle={styles.inputContainer}
                    inputStyle={styles.inputStyle}
                    containerStyle={{ marginBottom: 50 }}
                    placeholder='Password'
                    textContentType="password"
                    secureTextEntry={true}
                    errorMessage={this.state.errors.pass}
                    renderErrorMessage={true}

                    leftIcon={<Icon type="ionicon" name="md-key" size={24} color={primaryColor} />}
                    onChangeText={this.handlePassword}
                />


                {this.state.loading && <LoadingModal title="Please wait..." />}
            </KeyboardAwareScrollView>
            <View style={{ flex: 1, marginBottom: 0, position: "absolute", bottom: 0, left: 0, right: 0 }}>
                <Button title="Continue" onPress={this.handleContinue} disabled={!this.state.validEmailPass} disabledStyle={{ opacity: 0.4, backgroundColor: "#075193" }} buttonStyle={{ backgroundColor: "#075193", paddingVertical: 15 }} titleStyle={{ fontSize: 18, fontFamily: '"Roboto","Helvetica Neue", sans-serif' }} ></Button>
            </View></View>
        );
    }

}

const mapStateToProps = state => {
    return {
        loading: state.loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loginSuccess: user => dispatch(loginSuccess(user)),
        blankErr: err => dispatch(blankErr(err))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup);

const styles = StyleSheet.create({
    inputContainer: { backgroundColor: 'white', borderBottomWidth: 0, paddingVertical: 5, paddingHorizontal: 10, borderRadius: 5 },
    inputStyle: { backgroundColor: 'white', margin: 5, color: "grey" },
    container: {
        backgroundColor: '#023C6F',
    },
    loginText: {
        fontSize: 20,
        textAlign: "center",
        color: 'white',
        fontFamily: '"Roboto","Helvetica Neue", sans-serif',
        marginBottom: 10,
    }
})
