import { createStackNavigator } from '@react-navigation/stack';
import React, { Component } from 'react';
import { TouchableOpacity } from 'react-native';
import { Image } from 'react-native';
import { Icon } from 'react-native-elements';
import NavigationDrawerIcon from '../components/NavigationDrawerIcon';
import NavigationRightIcons from '../components/NavigationRightIcons';
import { color4 } from '../configs/themeOptions';
import ProfileTab from '../pages/ProfileTab';


const Stack = createStackNavigator();

const img = require('../assets/images/logo-full.png');

export default class ProfileStack extends Component {
    render() {
        return (
            <Stack.Navigator screenOptions={{
                headerRight: () => (<NavigationRightIcons />),
                headerStyle: {
                    backgroundColor: '#023C6F',
                    elevation: 0
                },
                headerLeftContainerStyle: { marginLeft: 15, marginRight: 0 },
                headerTitleAlign: "left",
                headerTitle: "My Profile",
                headerRight: () => (<NavigationRightIcons navigation={this.props.navigation} />),
                headerLeft: () => {
                    return (<TouchableOpacity onPress={() => { this.props.navigation.goBack() }}>
                        <Icon
                            type="ionicon"
                            name="md-arrow-back"
                            size={24}
                            color="white" />
                    </TouchableOpacity>)
                },
                headerTintColor: '#fff',
            }}
            >
                <Stack.Screen component={ProfileTab} name="ProfileTab" />
            </Stack.Navigator>

        )
    }
}
