import React, { Component } from 'react'
import { Image, TouchableOpacity, View } from 'react-native';
import { Icon } from 'react-native-elements';
import { connect } from 'react-redux'
import Alert from '../components/Alert'
import { EventService } from '../services/events';
import "../assets/stream.css"
import { confirmAlert } from 'react-confirm-alert';
import Text from '../components/WebWedText'
import { Dimensions } from 'react-native';

export class LiveStream extends Component {

    state = {
        sessionID: '',
        apiKey: '',
        token: '',
        notInvited: false,
        eventOver: false,
        publishAudio: true,
        publishVideo: true,
        videoSource: 'camera',
        totalViewers: 0,
        totalGuests:0,
        signal: {
            data: 0,
            type: '',
        },
        text: '',
        messages: [],
        session: {},
        eventID: '',
        eventDetail: { addons: [], title: '', participants: [], guests: [], host: { uid: '' } },
        archive: false,
        isScreenRecording: false,
        recordedVideos: false,
        remainingMinutes: 360,
        publisherInitiated: false,
        pub: {
            width: '100%',
            height: '100%',
            rowBasis:'100%'
        }
    }
    startSession = () => {
        const OT = require('@opentok/client');

        const eventID = this.props.route.params.eventID
        const isParticipant = this.props.route.params.isParticipant
        const eventDetail = this.props.route.params.eventDetail


        if (isParticipant) {
            EventService.joinAsParticipant(eventID).then(res => res.json()).then(json => {
                console.log(json)
                if (json.status) {
                    switch (json.status) {
                        case 1:
                            Alert.alert("Event Over!!", json.error, [{
                                text: "Okay",
                                onPress: () => this.props.navigation.pop(),
                                style: "cancel"
                            },
                            ])
                            break;

                        case 2:
                            // event is not started yet
                            // show modal with message event not started yet
                            this.initSession(json, eventID)
                            this.startTimeout(eventDetail)

                            break;
                        case 3:
                            // you are not invited
                            // show modal with message event not started yet
                            Alert.alert("Not Invited", "Oops! seems you are not invited", [{
                                text: "Okay",
                                onPress: () => this.props.navigation.pop(),
                                style: "cancel"
                            },
                            ])
                            break;
                        case 4:
                            // event is over
                            // show modal with message event is over
                            Alert.alert("Event Over", "Oh! you are late, event is over.", [{
                                text: "Cancel",
                                onPress: () => this.props.navigation.pop(),
                                style: "cancel"
                            },
                            ])
                            break;
                        case 5:
                            // server error
                            // show alert and redirect to details page
                            Alert.alert("Failed!", "Something went wrong", [{
                                text: "Cancel",
                                onPress: () => this.props.navigation.pop(),
                                style: "cancel"
                            },
                            ])
                            break;
                        default:
                            break;
                    }
                }
                else {
                    this.initSession(json, eventID)
                    this.startTimeout(eventDetail)

                }
            }).catch(err => {
                console.log(err)
            })
        }
        else {
            EventService.startSession(eventID).then(res => res.json()).then(json => {
                if (json.error) {
                    Alert.alert("Error", json.error, [{
                        text: "Okay",
                        onPress: () => this.props.navigation.pop(),
                        style: "cancel"
                    },
                    ])
                }
                else {

                    if (json.status) {
                        switch (json.status) {
                            case 1:
                                Alert.alert("Event Over!!", json.error, [{
                                    text: "Okay",
                                    onPress: () => this.props.navigation.pop(),
                                    style: "cancel"
                                },
                                ])
                                break;

                        }
                    } else {
                        this.initSession(json, eventID)
                        this.startTimeout(eventDetail)
                    }
                }
            }).catch(err => {
                console.log(err)
            })
        }

    }

    getPublisherSize = () => {
        const dim = Dimensions.get('window')
        const totalViewers = this.state.totalViewers - this.state.totalGuests

        if (totalViewers == 0) {

            this.setState({ pub : { width: dim.width, height: dim.height } })
        }
        else {
            switch (totalViewers) {
                case 1:
                case 2:
                    console.log("1 viewer...")
                    this.setState({ pub : { width: dim.width, height: dim.height / 2, rowBasis: '100%' } })
                    break;
                case 3:
                case 4:
                    this.setState({ pub : { width: dim.width / 2, height: dim.height / 2, rowBasis: '50%' } })
                    break;

                case 5:
                case 6:
                case 7:
                    this.setState({ pub : { width: dim.width / 2, height: dim.height / 3, rowBasis: '50%' } })
                    break;

                case 8:
                case 9:
                    this.setState({ pub : { width: dim.width / 3, height: dim.height / 3, rowBasis: '33%' } })
                    break;

                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                    this.setState({ pub : { width: dim.width / 4, height: dim.height / 4, rowBasis: '25%' } })
                    break;

                default:
                    if (totalViewers > 15 && totalViewers < 20) {
                        this.setState({ pub : { width: dim.width / 4, height: dim.height / 5,rowBasis: '25%' } })
                    }
                    else {
                        this.setState({ pub : { width: dim.width / 5, height: dim.height / 5, rowBasis: '25%' } })
                    }
            }
        }
    }


    getSubscriberSize = (idx) => {
        const dim = Dimensions.get('window')

        const totalViewers = this.state.totalViewers - this.state.totalGuests
        switch (totalViewers) {
            case 1:
            case 0:
                return { width: dim.width, height: dim.height / 2, rowBasis: '100%' }
            case 2:
            case 3:
                return { width: dim.width / 2, height: dim.height / 2, rowBasis: '50%' }
            case 4:
                if (idx == 0) {
                    return { width: dim.width / 2, height: dim.height / 2, rowBasis: '50%' }
                }
                else {
                    return { width: dim.width / 3, height: dim.height / 2, rowBasis: '33%' }
                }
            case 5:
                return { width: dim.width / 2, height: dim.height / 3, rowBasis: '50%' }
            case 6:
                if (idx < 3) {
                    return { width: dim.width / 2, height: dim.height / 3, rowBasis: '50%' }
                }
                else {
                    return { width: dim.width / 3, height: dim.height / 3, rowBasis: '33%' }
                }
            case 7:
                if (idx == 0) {
                    return { width: dim.width / 2, height: dim.height / 3, rowBasis: '50%' }
                }
                else {
                    return { width: dim.width / 3, height: dim.height / 3, rowBasis: '33%' }
                }
            case 8:
                return { width: dim.width / 3, height: dim.height / 3, rowBasis: '33%' }

            case 9:
                if (idx < 5) {
                    return { width: dim.width / 3, height: dim.height / 3, rowBasis: '33%' }
                }
                else {
                    return { width: dim.width / 4, height: dim.height / 3, rowBasis: '33%' }
                }
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
                return { width: dim.width / 4, height: dim.height / 4, rowBasis: '25%' }
            default:
                if (id > 15 && idx < 20) {
                    return { width: dim.width / 4, height: dim.height / 5 }
                }
                else {
                    return { width: dim.width / 5, height: dim.height / 5, rowBasis: '25%' }
                }

        }
    }



    initSession = (json, eventID) => {
        let subidx = 0
        this.setState({
            sessionID: json.session_details.session_id,
            apiKey: json.session_details.key,
            token: json.session_details.token,
            eventID: eventID
        })
        this.session = OT.initSession(json.session_details.key, json.session_details.session_id);

        this.session.on('streamCreated', event => {
            console.log("Subidx", this.getSubscriberSize(subidx))
            const size = this.getSubscriberSize(subidx)
            const subs = this.session.subscribe(event.stream, 'subscribers', {
                insertMode: 'append',
                width: size.width,
                height: size.height
            }, err => console.log("Err", err));

            subidx += 1

            subs.on('connected', (event) => {
                const conectionData = JSON.parse(event.data)
                this.setState({ 
                    totalViewers: this.state.totalViewers + 1,
                    totalGuests: conectionData.type == 'guest' ? (this.state.totalGuests + 1) : this.state.totalGuests 
                })
                this.getPublisherSize()
            })

            subs.on('destroyed', (event) => {
                const conectionData = JSON.parse(event.data)
                this.setState({ 
                    totalViewers: this.state.totalViewers - 1,
                    totalGuests: conectionData.type == 'guest' ? (this.state.totalGuests - 1) : this.state.totalGuests 
                 })
                this.getPublisherSize()

            })
        });

        this.session.on('connectionCreated', event => {

        })

        this.session.on('signal', event => {
            console.log("Signal received...")
            if (event.data) {

                if (event.data == 'EVENTCOMPLETE') {
                    this.props.navigation.replace('EventCompleted', { eventID: this.state.eventDetail.id })
                }
            }
        })

        if (!this.state.publisherInitiated) {
            this.publisher = OT.initPublisher('publisher', {
                insertMode: 'append',
                width: '100%',
                height: '100%'
            }, err => console.log(err));


            this.session?.publish(this.publisher, err => console.log("Err", err));
            this.setState({ publisherInitiated: true })
        }

        this.session.connect(json.session_details.token, (error) => {
            // If the connection is successful, publish to the session
            if (error) {
                console.log(error);
            }
            else {
                this.session?.publish(this.publisher, err => console.log("Err", err));
                this.setState({ publisherInitiated: true })
            }
        });
    }

    startTimeout = eventDetail => {
        const remainingSec = parseInt(eventDetail.package.allotted_time) * 60 - eventDetail.consumed_seconds
        setTimeout(() => {
            this.session.signal({
                type: 'EVENTCOMPLETE',
                data: 'EVENTOVER',
            }, err => {
                if (err) {
                    console.log("Signal failed...")
                }
            })
        }, remainingSec * 1000)
    }


    toggleVideo = () => {
        this.publisher.publishVideo(!this.state.publishVideo)
        this.setState({ publishVideo: !this.state.publishVideo })
    }

    toggleAudio = () => {
        this.publisher.publishAudio(!this.state.publishAudio)
        this.setState({ publishAudio: !this.state.publishAudio })
    }

    leaveEvent = () => {
        if (this.props.route.params.isParticipant) {
            Alert.alert("Confirm!!", "Are you sure to leave this event? nevermind you can join later.", [
                { text: "Leave", onPress: () => this.props.navigation.pop() },
                {
                    text: "Cancel",
                    // onPress: () => this.props.navigation.push('Dashboard'),
                    style: "cancel"
                }
            ])
        }
        else {
            Alert.alert("Are you sure?", "Event will be over and others will also drop.", [
                {
                    text: "Leave", onPress: () => {
                        // complete event then move to completed event
                        // 
                        EventService.completeEvent(this.state.eventID).then(res => res.json()).then(json => {
                            if (json.message) {
                                this.session.signal({
                                    type: 'EVENTCOMPLETE',
                                    data: 'EVENTCOMPLETE',
                                },
                                    err => {
                                        if (err) {
                                            console.log("Signal failed...", err)
                                        }
                                    })
                                // this.props.navigation.replace('EventCompleted')
                            }
                            else {
                                Alert.alert("Failed to complete the event")
                            }
                        })
                    }
                },
                {
                    text: "Cancel",
                    // onPress: () => this.props.navigation.push('Dashboard'),
                    style: "cancel"
                }
            ])

        }

    }

    componentDidMount() {
        this.startSession()
        this.getPublisherSize()
    }

    componentWillUnmount() {
        this.session?.disconnect()
    }
    render() {

        return (
            <View style={{ flex: 1,justifyContent:"center"}}>
                {/* {this.state.eventNotStarted && <EventNotStartedModal eventDetail={this.props.route.params.eventDetail} />} */}
                {/* {!this.state.eventNotStarted && <> */}
                <View nativeID="publisher" style={{ width: this.state.pub.width, height: this.state.pub.height,rowBasis:this.state.pub.rowBasis }}></View>
                <View nativeID="subscribers"></View>

                <View style={{ position: "absolute", top: 30, left: 10, flexDirection: "row" }}>
                    <Icon color="white" size={18} type="material-icon" name="group" /><Text style={{ color: "#FFF", marginLeft: 5 }}>
                        {parseInt(this.state.totalViewers)}</Text>
                </View>
                <View style={{ position: "absolute", top: 0, right: 10, width: 100, height: 100 }}>
                    <Image source={require('../assets/images/WebWed-Logo.png')} style={{ width: 100, height: 100 }} resizeMode="contain" />
                </View>

                <View style={{ position: "absolute", bottom: 70, left: 0, right: 0, backgroundColor: "rgba(90,171,211,0.3)", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", borderRadius: 50, marginHorizontal: 10 }}>
                    <TouchableOpacity onPress={this.toggleVideo}>
                        <Icon reverseColor={this.state.publishVideo ? "#023C6F" : '#FFF'} color={this.state.publishVideo ? "#FFF" : '#023C6F'} size={18} reverse type="octicon" name="eye-closed" />
                    </TouchableOpacity>

                    <TouchableOpacity onPress={this.leaveEvent}>
                        <Image source={require("../assets/images/heart_active.png")} style={{ width: 50, height: 50 }} />
                        {/* <Icon reverseColor="red" color="#FFF" size={18} reverse type="ionicon" name="md-heart" onPress={this.completeEvent} /> */}
                    </TouchableOpacity>
                    <TouchableOpacity onPress={this.toggleAudio}>
                        <Icon reverseColor={this.state.publishAudio ? "#023C6F" : '#FFF'} color={this.state.publishAudio ? "#FFF" : '#023C6F'} size={18} reverse type="ionicon" name="md-mic-off" />
                    </TouchableOpacity>
                </View>

            </View>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        cameraPosition: state.cameraPosition,

    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(LiveStream)
