import React, { Component } from 'react';
import { Image, ImageBackground, Linking, StyleSheet, View } from 'react-native';
import { Icon, ListItem } from 'react-native-elements';
import { ScrollView, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import LoadingModal from '../components/LoadingModal';
import SocialShare from '../components/SocialShare';
import { imagePath, joinURL } from '../services/masters';
import { getEventDetail } from '../stores/actions';
import { formatDate } from '../utils/convrters';
import Text from '../components/WebwedText'
import { color5 } from '../configs/themeOptions';

class EventDetail extends Component {

    componentDidMount() {
        this.props.getEventDetail(this.props.route.params.eventID);
    }

    needHelp = () => {
        const mailData = {
            to: 'info@webwedmobile.com',
            subject: 'WebWed Mobile - App Support',
            body: 'I am having technical issues with the WebWed Mobile app.  Please get back to me at when you can. My contact information is listed below:',
        }

        Linking.openURL("mailTo:" + mailData.to + "?subject=" + mailData.subject + "&body=" + mailData.body)
    }

    goToOrders = () => {
        this.props.navigation.push('ProfileTabs', { screen: 'Orders', name: 'My Orders' })
    }

    openRegistry = () => {
        this.props.navigation.push('Registry');
    }

    openPremaritalEducation = () => {
        this.props.navigation.push('MarriageEducation');
    }

    openMarriageApplication = () => {

        this.props.navigation.push('MarriageApplication');
    }

    openMariageLicene = () => {
        this.props.navigation.push('MarriageLicense');
    }

    hasRegistry = () => {
        // return true;
        return this.props.eventDetail.addons.some((v, i) => (v.addon_type == 'registry'));
    }

    hasEducation = () => {
        // return true;
        return this.props.eventDetail.addons.some((v, i) => (v.addon_type == 'marriage_education'));
    }

    hasApplication = () => {
        // return true;
        return this.props.eventDetail.addons.some((v, i) => (v.addon_type == 'marriage_application'));
    }

    hasLicense = () => {
        return this.props.eventDetail.addons.some((v, i) => (v.addon_type == 'marriage_license'));
    }

    openStream = () => {

        const user_email = this.props.user.email;
        const participant_emails = this.props.eventDetail.participants.map((v, i) => (v.email))
        if (user_email == this.props.eventDetail.host.email || participant_emails.includes(user_email)) {
            const isParticipant = participant_emails.includes(user_email)

            this.props.navigation.push('LiveStream', { name: this.props.eventDetail.title, 'eventDetail': this.props.eventDetail, 'eventID': this.props.eventDetail.id, isParticipant: isParticipant });
        }
        else {
            this.props.navigation.push("Viewer", { viewerName: this.props.user.email, eventID: this.props.eventDetail.code });
        }
    }

    hostOrOfficiant = () => {
        console.log("called...")
        const invite = this.props.eventDetail.invitations.filter((v, i) => (v.email == this.props.user.email && v.role == 'officiant'))
        return this.props.user.uid == this.props.eventDetail.host.uid || invite.length > 0
    }

    render() {

        return this.props.loading ? <LoadingModal /> : (<ScrollView backgroundColor="#d3dbdf" containerStyle={{ padding: 0 }} >

            <View style={{ marginBottom: 10 }}>
                <View style={{ height: 250, width: "100%" }}>
                    <ImageBackground source={this.props.eventDetail.cover ? { uri: imagePath + "/" + this.props.eventDetail.cover } : require('../assets/images/placeholder.png')} style={styles.bgImage}>
                        <View style={{ flex: 1, alignItems: "center", justifyContent: "flex-end", padding: 10 }}>
                            <Text style={styles.title}>{this.props.eventDetail.title}</Text>
                            <Text style={styles.eventDate}>{formatDate(this.props.eventDetail.event_time, false, " | ", false, 12)}</Text>
                            <Text style={styles.eventDate}>Event ID #{this.props.eventDetail.code}</Text>
                        </View>
                    </ImageBackground>
                </View>
                <View style={{ padding: 10, flexDirection: "row", alignContent: "space-between", justifyContent: "space-evenly", backgroundColor: "#FFF" }}>
                    {this.hostOrOfficiant() && <TouchableOpacity style={{ flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 20 }} onPress={this.goToOrders}>
                        <Icon reverse color="#cfd8dc" name="md-star" type="ionicon" />
                        <Text>OPTIONS</Text>
                    </TouchableOpacity>}
                    <TouchableOpacity style={{ fontSize: 24, flexDirection: "column" }} onPress={this.openStream}>
                        <Image source={require("../assets/images/heart_active.png")} style={{ width: 100, height: 100 }} />
                    </TouchableOpacity>
                    {this.hostOrOfficiant() && <SocialShare style={{ marginTop: 20 }} message={"Please come and join us and be the part of our special moments: " + this.props.eventDetail.title} url={joinURL + this.props.eventDetail.code} />}
                </View>
            </View>
            <View style={{ padding: 10, backgroundColor: "#FFF" }}>
                <View style={{ flexDirection: "row", marginBottom: 10 }}><Icon name="md-stopwatch" type="ionicon" /><Text style={{ fontSize: 18, marginHorizontal: 10 }}>30 Minutes</Text></View>
                <View style={{ marginVertical: 10, paddingHorizontal: 10 }}>
                    <Text style={{ color: "#023C6F", fontSize: 16 }}>
                        You can stream for a consecutive window of time based on the package pre-selected for your event. You will not be able to start and stop the stream at random; and the video stream may only be accessed again if you experience technical issues. Any remaining balance of time will be removed and the event will be closed
                    </Text>
                </View>
            </View>
            { this.props.eventDetail && this.props.eventDetail.addons.length > 0 && <Text style={{ fontSize: 16, marginHorizontal: 10, marginVertical: 20 }}>PREMIUM ADDONS</Text>}

            <View style={{ marginBottom: 20 }}>
                {this.hasRegistry() && <ListItem bottomDivider topDivider onPress={this.openRegistry}>
                    <Icon type="ionicon" name="md-star" color="gray" />
                    <ListItem.Content>
                        <ListItem.Title style={{ fontFamily: '"Roboto","Helvetica Neue", sans-serif' }}>
                            Go to Registry
                        </ListItem.Title>
                    </ListItem.Content>
                    <Icon type="ionicon" name="md-arrow-dropright" />
                </ListItem>
                }
                {this.hasEducation() && <ListItem bottomDivider onPress={this.openPremaritalEducation}>
                    <Icon type="ionicon" name="md-star" color="gray" />
                    <ListItem.Content>
                        <ListItem.Title style={{ fontFamily: '"Roboto","Helvetica Neue", sans-serif' }}>
                            Premarital Education
                                </ListItem.Title>
                    </ListItem.Content>

                    <Icon type="ionicon" name="md-arrow-dropright" />
                </ListItem>}
                {this.hasApplication() && <ListItem bottomDivider onPress={this.openMarriageApplication}>
                    <Icon type="ionicon" name="md-star" color="gray" />
                    <ListItem.Content>
                        <ListItem.Title style={{ fontFamily: '"Roboto","Helvetica Neue", sans-serif' }}>
                            Marriage Application
                                </ListItem.Title>
                    </ListItem.Content>

                    <Icon type="ionicon" name="md-arrow-dropright" />
                </ListItem>}

                {/* {this.hasLicense() && <ListItem bottomDivider onPress={this.openMariageLicene}>
                    <Icon type="ionicon" name="md-star" color="gray" />
                    <ListItem.Content>
                        <ListItem.Title style={{ fontFamily: '"Roboto","Helvetica Neue", sans-serif' }}>
                            Marriage License
                                </ListItem.Title>
                    </ListItem.Content>

                    <Icon type="ionicon" name="md-arrow-dropright" />
                </ListItem>} */}

                {this.props.eventDetail.addons.filter((v, i) => (v.addon_type != 'registry' && v.addon_type != 'marriage_education' && v.addon_type != 'marriage_application' && v.addon_type != 'marriage_license')).map((v, i) => (<ListItem key={i} topDivider bottomDivider>
                    <Icon type="ionicon" name="md-star" color="gray" />
                    <ListItem.Content>
                        <ListItem.Title style={{ fontFamily: '"Roboto","Helvetica Neue", sans-serif' }}>
                            {v.title}
                        </ListItem.Title>
                    </ListItem.Content>
                </ListItem>))}
            </View>

            <TouchableOpacity style={{ flex: 1, justifyContent: "center", alignItems: "center", marginVertical: 10 }} onPress={this.needHelp}>
                <Text style={{ fontSize: 18, color: '#043f7b', fontFamily: '"Roboto","Helvetica Neue", sans-serif' }}>
                    NEED HELP?
                </Text>
            </TouchableOpacity>
        </ScrollView >)
    }
}


const mapStateToProps = state => {
    return {
        eventDetail: state.eventDetail,
        user: state.user,
        loading: state.loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getEventDetail: eventID => (dispatch(getEventDetail(eventID)))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventDetail);

const styles = StyleSheet.create({
    eventTitle: {
        color: color5,
        margin: 0,
        fontFamily: '"Roboto","Helvetica Neue", sans-serif',
        paddingVertical: 8,
        fontSize: 24,
        paddingHorizontal: 20
    },
    eventDate: {
        color: "#FFF",
        margin: 5,
        paddingHorizontal: 20
    },
    participant: {
        borderWidth: 1,
        borderColor: 'rgba(0,0,0,0.2)',
        alignItems: 'center',
        justifyContent: 'center',
        width: 50,
        height: 50,
        padding: 5,
        backgroundColor: '#fff',
        borderRadius: 50,
        marginRight: 10
    },
    title: {
        fontSize: 24,
        fontFamily: '"Roboto","Helvetica Neue", sans-serif',
        color: 'white'
    },
    eventList: { backgroundColor: '#d3dbdf' },
    bgImage: {
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        resizeMode: "center"
    }
}
)
