import React, { Component } from 'react'
import { View } from 'react-native'
import { Input, Button } from 'react-native-elements'
import { connect } from 'react-redux'
import { EventService } from '../services/events'
import Text from '../components/WebWedText'

class RegistryLink extends Component {

    state = {
        url: '',
        disabled: true
    }

    handleTextChange = v => {
        const expression =
            /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        const exp = new RegExp(expression);
        this.setState({ url: v, err: !v.match(exp) ? 'invalid url' : '', disabled: !v.match(exp) ? true : false })
    }

    submitRegistry = () => {
        EventService.addRegistry(this.props.eventDetail.id, this.state.url).then(res => res.json()).then(json => {
            console.log(json)
            json.message && alert(json.message);
            json.error && alert(json.error);
            // this.props.onSubmitRegistry();
        }).catch(err => console.log(err))
    }


    render() {
        return (
            <View style={{flex:1}}>
                <View style={{ margin: 10 }}>
                    <Text>Enter a direct link for guests to access your online registry. You can copy and paste this from your web browser or enter manually.</Text>
                </View>

                <View style={{ padding: 10,flex:1 }}>
                    <Input placeholder="Enter regsitry link e.g. http://xyz.com" keyboardType="url" onChangeText={this.handleTextChange} errorMessage={this.state.err} renderErrorMessage={true} />
                </View>

                <View style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
                    <Button title="Submit" disabled={this.state.disabled} buttonStyle={{ padding: 10 }} onPress={this.submitRegistry} />
                </View>
            </View>
        )
    }
}

const mapStateToProps = state => {
    return {
        eventDetail: state.eventDetail,
        loading: state.loading
    }
}

export default connect(mapStateToProps, null)(RegistryLink);
