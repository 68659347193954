import * as firebase from 'firebase';
import React, { Component } from 'react';
import { Alert, View } from 'react-native';
import { Button, Input, ListItem } from 'react-native-elements';
import { ScrollView } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { connect } from 'react-redux';
import LoadingModal from '../components/LoadingModal';
import { Storage } from '../services/storage';
import { UserService } from '../services/user';
import { getInitials, ucWords } from '../utils/convrters';
import Text from '../components/WebwedText'

class ProfileTab extends Component {

    state = {
        user: { profile: { birthday: '' } },
        phone: '',
        address: '',
        mailing_address: '',
        birthday: '',
        password: '',
        confirm: '',
        errors: {},
        loading: false
    }

    componentDidMount() {

        this.setState({ loading: true })
        UserService.getProfile().then(res => res.json()).then(json => {
            if (json.hasOwnProperty('user')) {
                const user = {
                    phone: json.user.profile.phone,
                    address: json.user.profile.address,
                    mailing_address: json.user.profile.mailing_address,
                    birthday: json.user.profile.birthday
                }
                this.setState(
                    {
                        loading: false,
                        ...user
                    }
                )
            }
            else {
                this.setState(
                    {
                        loading: false
                    }
                )
            }

        }).catch(err => console.log(err))

    }

    getBirthday() {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        if (this.state.birthday) {
            const d = new Date(Date.parse(this.state.birthday));
            return monthNames[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear()
        }
        else {
            return false;
        }
    }

    handleAddressChange = (v) => {
        this.setState({
            address: v,
            errors: { ...this.state.errors, address: !v ? 'address can\'t be empty!' : (v.length < 10 ? 'invalid address' : '') }
        })
    }

    handleMailingAddressChange = (v) => {
        this.setState({
            mailing_address: v,
            errors: { ...this.state.errors, mailing_address: !v ? 'mailing address can\'t be empty!' : (v.length < 10 ? ' mailing address' : '') }
        })
    }

    handlePhone = (v) => {
        this.setState({
            phone: v,
            errors: { ...this.state.errors, phone: !v ? 'phone umber can\'t be empty!' : (v.length < 10 ? ' phone number length can\'t be less than 10' : '') }
        })
    }

    handlePasswordChange = (v) => {
        this.setState({
            password: v
        })
    }

    handleConfirmPasswordChange = (v) => {
        this.setState({
            confirm: v
        })
    }

    updateProfile = () => {
        const profile = {
            phone: this.state.phone,
            address: this.state.address,
            mailing_address: this.state.mailing_address,
        }

        if (this.state.confirm) {

            // this.props.startLoading();
            this.setState({ loading: true })
            const user = firebase.auth().currentUser;
            let cred = firebase.auth.EmailAuthProvider.credential(user.email, this.state.password);

            firebase.auth().currentUser.reauthenticateWithCredential(cred).then(
                () => {
                    firebase.auth().currentUser.updatePassword(this.state.confirm).then(() => {

                        this.updateProfileServer(profile);
                    }).catch(err => {
                        this.setState({ loading: false })

                        Alert.alert("Password update failed!", err.message)
                    })
                }
            ).catch(err => {
                this.setState({ loading: false })

                Alert.alert("Password update failed!", err.message)
            })
        }
        else {
            this.setState({ loading: true })

            this.updateProfileServer(profile);
        }

    }

    updateProfileServer(profile) {
        setTimeout(() => {

            UserService.updateUserProfile(profile).then(res => res.json()).then(json => {
                this.setState({ loading: false })

                if (typeof json.message == 'object') {

                    Alert.alert("Failed!!!", JSON.stringify(json.message));
                }
                else {
                    if (json.user) {
                        Storage.setProfile(json.user)
                    }
                    Alert.alert("Failed!!!", json.message || json.error);
                }
            }).catch(err => {
                this.setState({ loading: false })

                Alert.alert("Error!!", "Something went wrong,please try again later");
            })

        }, 5000);
    }

    render() {

        return this.state.loading ? <LoadingModal /> : (
            <KeyboardAwareScrollView style={{ flex: 1 }}>
                <ScrollView>
                    <View style={{ flex: 1, backgroundColor: '#023C6F', paddingVertical: 20, justifyContent: "center", alignItems: 'center' }}>
                        {/* <Image source={require('../assets/images/logo-full.png')} style={{ alignSelf: 'center' }} /> */}
                        <View style={{ width: 100, height: 100, borderRadius: 50, backgroundColor: '#FFF', justifyContent: "center", alignItems: "center" }}>
                            <Text style={{ fontSize: 24, fontFamily: '"Roboto","Helvetica Neue", sans-serif', color: "#023C6F" }}>{getInitials(this.props.user.displayName)}</Text>
                        </View>
                        <Text style={{ fontSize: 18, color: 'white', marginVertical: 5 }}> {ucWords(this.props.user.displayName)} </Text>

                    </View>
                    <Text style={{ margin: 15, color: '#023C6F' }}>GENERAL</Text>
                    <View style={{ flex: 1 }}>
                        <ListItem bottomDivider>
                            <Text>Email</Text>
                            <Text> {this.props.user.email} </Text>
                        </ListItem>
                        <ListItem bottomDivider>
                            <Text>Birthday</Text>
                            <Text> {this.getBirthday()} </Text>
                        </ListItem>

                    </View>
                    <Text style={{ margin: 15, color: '#023C6F' }}>EDIT INFO</Text>

                    <View style={{ flex: 1, paddingTop: 10, marginBottom: 50, backgroundColor: "#FFF" }}>
                        <Input
                            label="Phone"
                            labelStyle={{ fontWeight: "normal" }}
                            renderErrorMessage={true}
                            errorMessage={this.state.errors.phone}
                            value={this.state.phone}
                            onChangeText={this.handlePhone}
                        />

                        <Input
                            labelStyle={{ fontWeight: "normal" }}
                            label="Address"
                            value={this.state.address}
                            renderErrorMessage={true}
                            errorMessage={this.state.errors.address}
                            onChangeText={this.handleAddressChange}
                        />

                        <Input
                            label="Mailing address"
                            labelStyle={{ fontWeight: "normal" }}
                            value={this.state.mailing_address}
                            renderErrorMessage={true}
                            errorMessage={this.state.errors.mailing_address}
                            onChangeText={this.handleMailingAddressChange}
                        />

                        <Input
                            labelStyle={{ fontWeight: "normal" }}
                            label="Password"
                            value={this.state.password}
                            onChangeText={this.handlePasswordChange}
                            placeholder="New password"
                        />

                        <Input
                            labelStyle={{ fontWeight: "normal" }}
                            label="Current Password"
                            value={this.state.confirm}
                            onChangeText={this.handleConfirmPasswordChange}
                            placeholder="Your current password"
                        />

                    </View>

                </ScrollView>
                <View style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
                    <Button titleStyle={{ fontFamily: '"Roboto","Helvetica Neue", sans-serif' }} title="UPDATE PROFILE" containerStyle={{ borderRadius: 0 }} buttonStyle={{ borderRadius: 0, marginTop: 20, height: 50 }} onPress={this.updateProfile} />
                </View>
                {this.props.loading && <LoadingModal />}
            </KeyboardAwareScrollView>

        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
    }
}


export default connect(mapStateToProps, null)(ProfileTab);
