import * as firebase from 'firebase';
import { EventService } from '../services/events';
import { MastersService } from "../services/masters";
import { Storage } from '../services/storage';
import { UserService } from '../services/user';
import * as actionTypes from './actionTypes';

// import * as firebase from 'firebase';

export const startLoading = () => {
    return {
        type: actionTypes.START_LOADING,
        loading: true
    }
}

const httpFailed = err => {
    console.log("Json err......", err)
    return {
        type: actionTypes.HTTP_FAILED,
        err
    }
}


export const loginSuccess = user => {

    // UserService.updateProfile();

    return {
        type: actionTypes.LOGIN_SUCCESS,
        user
    }
}

export const loginFailed = (err) => ({
    type: actionTypes.LOGIN_FAILED,
    err
})


export const signOutSucess = () => ({
    type: actionTypes.LOGOUT_SUCCESS
})

export const signOut = () => dispatch => {
    UserService.signOut().then(() => dispatch(signOutSucess()))
}


const packageAddonsSuccess = json => {
    return {
        type: actionTypes.PACKAGE_ADDONS_SUCCESS,
        packages: json.packages,
        addons: json.addons,
    }
}

const packageAddonsFailed = err => {
    return {
        type: actionTypes.PACKAGE_ADDONS_FAILED,
        err
    }
}

export const getPackageAddons = () => (dispatch) => {
    MastersService.fetchPackageAddons()
        .then(res => res.json())
        .then(
            json => {
                if (!json.Message) {
                    dispatch(packageAddonsSuccess(json))
                }
                else {
                    dispatch(httpFailed(json.Message))
                }
            }
        ).catch(
            err => {
                console.log("Errors...............", err)
                dispatch(packageAddonsFailed(err))
            }
        )
}


const createEventSuccess = json => ({ type: actionTypes.EVENT_CREATE_SUCCESS, message: json.message })
const createEventFailed = err => { console.log("Errr....................", err); return { type: actionTypes.EVENT_CREATE_FAILED, err: err } }
export const createEvent = (event_data) => dispatch => {
    dispatch(startLoading());
    EventService.create(event_data)
        .then(res => res.json())
        .then(json => {
            dispatch(createEventSuccess(json))
        })
        .catch(
            err => dispatch(createEventFailed(err)))
}

const uploadCoverSuccess = json => ({ type: actionTypes.EVENT_CREATE_SUCCESS, message: json.message })
const uploadCoverFailed = err => { console.log(err); return { type: actionTypes.EVENT_CREATE_FAILED, err: err } }
export const uploadCover = (url, cover, fields) => dispatch => {
    dispatch(startLoading());
    EventService.uploadCover(url, cover, fields)
        .then(res => {
            return res.text()
            // return res.json()
        })
        .then(json => {
            dispatch(uploadCoverSuccess(json))
        })
        .catch(
            err => dispatch(uploadCoverFailed(err)))
}

const getOrdersSuccess = json => {
    return {
        type: actionTypes.GET_ORDER_SUCCESS,
        orders: json.orders
    }
}

export const getOrders = () => dispatch => {
    dispatch(startLoading());
    UserService.getOrders().then(r => r.json()).then(json => {
        // console.log(json);
        dispatch(getOrdersSuccess(json))
    }).catch(err => dispatch(httpFailed(err)))
}

const getNotificationsSuccess = json => {
    // console.log(json.notifications)
    return {
        type: actionTypes.NOTIFICATIONS_SUCCESS,
        notifications: json.notifications
    }
}

export const getNotifications = () => dispatch => {
    dispatch(startLoading());
    UserService.getNotifications().then(res => res.json()).then(json => {
        dispatch(getNotificationsSuccess(json));
    }).catch(err => dispatch(httpFailed(err)))
}

export const getEventSuccess = events => {
    // console.log(events);
    return {
        type: actionTypes.GET_EVENT_SUCCESS,
        events: events
    }
}

export const getEvents = () => dispatch => {
    dispatch(startLoading());
    EventService.getEvents()
        .then(res => res.json())
        .then(json => {
            // console.log(json)
            dispatch(getEventSuccess(json.events))
        })
        .catch(err => dispatch(httpFailed(err)))
}

const archiveSuccess = events => {
    // console.log(events);
    return {
        type: actionTypes.ARCHIVES_SUCCESS,
        archives: events
    }
}

export const getArchives = () => dispatch => {
    dispatch(startLoading());
    EventService.archives()
        .then(res => res.json())
        .then(json => {
            if (json.hasOwnProperty('events')) {
                dispatch(archiveSuccess(json.events))
            }
            else {
                dispatch(httpFailed(json.error))
            }
        })
        .catch(err => dispatch(httpFailed(err)))
}

const getEventDetailSuccess = json => {
    return {
        type: actionTypes.EVENT_DETAIL_SUCCESS,
        eventDetail: json?.event_details
    }
}

export const getEventDetail = eventID => dispatch => {
    dispatch(startLoading());
    EventService.getDetails(eventID)
        .then(res => res.json())
        .then(json => { 
            dispatch(getEventDetailSuccess(json))
        })
        .catch(err => dispatch(httpFailed(err)))
}

export const updateCurrentEvent = eventDetail => dispatch => {
    dispatch(getEventDetailSuccess(eventDetail))
}

export const resetEventDetail = () => dispatch => dispatch(getEventDetailSuccess({ event_details: {} }))

const eventRoleSuccess = json => ({
    type: actionTypes.ROLES_SUCCESS,
    roles: json.roles
})
export const getRoles = () => dispatch => {
    MastersService.getRoles().then(res => res.json()).then(json => {
        dispatch(eventRoleSuccess(json))
    }).catch(err => dispatch(httpFailed(err)))
}

const courtsSuccess = json => ({
    type: actionTypes.COURT_SUCCESS,
    courts: json.courts
})
export const getCourts = () => dispatch => {
    MastersService.getCourts().then(res => res.json()).then(json => {
        // console.log(json);
        dispatch(courtsSuccess(json))
    }).catch(err => dispatch(httpFailed(err)))
}

export const refreshEvent = eventDetail => dispatch => {
    // console.log("Event Details.....",eventDetail)
    dispatch(
        {
            type: actionTypes.REFRESH_EVENT,
            eventDetail
        }
    )
}


export const autoLogin = () => dispatch => {
    dispatch(startLoading())
    firebase.auth().onAuthStateChanged(user => {
        if (user) {
            // User is signed in.
            // console.log("Auth user", user)
            firebase.auth().currentUser.getIdToken().then(token => { Storage.setToken(token) })
            dispatch(loginSuccess(user))
        }
        dispatch(loginFailed(""))
    });
}

export const login = (email, password) => dispatch => {
    dispatch(startLoading())
    firebase.auth().signInWithEmailAndPassword(email, password).catch(err => {
        console.log(err.message);
        dispatch(loginFailed(err.message));
    });

    const user = firebase.auth().currentUser;
    if (user) {
        dispatch(loginSuccess(user));
    }
}

export const blankErr = err => dispatch => {
    dispatch({
        type: actionTypes.BLANK_ERR,
        err
    })
}

export const signup = user => dispatch => {
    dispatch(startLoading())
    firebase.auth().createUserWithEmailAndPassword(user.email, user.password).then(
        cred => {
            const userProfile = {
                full_name: user.full_name,
                email: user.email,
                address: user.address,
                mailing_address: user.mailing_address,
                phone: user.phone,
                birthday: user.birthday,
                uid: cred.user.uid,
                phone: user.phone
            }

            firebase.auth().currentUser.updateProfile({
                displayName: user.full_name
            }).then(function () {
                // console.log("profile updated successfully")
            }).catch(function (error) {
            });

            UserService.createProfile(userProfile).then(res => res.json()).then(json => {
                // console.log("Json......",json)
                if (json.message) {
                    dispatch(loginSuccess(cred.user))
                }
                else {
                    dispatch(httpFailed(json.error))
                }
            }).catch(err => dispatch(httpFailed(err)))
        }
    ).catch(
        err => {
            console.log(err);
            dispatch(httpFailed(err.message))
        }
    )
}

const profileSuccess = (profile,is_officiant)  => ({
    type: actionTypes.USER_PROFILE,
    profile: profile,
    is_officiant
})
export const getProfile = () => dispatch => {
    dispatch(startLoading())
    UserService.getProfile().then(res => res.json()).then(json => {
        // console.log(json)
        if (json.message) {
            dispatch(profileSuccess(json.user.profile,json.user.is_officiant))
        }
        else {
            dispatch(httpFailed(json.Message || json.error))
        }
    }).catch(err => {
        dispatch(httpFailed(err))
    })
}

export const pushEvent = events => dispatch => {
    dispatch(getEventSuccess(events))
}

export const flipCamera = cameraPosition => dispatch => {
    console.log(cameraPosition)
    dispatch({
        type: actionTypes.FLIP_CAMERA,
        cameraPosition
    })
}

const officiantsSuccess = officiants => ({ type: actionTypes.OFFICIANT_LIST, officiants })

export const findOfficiants = () => dispatch => {
    dispatch(startLoading())
    MastersService.officiants().then(res => res.json()).then(json => {
        dispatch(officiantsSuccess(json.officiants))
    }).catch(err => {
        dispatch(httpFailed(err))
    })
}
