import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
import { ListItem } from 'react-native-elements';
import { ScrollView } from 'react-native-gesture-handler';
import { color2 } from '../configs/themeOptions';
import { formatDate } from '../utils/convrters';

export default class OrderDetails extends Component {
    getTotalPaid = order => {
        const { addons } = order.event
        let total = parseFloat(order.event.package.price)
        if (addons.length > 0) {
            
            total += addons.reduce((x, y) => (parseFloat(x.price) + parseFloat(y.price)))
        }
        return total
    }
    render() {

        const { order } = this.props.route.params;
        
        return (
            <View style={styles.container}>
                <ListItem title="Order Date" containerStyle={{backgroundColor:color2}} titleStyle={{color:"#FFF"}} rightSubtitleStyle={{color:"#FFF"}} rightSubtitle={formatDate(order.created_at, true)} bottomDivider/>
                <ScrollView>

                    <ListItem title={order.event.package.title} rightSubtitle={"$" + order.event.package.price} bottomDivider />

                    {
                        order.event.addons?.map((v, i) => (<ListItem key={i} title={v.title} rightSubtitle={"$" + v.price} bottomDivider />))
                    }
                {/* <ListItem title={() => (order.txn_desc.split(",").map( (v,i) => (<Text key={i}>{v}</Text>) ))} bottomDivider /> */}

                </ScrollView>
                
                <View style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
                    <ListItem containerStyle={styles.totalRow} title="Total Paid" rightSubtitle={"$ " + this.getTotalPaid(order)} />
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    heading: {
        color: "#075193",
        margin: 10,
        fontSize: 18
    },
    totalRow: {
        backgroundColor: "#5aabd3"
    }
})