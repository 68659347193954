import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
import { ListItem,Icon,Button } from 'react-native-elements';
import { findOfficiants } from '../stores/actions';
import { connect } from 'react-redux';
import LoadingModal from '../components/LoadingModal';

class FindOfficiant extends Component {

    componentDidMount = () => {
        this.props.getOfficiants()
    }

    saveInvite = () => {

    }
    render() {
        return (
            <View style={{flex:1}}>
                <View style={styles.centeredView}>
                        <View>
                            
                            {this.props.loading ? (<LoadingModal title="Please wait..." />) : this.props.officiants.map((v, i) => {
                                return (<ListItem key={i} title={v.user.full_name} rightAvatar={<Icon type="ionicon" name="md-arrow-dropright" />} onPress={() => {
                                    this.props.navigation.navigate('OfficiantDetail',{officiant:v,title:'Invite Officiant'})
                                }} bottomDivider />)
                            })}

                        </View>
                    </View>
                    <View style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
                        <Button containerStyle={{ backgroundColor: "#00bcd4" }} title="SAVE AND CONTINUE" onPress={this.saveInvite} />
                    </View>
            </View>
        )
    }
}

const mapStateToProps = state => {
    return {
        eventDetail:state.eventDetail,
        loading: state.loading,
        officiants:state.officiants
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getOfficiants: () => dispatch(findOfficiants())
    }
}

const styles = StyleSheet.create({
    
    container: {
        flex: 1,
        padding: 10,
        backgroundColor: '#FFF'
    },
    title: {
        fontSize: 16,
        marginVertical: 10,
        alignContent: "center",
        alignSelf: "center",
        fontWeight: "400"
    },
    subTitle: {
        marginVertical: 10
    }
})

export default connect(mapStateToProps,mapDispatchToProps)(FindOfficiant)