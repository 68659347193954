import React, { Component } from 'react'
import { Share,TouchableOpacity } from 'react-native'
// import Share from "react-native-share";
import { Icon } from 'react-native-elements';
import Alert from './Alert';
// import { TouchableOpacity } from 'react-native-gesture-handler';
import Text from '../components/WebWedText'

export default class SocialShare extends Component {

    doShare = async () => {

        try {
            const result = await Share.share({
                message: this.props.message,
                url: this.props.url,
            });
            if (result.action === Share.sharedAction) {
                if (result.activityType) {
                    // shared with activity type of result.activityType
                } else {
                    // shared
                }
            } else if (result.action === Share.dismissedAction) {
                // dismissed
            }
        } catch (error) {
            Alert.alert(error.message);
        }
    }

    render() {
        return (
            <TouchableOpacity style={{ flexDirection: "column", ...this.props.style }} onPress={this.doShare}>
                <Icon reverse color="#53d7ee" name="md-share" type="ionicon" />
                <Text style={{ alignSelf: "center" }}>SHARE</Text>
            </TouchableOpacity>
        )
    }
}
