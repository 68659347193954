import React, { Component } from 'react'
import { View, Image } from 'react-native'

export default class NoEvent extends Component {
    render() {
        return (
            <View style={{ flex:1,justifyContent: "center", alignItems: "center",alignContent:"center"}}>
                <Image resizeMode="contain" source={require("../assets/images/noevent.png")} style={{width:250,height:250}} />
            </View>
        )
    }
}
