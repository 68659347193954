import React, { Component } from 'react'
import { View, Modal, Image } from 'react-native'
import { Button, Icon } from 'react-native-elements'
import Text from '../components/WebWedText'

export default class MarriageApplicationCongrats extends Component {
    render() {
        return (
            <View style={{ flex: 1, padding: 20, backgroundColor: "#023C6F", alignItems: "center" }}>
                    <View style={{ flex: 1, justifyContent: "center", alignItems: "center", }}>
                        <Image source={require("../assets/images/logo-full.png")} resizeMode="cover" />
                    </View>

                    <View style={{ flex: 3, justifyContent: "flex-start", alignItems: "center" }}>
                        <View style={{ marginVertical: 20 }}>
                            <Icon type="ionicon" name="md-checkmark-circle" color="white" size={48} />
                        </View>
                        <View style={{ paddingHorizontal: 40, paddingVertical: 20, alignItems: "center" }}>
                            <Text style={{ fontSize: 18, color: '#FFF' }}>Application Sent</Text>
                        </View>
                        <View style={{ width: "50%", alignItems: "center", }}>
                            <Text style={{ color: '#FFF' }}>Check your email to complete the required verification.</Text>
                        </View>

                    </View>
                    <View style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
                        <Button title="Close" buttonStyle={{ padding: 15 }} onPress={() => { this.props.navigation.pop(2) }} />
                    </View>

                </View>
        )
    }
}
