import React, { Component } from 'react'
import { View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { color1 } from '../configs/themeOptions'
import Text from '../components/WebWedText'


export default class AddParticipant extends Component {
    render() {
        return (
            <View style={{ flexBasis: "50%", paddingVertical: 10, justifyContent: "center", alignItems: 'center' }}>
                <TouchableOpacity onPress={this.props.onPress}>
                    <View style={{ width: 70, height: 70, borderRadius: 50, backgroundColor: color1, justifyContent: "center", alignItems: "center" }}>
                        <Text style={{ fontSize: 16, fontWeight: "bold", color: "#FFF" }}>+</Text>
                    </View>
                    <Text style={{ marginVertical: 5 }}> Add User </Text>
                </TouchableOpacity>
            </View>
        )
    }
}
