import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { Button, Icon, ListItem } from 'react-native-elements'
import RNPickerSelect from 'react-native-picker-select'
import { connect } from 'react-redux'
import AddDocument from '../components/AddDocument'
import AddPlaceholder from '../components/AddPlaceholder'
import Alert from '../components/Alert'
import { EventService } from '../services/events'
import { getCourts } from '../stores/actions'
import { getInitials, ucWords } from '../utils/convrters'
import Text from '../components/WebWedText'
import { Linking } from 'react-native'
class MarriageApplication extends Component {

    state = {
        court: false,
        spouse_docs: [],
        my_docs: [],
        spouse: false,
        officiant: false,
        isSubmitted: false,
    }

    componentDidMount() {
        this.props.getCourts();
        this.setState({ isSubmitted: this.props.eventDetail.application.status })
    }

    handleTextChange = v => {
        this.setState({ url: v, err: !v ? "link required" : '' })
    }

    submitApplication = () => {

        if (!this.state.court) {
            Alert.alert("Select Court", "Please select the court first!")
        }
        else {

            const court_id = this.state.court.id;

            EventService.saveApplication(this.props.eventDetail.id, court_id).then(res => res.json()).then(async json => {
                if (json.message) {
                    const supported = await Linking.canOpenURL(this.state.court.application_url)

                    if (supported) {
                        Linking.openURL(this.state.court.application_url)
                    }
                    else {
                        Alert.alert("Failed!!", "Please ask the admin to set correct URL");
                    }

                }
                else {
                    Alert.alert("Failed!!", json.error);
                }
            }).catch(err => Alert.alert("Failed!!", "Oops! please try again later " + err));
        }
    }

    getSpouse = () => {
        const spouses = this.props.eventDetail.participants.filter((v, i) => (v.role.toLowerCase() == 'bride' || v.role.toLowerCase() == 'groom'))
        return spouses.length > 0 ? spouses[0] : false;
    }

    selectCourt = (itemValue) => {
        const court = this.props.courts.filter(v => (v.id == itemValue))
        this.setState({ court: court[0] })

    }

    render() {

        const spouse = this.getSpouse();

        const my_docs = this.props.eventDetail.application.documents ? this.props.eventDetail.application.documents.filter((v, i) => (v.document_for == 'self')) : [];
        const spouse_docs = this.props.eventDetail.application.documents ? this.props.eventDetail.application.documents.filter((v, i) => (v.document_for == 'spouse')) : [];
        const my_remaining_docs = []
        const spouse_remaining_docs = []

        for (let i = my_docs.length; i < 4; i++) {
            my_remaining_docs.push(<AddDocument documentID={i} key={i} type="self" eventID={this.props.eventDetail.id} />)
        }

        for (let i = spouse_docs.length; i < 4; i++) {
            spouse_remaining_docs.push(<AddDocument documentID={i} key={i} type="spouse" eventID={this.props.eventDetail.id} />)
        }


        const officiant = this.props.eventDetail.officiants.length > 0 ? this.props.eventDetail.officiants[0] : false

        return (
            <View style={{ position: "relative" }}>
                <View style={{ backgroundColor: "#e0e4e6", position: "relative" }}>
                    <View style={{ paddingHorizontal: 15, backgroundColor: "#FFF", marginVertical: 10, paddingBottom: 20 }}>
                        <View style={{ marginVertical: 10 }}>
                            <Text style={{ fontSize: 22, color: "#043f7b" }}>1. Court</Text>
                        </View>
                        <Text>Select a court.</Text>
                        <View style={{ margin: 10, justifyContent: "center", alignItems: "center" }}>
                            {this.props.courts &&
                                <RNPickerSelect
                                    style={pickerSelectStyles}
                                    placeholder={{ label: "Select Court", color: '#5aabd3', value: null }}
                                    onValueChange={this.selectCourt}
                                    items={this.props.courts.map((v, i) => ({ label: ucWords(v.name), value: v.id }))}
                                />
                            }
                        </View>

                    </View>

                    <View style={{ paddingHorizontal: 15, backgroundColor: "#FFF", marginVertical: 10, paddingBottom: 20 }}>
                        <View style={{ marginVertical: 10 }}>
                            <Text style={{ fontSize: 22, color: "#043f7b" }}>2. Application Information</Text>
                        </View>
                        <ListItem containerStyle={{ marginVertical: 0, paddingVertical: 0 }} pad={1} title="Both partners will be required to take a background identification check by the government server. Both partners must upload copy of government ID and recent selfie picture and complete the court application on the government server upload system." leftAvatar={<Icon type="entypo" name="dot-single" />} />
                        <ListItem containerStyle={{ marginVertical: 0, paddingVertical: 0 }} pad={1} title="Both partners must be 18 years of age or older to use this program. Both partners do not physical haft be together or present to get married." leftAvatar={<Icon type="entypo" name="dot-single" />} />
                        <ListItem containerStyle={{ marginVertical: 0, paddingVertical: 0 }} pad={1} title="Please check all mail including spam and junk mail for government and Web Wed emails to follow instructions." leftAvatar={<Icon type="entypo" name="dot-single" />} />
                        <ListItem containerStyle={{ marginVertical: 0, paddingVertical: 0 }} pad={1} title="Once your marriage license number has been issued forward all documents to info@webwedmobile.com to confirm ceremony date." leftAvatar={<Icon type="entypo" name="dot-single" />} />
                        <ListItem containerStyle={{ marginVertical: 0, paddingVertical: 0 }} pad={1} title="Please tap Review and Submit below to start marriage application." leftAvatar={<Icon type="entypo" name="dot-single" />} />
                    </View>

                    <View>
                        <Text style={{ fontSize: 16, color: "#043f7b", margin: 10 }}>YOUR INFORMATION</Text>
                        <View style={{ padding: 15, backgroundColor: "#FFF", marginVertical: 10 }}>
                            <ListItem titleStyle={{ color: "#043f7b" }} title={this.props.eventDetail.host.full_name} subtitle={this.props.eventDetail.host.email} leftAvatar={
                                <View style={{ width: 35, height: 35, borderRadius: 50, backgroundColor: "#075193", justifyContent: "center", alignItems: "center" }}>
                                    <Text style={{ fontSize: 16, fontWeight: "bold", color: "#FFF" }}>{getInitials(this.props.eventDetail.host.full_name)}</Text>
                                </View>
                            } />
                        </View>
                    </View>

                    <View>
                        <Text style={{ fontSize: 16, color: "#043f7b", margin: 10 }}>SPOUSE INFORMATION</Text>
                        <View style={{ paddingHorizontal: 10, backgroundColor: "#FFF", marginBottom: 10 }}>

                            {spouse ? (<View>
                                <ListItem titleStyle={{ color: "#043f7b" }} title={spouse.name} subtitle={spouse.email} leftAvatar={
                                    <View style={{ width: 35, height: 35, borderRadius: 50, backgroundColor: "#075193", justifyContent: "center", alignItems: "center" }}>
                                        <Text style={{ fontSize: 16, fontWeight: "bold", color: "#FFF" }}>{getInitials(spouse.name)}</Text>
                                    </View>
                                } />
                            </View>) : (<AddPlaceholder title="Add Spouse" subtitle="Select your spouse from the participants page" />)}
                        </View>
                    </View>

                    <View>
                        <View style={{ paddingHorizontal: 15, backgroundColor: "#FFF", marginVertical: 10 }}>
                            <View style={{ marginVertical: 10 }}>
                                <Text style={{ fontSize: 22, color: "#043f7b" }}>3. Officiant Details</Text>
                            </View>
                            {officiant && officiant.user.full_name ?
                                <ListItem titleStyle={{ color: "#043f7b" }} title={officiant.user.full_name} subtitle={officiant.user.email} leftAvatar={
                                    <View style={{ width: 35, height: 35, borderRadius: 50, backgroundColor: "#075193", justifyContent: "center", alignItems: "center" }}>
                                        <Text style={{ fontSize: 16, fontWeight: "bold", color: "#FFF" }}>{getInitials(officiant.user.full_name)}</Text>
                                    </View>
                                } />
                                : (<AddPlaceholder title="Add Officiant" subtitle="Select your officient from the participants page" />)}
                        </View>
                    </View>

                    <View style={{ marginBottom: 50 }}>
                        <View style={{ paddingHorizontal: 15, backgroundColor: "#FFF", marginVertical: 10 }}>
                            <View style={{ marginVertical: 10 }}>
                                <Text style={{ fontSize: 22, color: "#043f7b" }}>4. Review and Submit</Text>
                            </View>
                            <Text style={{ color: "#000", opacity: 0.5, marginVertical: 5 }}>
                                The U.S government court that randomly selects your application will be the marriage bureau that issues your license legally and will be the U.S state that registers your marriage.
                                </Text>
                            <Text style={{ color: "#000", opacity: 0.5, marginVertical: 5 }}>
                                Note: You are not required to reside or have residency or live in the U.S to get married through this program. All our marriages are recognized as civil traditional marriages.
                            </Text>

                        </View>
                    </View>
                </View>

                <View style={{ position: "absolute", right: 0, left: 0, bottom: 0 }}>
                    <Button disabled={spouse == false || officiant == false} title="Review and Submit" onPress={this.submitApplication} buttonStyle={{ padding: 15 }} />
                </View>
            </View>
        )
    }
}


const mapStateToProps = state => {
    return {
        eventDetail: state.eventDetail,
        courts: state.courts,
        loading: state.loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCourts: () => (dispatch(getCourts()))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarriageApplication);

const pickerSelectStyles = StyleSheet.create({
    inputWeb: {
        height: 40
    },
    inputIOS: {
        fontSize: 16,
        paddingVertical: 12,
        paddingHorizontal: 10,
        borderWidth: 1,
        borderColor: 'gray',
        borderRadius: 4,
        color: 'black',
        paddingRight: 30, // to ensure the text is never behind the icon
    },
    inputAndroid: {
        fontSize: 16,
        paddingHorizontal: 10,
        paddingVertical: 8,
        borderWidth: 0.5,
        borderColor: 'purple',
        borderRadius: 8,
        color: 'black',
        paddingRight: 30, // to ensure the text is never behind the icon
    },
});