import * as firebase from 'firebase';
import { API_BASE } from "./masters";

export const EventService = {

    setToken: token => {
        EventService.token = token
    },
    uploadCover: async (url, cover, fields) => {

        let f = new FormData();

        f.append("key", fields.key)
        f.append("AWSAccessKeyId", fields.AWSAccessKeyId)
        f.append("x-amz-security-token", fields['x-amz-security-token'])
        f.append("policy", fields.policy)
        f.append("signature", fields.signature)
        f.append("ACL", fields['ACL'])

        let fn = cover.uri.split("/").pop()
        if (cover.type) {
            f.append('file', {
                name: cover.hasOwnProperty('fileName') ? cover.fileName : fn,
                type: cover.type,
                uri: cover.uri
            })
        }
        else {

            const mType = cover.uri.split(".").pop()
            const fName = cover.uri.split("/").pop()

            f.append('file', {
                name: fName,
                type: mType == '3gp' ? 'video/3gpp' : 'video/mp4',
                uri: cover.uri
            })

        }



        // f.append('cover_id', coverName)
        // const uid_token = await firebase.auth().currentUser.getIdTokenResult();
        return fetch(url,
            {
                method: 'POST',
                body: f
            });
    },

    create: async (event_data) => {
        const uid_token = await firebase.auth().currentUser.getIdTokenResult();

        return fetch(API_BASE + '/events', {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + uid_token.token
            },
            method: 'POST',
            body: JSON.stringify(event_data)
        })
    },
    getEvents: async () => {
        const uid_token = await firebase.auth().currentUser.getIdTokenResult();

        return fetch(API_BASE + '/events', {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + uid_token.token
            }
        })
    },
    archives: async () => {

        const uid_token = await firebase.auth().currentUser.getIdTokenResult();

        return fetch(API_BASE + '/archives', {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + uid_token.token
            }
        })
    },
    playArchive: async eventID => {
        const uid_token = await firebase.auth().currentUser.getIdTokenResult();
        return fetch(API_BASE + '/record/' + eventID + "/play", {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + uid_token.token
            }
        })
    },
    getDetails: async eventID => {
        const uid_token = await firebase.auth().currentUser.getIdTokenResult();

        return fetch(API_BASE + '/events/' + eventID, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + uid_token.token
            }
        })
    },
    completeEvent: async eventID => {
        const uid_token = await firebase.auth().currentUser.getIdTokenResult();

        return fetch(API_BASE + '/events/' + eventID + "/complete", {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + uid_token.token
            }
        })
    },
    getEventByCode: async code => {
        const uid_token = await firebase.auth().currentUser.getIdTokenResult();

        return fetch(API_BASE + '/search/' + code, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + uid_token.token
            }
        })
    },
    inviteGuest: async (eventID, guest) => {
        const uid_token = await firebase.auth().currentUser.getIdTokenResult();

        return fetch(API_BASE + '/events/' + eventID + "/invite-guest", {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + uid_token.token
            },
            method: 'POST',
            body: JSON.stringify(guest)
        })
    },

    inviteOfficiant: async (eventID, officiant_id) => {
        const uid_token = await firebase.auth().currentUser.getIdTokenResult();
        return fetch(API_BASE + '/events/' + eventID + "/invite-officiant", {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + uid_token.token
            },
            method: 'POST',
            body: JSON.stringify({ officiant_id: officiant_id })
        })
    },

    addRegistry: async (eventID, url) => {
        const uid_token = await firebase.auth().currentUser.getIdTokenResult();
        return fetch(API_BASE + '/events/' + eventID + "/registry", {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + uid_token.token
            },
            method: 'POST',
            body: JSON.stringify({ url: url })
        })
    },

    preMaritalEducationCertificate: async (eventID) => {
        const uid_token = await firebase.auth().currentUser.getIdTokenResult();
        return fetch(API_BASE + '/events/' + eventID + "/education", {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + uid_token.token
            },
            method: 'POST'
        })
    },

    toggleStatus: async eventID => {
        const uid_token = await firebase.auth().currentUser.getIdTokenResult();

        return fetch(API_BASE + '/events/' + eventID + "/make-public", {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + uid_token.token
            },
            method: 'GET'
        })
    },
    sendVideoInvite: async (eventID, messageBody) => {
        let f = new FormData();
        const mType = messageBody.video.uri.split(".").pop()

        f.append('body', messageBody.body)
        f.append('subject', messageBody.subject)
        f.append('ftype', mType)

        const uid_token = await firebase.auth().currentUser.getIdTokenResult();
        return fetch(API_BASE + "/events/" + eventID + "/video-invite",
            {
                headers: {
                    Authorization: 'Bearer ' + uid_token.token
                },
                method: 'POST',
                body: f
            });
    },
    uploadDocument: async (eventID, documentId, doc_type) => {
        const d = { document_for: doc_type, document_id: documentId }
        const uid_token = await firebase.auth().currentUser.getIdTokenResult();

        return fetch(API_BASE + "/events/" + eventID + "/documents",
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + uid_token.token
                },
                method: 'POST',
                body: JSON.stringify(d)
            });
    },
    saveApplication: async (eventID, court_id) => {
        const uid_token = await firebase.auth().currentUser.getIdTokenResult();
        const application_data = {
            court_id: court_id
        }
        return fetch(API_BASE + '/events/' + eventID + "/applications", {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + uid_token.token
            },
            method: 'POST',
            body: JSON.stringify(application_data)
        })
    },
    startSession: async (eventID) => {
        const uid_token = await firebase.auth().currentUser.getIdTokenResult();

        return fetch(API_BASE + '/events/' + eventID + "/start", {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + uid_token.token
            },
            method: 'GET'
        })
    },
    joinAsGuest: (eventID, viewerName) => {

        return fetch(API_BASE + '/open/join-guest/' + eventID, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name: viewerName }),
            method: 'POST'
        })
    },
    joinAsParticipant: async eventID => {
        const uid_token = await firebase.auth().currentUser.getIdTokenResult();

        return fetch(API_BASE + '/events/' + eventID + "/join", {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + uid_token.token
            },
            method: 'GET'
        })
    },
    toggleRecording: async (eventID, status, session_id) => {
        console.log(eventID, session_id)
        const action = status ? 'stop' : 'start'
        const uid_token = await firebase.auth().currentUser.getIdTokenResult();

        return fetch(API_BASE + '/record/' + eventID + "/" + action, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + uid_token.token
            },
            body: JSON.stringify({ session_id: session_id }),
            method: 'POST'
        })
    },
    acceptInvite: async eventID => {
        const uid_token = await firebase.auth().currentUser.getIdTokenResult();

        return fetch(API_BASE + '/invite/' + eventID + "/accept", {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + uid_token.token
            },
            method: 'POST'
        })
    },
    declineInvite: async eventID => {
        const uid_token = await firebase.auth().currentUser.getIdTokenResult();

        return fetch(API_BASE + '/invite/' + eventID + "/decline", {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + uid_token.token
            },
            method: 'POST'
        })
    },
    joinSharedSession: async eventCode => {
        // const uid_token = await firebase.auth().currentUser.getIdTokenResult();

        return fetch(API_BASE + '/open/join-shared/' + eventCode, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                // Authorization: 'Bearer ' + uid_token.token
            },
            method: 'GET'
        })
    },
    removeParticipant: async (eventID, inviteID) => {
        const uid_token = await firebase.auth().currentUser.getIdTokenResult();

        return fetch(`${API_BASE}/events/${eventID}/remove-invite/${inviteID}`, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + uid_token.token
            },
            method: 'DELETE'
        })
    }

}
