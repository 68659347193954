import {
    createStore,
    applyMiddleware,
    compose
} from 'redux';
import thunk from 'redux-thunk';
// import clientMiddleware from '../middlewares/clientMiddleware';

import rootReducer from "./reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line
const preloadedState = window.__PRELOADED_STATE__ || {}; // eslint-disable-line

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
export default store;