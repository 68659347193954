export const offsetToHourMinute = (minutes) => {
    minutes = -(minutes);
    const hours = Math.floor(minutes / 60);
    const remained_minutes = minutes - (hours * 60)

    return ("0" + hours).slice(-2) + ":" + ("0" + remained_minutes).slice(-2)
}

export const ucWords = s => {
    if (s) {
        const str = s.toLowerCase().replace(/\b[a-z]/g, function (letter) {
            return letter.toUpperCase();
        });
        return str;
    }
    else {
        return s;
    }
}

export const getInitials = s => {
    if (s) {
        const x = s.split(" ")
        if (x.length > 1) {
            return (x[0][0] + x[1][0]).toUpperCase();
        }
        else {
            const y = x[0]
            if (y.length > 1) {
                return (y[0] + y[1]).toUpperCase()
            }
            else {
                return '';
            }
        }
    }
    else {
        return s;
    }
}

export const formatDate = (ymdHMS, onlyDate = false, delimiter = "", showYr = true, timeFormat = 24) => {

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];


    if (ymdHMS) {
        ymdHMS = ymdHMS.replace(" ", "T") + ".000Z";

        const d = new Date(Date.parse(ymdHMS));

        let hrs = d.getHours();
        let ampm = '';
        if (timeFormat == 12) {
            if (hrs > 12) {
                hrs = hrs-12;
                ampm = 'PM';
            }
            else {
                ampm = 'AM'
            }
        }

        if (onlyDate) {
            return monthNames[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear()

        }
        else {
            return monthNames[d.getMonth()] + " " + d.getDate() + (showYr ? ", " + d.getFullYear() : "") + " " + delimiter + ("0" + hrs).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + " " + ampm

        }
    }
    else {
        return false;
    }
}

export const randomColor = () => {
    const bgs = ['#075193', '#53d7ee', '#5AABD3'];
    const bg = bgs[Math.floor(Math.random() * bgs.length)];
    return bg;
}