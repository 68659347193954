import { createStackNavigator } from '@react-navigation/stack';
import React, { Component } from 'react';
import { Image } from 'react-native';
import { Icon } from 'react-native-elements';
import { TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import Checkout from '../components/Checkout';
import MarriageApplicationCongrats from '../components/MarriageApplicationCongrats';
import NavigationDrawerIcon from '../components/NavigationDrawerIcon';
import NavigationRightIcons from '../components/NavigationRightIcons';
import OrderReview from '../components/OrderReview';
import VideoInviteForm from '../components/VideoInviteForm';
import { color1, color4 } from '../configs/themeOptions';
import Archive from '../pages/Archive';
import CreateEvent from '../pages/CreateEvent';
import DashboardTabs from '../pages/DashboardTabs';
import EventCompleted from '../pages/EventCompleted';
import EventDetailTabs from '../pages/EventDetailTabs';
import FindOfficiant from '../pages/FindOfficiant';
import ImportContact from '../pages/ImportContact';
import LiveStream from '../pages/LiveStream';
import ManualInvite from '../pages/ManualInvite';
import MarriageApplication from '../pages/MarriageApplication';
import MarriageEducation from '../pages/MarriageEducation';
import MarriageLicense from '../pages/MarriageLicense';
import NotificationsList from '../pages/NotificationsList';
import OfficiantDetail from '../pages/OfficiantDetail';
import OrderDetails from '../pages/OrderDetails';
import PlayRecord from '../pages/PlayRecord';
import RecordVideoList from '../pages/RecordVideoList';
import RegistryLink from '../pages/RegistryLink';
import Viewer from '../pages/Viewer';
import { flipCamera, getProfile } from '../stores/actions';
import ProfileTabs from './ProfileTabs';



const Stack = createStackNavigator();

const img = require('../assets/images/webwedlogo.png');
class MainStack extends Component {

    flipCamera = () => {
        const cameraPosition = this.props.cameraPosition == 'front' ? 'back' : 'front';
        this.props.flipCamera(cameraPosition)
    }

    componentDidMount(){
        this.props.getProfile();
    }

    render() {
        const { params } = this.props.route;

        return (
            <Stack.Navigator screenOptions={{
                headerLeft: () => (<NavigationDrawerIcon navigationProps={this.props.navigation} />),
                headerRight: () => (<NavigationRightIcons navigation={this.props.navigation} />),
                headerStyle: {
                    backgroundColor: color4,
                    shadowColor: "transparent"
                },
                headerTitleAlign: "left",
                headerTitle: () => (<Image source={img} style={{ marginLeft: -30, paddingHorizontal: 0,width:100,height:50 }} />)
            }}
                backBehavior="initialRoute">
                <Stack.Screen name="Dashboard" component={DashboardTabs} />
                <Stack.Screen name="EventCompleted" component={EventCompleted} options={({ route }) => ({
                    headerTitle: "Congratulations!!",
                    headerStyle: {
                        backgroundColor: "#023C6F",
                        elevation: 0,
                        shadowColor: "transparent"
                    },
                    headerLeftContainerStyle: { marginLeft: 15 },
                    headerTitleAlign: "center",
                    headerRight: () => (null),
                    headerLeft: () => (null),
                    headerTintColor: '#fff',

                })} />
                <Stack.Screen name="CreateEvent" component={CreateEvent} options={({ route }) => ({
                    headerTitle: "Create event",
                    headerStyle: {
                        backgroundColor: color1,
                        elevation: 0,
                        shadowColor: "transparent"
                    },
                    headerLeftContainerStyle: { marginLeft: 15 },
                    headerTitleAlign: "left",
                    headerRight: () => (null),
                    headerLeft: () => { return (<Icon name="md-arrow-back" type="ionicon" size={24} color="white" onPress={() => { this.props.navigation.goBack() }} />) },
                    headerTintColor: '#fff',

                })} />
                <Stack.Screen name="ReviewOrder" component={OrderReview} options={({ route }) => ({
                    headerTitle: route.params.title,
                    headerStyle: {
                        backgroundColor: color1,
                        elevation: 0
                    },
                    headerLeftContainerStyle: { marginLeft: 15 },
                    headerTitleAlign: "left",

                    headerLeft: () => { return (<Icon name="md-arrow-back" type="ionicon" size={24} color="white" onPress={() => { this.props.navigation.goBack() }} />) },
                    headerTintColor: '#fff',

                })} />
                <Stack.Screen name="Checkout" component={Checkout} options={({ route }) => ({
                    headerTitle: route.params.title,
                    headerStyle: {
                        backgroundColor: color1,
                        elevation: 0,
                        shadowColor: "transparent"
                    },
                    headerLeftContainerStyle: { marginLeft: 15 },
                    headerTitleAlign: "left",

                    headerLeft: () => { return (<Icon name="md-arrow-back" type="ionicon" size={24} color="white" onPress={() => { this.props.navigation.goBack() }} />) },
                    headerTintColor: '#fff',

                })} />
                <Stack.Screen name="EventDetailTabs" component={EventDetailTabs} options={({ route }) => ({
                    headerTitle: route.params.name,
                    headerStyle: {
                        backgroundColor: color1,
                        elevation: 0,
                        shadowColor: "transparent"
                    },
                    headerLeftContainerStyle: { marginLeft: 15 },
                    headerTitleAlign: "left",
                    headerRight: () => (null),
                    headerLeft: () => { return (<Icon name="md-arrow-back" type="ionicon" size={24} color="white" onPress={() => { this.props.navigation.goBack() }} />) },
                    headerTintColor: '#fff',

                })} />
                <Stack.Screen name="Orders" component={OrderDetails} options={({ route }) => ({
                    headerTitle: route.params.name,
                    headerStyle: {
                        backgroundColor: color1,
                        elevation: 0,
                        shadowColor: "transparent"
                    },
                    headerLeftContainerStyle: { marginLeft: 15 },
                    headerTitleAlign: "left",
                    headerRight: () => (null),
                    headerLeft: () => { return (<Icon name="md-arrow-back" type="ionicon" size={24} color="white" onPress={() => { this.props.navigation.goBack() }} />) },
                    headerTintColor: '#fff',

                })} />
                <Stack.Screen name="MarriageEducation" component={MarriageEducation} options={({ route }) => ({
                    headerTitle: "Marriage Education",
                    headerStyle: {
                        backgroundColor: color1,
                        elevation: 0,
                        shadowColor: "transparent"
                    },
                    headerLeftContainerStyle: { marginLeft: 15, marginRight: 0 },
                    headerTitleAlign: "left",

                    headerLeft: () => { return (<Icon name="md-arrow-back" type="ionicon" size={24} color="white" onPress={() => { this.props.navigation.goBack() }} />) },
                    headerTintColor: '#fff',

                })} />
                <Stack.Screen name="MarriageApplication" component={MarriageApplication} options={({ route }) => ({
                    headerTitle: "Marriage Application",
                    headerStyle: {
                        backgroundColor: color1,
                        elevation: 0,
                        shadowColor: "transparent"
                    },
                    headerLeftContainerStyle: { marginLeft: 15, marginRight: 0 },
                    headerTitleAlign: "left",
                    headerLeft: () => { return (<Icon name="md-arrow-back" type="ionicon" size={24} color="white" onPress={() => { this.props.navigation.goBack() }} />) },
                    headerTintColor: '#fff',

                })} />
                <Stack.Screen name="MarriageLicense" component={MarriageLicense} options={({ route }) => ({
                    headerTitle: "Marriage License",
                    headerStyle: {
                        backgroundColor: color1,
                        elevation: 0,
                        shadowColor: "transparent"
                    },
                    headerLeftContainerStyle: { marginLeft: 15, marginRight: 0 },
                    headerTitleAlign: "left",

                    headerLeft: () => { return (<Icon name="md-arrow-back" type="ionicon" size={24} color="white" onPress={() => { this.props.navigation.goBack() }} />) },
                    headerTintColor: '#fff',

                })} />
                <Stack.Screen name="Registry" component={RegistryLink} options={({ route }) => ({
                    headerTitle: "Registry Link",
                    headerStyle: {
                        backgroundColor: color1,
                        elevation: 0,
                        shadowColor: "transparent"
                    },
                    headerLeftContainerStyle: { marginLeft: 15, marginRight: 0 },
                    headerTitleAlign: "left",

                    headerLeft: () => { return (<Icon name="md-arrow-back" type="ionicon" size={24} color="white" onPress={() => { this.props.navigation.goBack() }} />) },
                    headerTintColor: '#fff',

                })} />
                <Stack.Screen name="LiveStream" component={LiveStream} options={({ route }) => ({
                    headerTitle: route.params.name,
                    headerStyle: {
                        backgroundColor: '#023C6F',
                        elevation: 0,
                        shadowColor: "transparent"
                    },
                    headerLeftContainerStyle: { marginLeft: 15, marginRight: 0 },
                    headerTitleAlign: "left",
                    headerRight: () => (<TouchableOpacity onPress={this.flipCamera} style={{ marginRight: 10 }}>
                        <Icon color={this.props.cameraPosition == 'back' ? "#5aabd3" : '#FFF'} size={18} type="material-community"
                            name="camera-party-mode"
                        />
                    </TouchableOpacity>),
                    headerLeft: () => {
                        return (<TouchableOpacity onPress={() => { this.props.navigation.goBack() }}>
                            <Icon
                                type="ionicon"
                                name="md-arrow-back"
                                size={24}
                                color="white" />
                        </TouchableOpacity>)
                    },
                    headerTintColor: '#fff',

                })} />
                <Stack.Screen name="Viewer" component={Viewer} options={({ route }) => ({
                    headerTitle: route.params.name,
                    headerStyle: {
                        backgroundColor: '#023C6F',
                        elevation: 0,
                        shadowColor: "transparent"
                    },
                    headerRight: () => (null),
                    headerLeftContainerStyle: { marginLeft: 15, marginRight: 0 },
                    headerTitleAlign: "left",

                    headerLeft: () => {
                        return (<TouchableOpacity onPress={() => { this.props.navigation.goBack() }}>
                            <Icon
                                type="ionicon"
                                name="md-arrow-back"
                                size={24}
                                color="white" />
                        </TouchableOpacity>)
                    },
                    headerTintColor: '#fff',

                })} />

                <Stack.Screen name="ProfileTabs" component={ProfileTabs} options={{ headerShown: false }} />
                <Stack.Screen name="Notifications" component={NotificationsList} options={({ route }) => ({
                    headerTitle: route.params.name,
                    headerStyle: {
                        backgroundColor: '#023C6F',
                        elevation: 0,
                        shadowColor: "transparent"
                    },
                    headerLeftContainerStyle: { marginLeft: 15, marginRight: 0 },
                    headerTitleAlign: "left",

                    headerLeft: () => {
                        return (<TouchableOpacity onPress={() => { this.props.navigation.goBack() }}>
                            <Icon
                                type="ionicon"
                                name="md-arrow-back"
                                size={24}
                                color="white" />
                        </TouchableOpacity>)
                    },
                    headerTintColor: '#fff',

                })} />
                <Stack.Screen name="PlayArchive" component={Archive} options={{ headerShown: false }} />

                <Stack.Screen name="VideoInvite" component={VideoInviteForm} options={({ route }) => ({
                    headerTitle: "Create an Invitation",
                    headerStyle: {
                        backgroundColor: '#023C6F',
                        elevation: 0,
                        shadowColor: "transparent"
                    },
                    headerLeftContainerStyle: { marginLeft: 15, marginRight: 0 },
                    headerTitleAlign: "left",

                    headerLeft: () => {
                        return (<TouchableOpacity onPress={() => { this.props.navigation.goBack() }}>
                            <Icon
                                type="ionicon"
                                name="md-arrow-back"
                                size={24}
                                color="white" />
                        </TouchableOpacity>)
                    },
                    headerTintColor: '#fff',

                })} />

                <Stack.Screen name="ManualInvite" component={ManualInvite} options={({ route }) => ({
                    headerTitle: route.params.title,
                    headerStyle: {
                        backgroundColor: color1,
                        elevation: 0,
                        shadowColor: "transparent"
                    },
                    headerLeftContainerStyle: { marginLeft: 15 },
                    headerTitleAlign: "left",

                    headerLeft: () => { return (<Icon name="md-arrow-back" type="ionicon" size={24} color="white" onPress={() => { this.props.navigation.goBack() }} />) },
                    headerTintColor: '#fff',

                })} />
                <Stack.Screen name="ImportContact" component={ImportContact} options={({ route }) => ({
                    headerTitle: route.params.title,
                    headerStyle: {
                        backgroundColor: color1,
                        elevation: 0,
                        shadowColor: "transparent"
                    },
                    headerLeftContainerStyle: { marginLeft: 15 },
                    headerTitleAlign: "left",

                    headerLeft: () => { return (<Icon name="md-arrow-back" type="ionicon" size={24} color="white" onPress={() => { this.props.navigation.goBack() }} />) },
                    headerTintColor: '#fff',

                })} />
                <Stack.Screen name="FindOfficiant" component={FindOfficiant} options={({ route }) => ({
                    headerTitle: route.params.title,
                    headerStyle: {
                        backgroundColor: color1,
                        elevation: 0,
                        shadowColor: "transparent"
                    },
                    headerLeftContainerStyle: { marginLeft: 15 },
                    headerTitleAlign: "left",

                    headerLeft: () => { return (<Icon name="md-arrow-back" type="ionicon" size={24} color="white" onPress={() => { this.props.navigation.goBack() }} />) },
                    headerTintColor: '#fff',

                })} />
                <Stack.Screen name="OfficiantDetail" component={OfficiantDetail} options={({ route }) => ({
                    headerTitle: route.params.title,
                    headerStyle: {
                        backgroundColor: "#023C6F",
                        elevation: 0,
                        shadowColor: "transparent"
                    },
                    headerLeftContainerStyle: { marginLeft: 15 },
                    headerTitleAlign: "left",
                    headerRight: () => (null),
                    headerLeft: () => { return (<Icon name="md-arrow-back" type="ionicon" size={24} color="white" onPress={() => { this.props.navigation.goBack() }} />) },
                    headerTintColor: '#fff',

                })} />
                <Stack.Screen name="MarriageApplicationCongrats" component={MarriageApplicationCongrats} options={{ headerShown: false }} />
                <Stack.Screen name="RecordVideoList" component={RecordVideoList} options={({ route }) => ({
                    headerTitle: 'Recorded Videos',
                    headerStyle: {
                        backgroundColor: color1,
                        elevation: 0,
                        shadowColor: "transparent"
                    },
                    headerLeftContainerStyle: { marginLeft: 15 },
                    headerTitleAlign: "left",
                    headerRight: () => (null),
                    headerLeft: () => { return (<Icon name="md-arrow-back" type="ionicon" size={24} color="white" onPress={() => { this.props.navigation.goBack() }} />) },
                    headerTintColor: '#fff',
                })} />
                
                <Stack.Screen name="PlayRecord" component={PlayRecord} options={{ headerShown: false }} />
            </Stack.Navigator>

        )
    }
}

const mapStateToProps = state => {
    return {
        cameraPosition: state.cameraPosition
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getProfile: () => dispatch(getProfile()),
        flipCamera: cameraPosition => dispatch(flipCamera(cameraPosition))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainStack)
