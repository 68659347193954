import React, { Component } from 'react'
import { ImageBackground, StyleSheet, TouchableOpacity, View } from 'react-native'
import { Icon } from 'react-native-elements'
import SocialShare from '../components/SocialShare'
import { formatDate } from '../utils/convrters'
import Text from '../components/WebwedText'
import { color5 } from '../configs/themeOptions'
import { joinURL } from '../services/masters'

export default class EventNotStartedModal extends Component {

    render() {
        return (
            <View style={{position:"absolute",top:0,left:0,right:0}}>
                {/* <ListItem leftIcon={<TouchableOpacity
                    onPress={this.props.goBack}>
                    <Icon type="ionicon" color="white" name="md-arrow-back" />
                </TouchableOpacity>}
                    title="Event not started yet!"
                    titleStyle={{ color: "#FFF" }}
                    containerStyle={{ backgroundColor: "#5aabd3" }}
                /> */}

                <View >
                    {/*  */}
                    <View style={{ height: 250, width: "100%" }}>
                        <ImageBackground source={require('../assets/images/placeholder.png')} style={styles.bgImage}>
                            <View style={{ flex: 1, alignItems: "center", justifyContent: "flex-end", padding: 10 }}>
                                <Text style={styles.title}>{this.props.eventDetail.title}</Text>
                                <Text style={styles.eventDate}>{formatDate(this.props.eventDetail.event_time, false, " | ", false)}</Text>
                                <Text style={styles.eventDate}>Event ID #{this.props.eventDetail.code}</Text>
                            </View>
                        </ImageBackground>
                    </View>
                    <View style={{ alignItems: "center", alignContent: "center", padding: 20 }}>
                        <Text style={{ color: '#023C6F' }}>
                            This event is not available to watch yet. Sit tight and the stream will start automatically.
                            </Text>
                    </View>
                    <View style={{ flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", marginTop: 20 }}>
                        <View style={{ justifyContent: "center", flex: 1, alignItems: "center" }}>
                            <SocialShare message={"Please come and join us and be the part of our special moments: " + this.props.eventDetail.title} url={joinURL + this.props.eventDetail.code}/>
                        </View>
                        <View style={{ justifyContent: "center", flex: 1, alignItems: "center" }}>
                            <TouchableOpacity style={{ alignItems: "center" }} onPress={this.props.goToRegistry}>
                                <Icon type="ionicon" name="md-star" reverse color="#ffc125" size={24} />
                                <Text>Registry</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>

            </View>
        )
    }
}

const styles = StyleSheet.create({
    eventTitle: {
        color: color5,
        margin: 0,
        fontFamily: '"Roboto","Helvetica Neue", sans-serif',
        paddingVertical: 8,
        fontSize: 24,
        paddingHorizontal: 20
    },
    eventDate: {
        color: "#FFF",
        margin: 5,
        paddingHorizontal: 20
    },
    participant: {
        borderWidth: 1,
        borderColor: 'rgba(0,0,0,0.2)',
        alignItems: 'center',
        justifyContent: 'center',
        width: 50,
        height: 50,
        padding: 5,
        backgroundColor: '#fff',
        borderRadius: 50,
        marginRight: 10
    },
    title: {
        fontSize: 18,
        fontFamily: '"Roboto","Helvetica Neue", sans-serif',
        color: 'white'
    },
    eventList: { backgroundColor: '#d3dbdf' },
    bgImage: {
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        resizeMode: "cover"
    }
}
)
