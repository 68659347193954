import React, { Component } from 'react'
import { View, ActivityIndicator } from 'react-native'

export default class LoadingInfo extends Component {
    render() {
        return (
            <View style={[{flex:1,alignItems:"center",justifyContent:"center"},this.props.style]}>
                <ActivityIndicator animating={true} color={this.props.spinColor} />
            </View>
        )
    }
}
