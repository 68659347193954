import React, { Component } from 'react';
import { ImageBackground, ScrollView, View } from 'react-native';
import { Button, Icon, ListItem } from 'react-native-elements';
import { connect } from 'react-redux';
import { EventService } from '../services/events';
import { getEventDetail, refreshEvent } from '../stores/actions';
import { getInitials, ucWords } from '../utils/convrters';
import Text from '../components/WebwedText'
import SocialShare from '../components/SocialShare';
import { TouchableOpacity } from 'react-native';
import { joinURL } from '../services/masters';
import Alert from '../components/Alert'

class OfficiantDetail extends Component {
    state = { currentOfficiant: {} }
    componentDidMount = () => {
        this.setState({ currentOfficiant: this.props.route.params.officiant })
    }

    inviteOfficiant = () => {

        const officiant_id = this.state.currentOfficiant.id;
        EventService.inviteOfficiant(this.props.eventDetail.id, officiant_id).then(res => res.json()).then(json => {

            const eventDetail = this.props.eventDetail
            eventDetail.officiants.push(json.officiant);

            this.props.getEvent(eventDetail.id)

            Alert.alert("Success!!", json.message, [
                {
                    text: "Okay",
                    onPress: () => {
                        this.setState({ loading: false })
                        this.props.navigation.pop(2)
                    }
                }
            ]);

        }).catch(err => {
            Alert.alert("Failed", "Oops! please try again after some time.");

        })
    }
    render() {
        // console.log("curr,,,,",this.state.currentOfficiant)
        return (
            <View style={{ flex: 1 }}>
                {this.state.currentOfficiant.user &&
                    <ScrollView style={{ backgroundColor: "#f1f1f1" }}>
                        <View style={{ marginBottom: 40 }}>
                            <ImageBackground source={require('../assets/images/background.png')} style={{ flex: 1, height: 250, backgroundColor: '#1658a0', paddingVertical: 10, justifyContent: "center", alignItems: 'center' }}>
                                {/* <Image source={require('../assets/images/logo-full.png')} style={{ alignSelf: 'center' }} /> */}
                                <View style={{ width: 100, height: 100, borderRadius: 50, backgroundColor: '#FFF', justifyContent: "center", alignItems: "center" }}>
                                    <Text style={{ fontSize: 24, fontFamily: '"Roboto","Helvetica Neue", sans-serif', color: "#023C6F" }}> {getInitials(this.state.currentOfficiant?.user.full_name)} </Text>
                                </View>
                                <Text style={{ fontSize: 18, color: 'white', marginVertical: 5 }}> {ucWords(this.state.currentOfficiant?.user.full_name)} </Text>

                            </ImageBackground>

                            <View style={{ flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", padding: 30, backgroundColor: "#FFF", marginBottom: 10 }}>
                            <SocialShare style={{ justifyContent: "center", flex: 1, alignItems: "center", marginTop: 20 }} message={"Please come and join us and be the part of our special moments: " + this.props.eventDetail.title} url={joinURL + this.props.eventDetail.code} />

                                <TouchableOpacity onPress={this.inviteOfficiant} style={{ justifyContent: "center", flex: 1, alignItems: "center" }}>
                                    <Icon type="ionicon" name="md-star" reverse color="#e1e1e1" size={24} />
                                    <Text>Invite</Text>
                                </TouchableOpacity>
                            </View>
                            <Text style={{ color: "#488aff", marginHorizontal: 10 }}>GENERAL</Text>
                            <View style={{ marginVertical: 10 }}>

                                <ListItem bottomDivider>
                                    <Icon type="ionicon" name="md-pin" />
                                    <ListItem.Content>
                                        <ListItem.Title style={{fontFamily:'"Roboto","Helvetica Neue", sans-serif'}}>
                                            {this.state.currentOfficiant?.user.profile.address}
                                        </ListItem.Title>
                                    </ListItem.Content>
                                </ListItem>

                                <ListItem bottomDivider>
                                    <Icon type="ionicon" name="md-pin" />
                                    <ListItem.Content>
                                        <ListItem.Title style={{fontFamily:'"Roboto","Helvetica Neue", sans-serif'}}>
                                            {this.state.currentOfficiant?.user.profile.mailing_address}
                                        </ListItem.Title>
                                    </ListItem.Content>
                                </ListItem>

                                <ListItem bottomDivider>
                                    <Icon type="ionicon" name="md-mail" />
                                    <ListItem.Content>
                                        <ListItem.Title style={{fontFamily:'"Roboto","Helvetica Neue", sans-serif'}}>
                                            {this.state.currentOfficiant?.user.email}
                                        </ListItem.Title>
                                    </ListItem.Content>
                                </ListItem>

                                <ListItem bottomDivider>
                                    <Icon type="ionicon" name="md-call" />
                                    <ListItem.Content>
                                        <ListItem.Title style={{fontFamily:'"Roboto","Helvetica Neue", sans-serif'}}>
                                            {this.state.currentOfficiant?.user.profile.phone}
                                        </ListItem.Title>
                                    </ListItem.Content>
                                </ListItem>
                            </View>

                            <Text style={{ color: "#488aff", marginHorizontal: 10 }}>OTHER</Text>

                            <View style={{ marginVertical: 10 }}>
                                <ListItem bottomDivider>
                                    <Icon type="ionicon" name="md-star" reverse color="#ffc125" size={10} />
                                    <ListItem.Content>
                                        <ListItem.Title style={{fontFamily:'"Roboto","Helvetica Neue", sans-serif'}}>
                                            Fees
                                        </ListItem.Title>
                                    </ListItem.Content>
                                    <ListItem.Title style={{fontFamily:'"Roboto","Helvetica Neue", sans-serif'}}>
                                        {this.state.currentOfficiant.fee ? this.state.currentOfficiant.fee : 'Free'}
                                    </ListItem.Title>
                                </ListItem>
                            </View>
                        </View>
                    </ScrollView>}
                <View style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
                    <Button titleStyle={{fontFamily:'"Roboto","Helvetica Neue", sans-serif'}} buttonStyle={{ backgroundColor: "#00bcd4", padding: 15 }} title="INVITE TO OFFICIATE" onPress={this.inviteOfficiant} />
                </View>
            </View>
        )
    }
}

const mapStateToProps = state => {
    return {
        eventDetail: state.eventDetail
    }
}

const mapDispatchToProps = dispatch => {
    return {
        refreshEvent: eventDetail => dispatch(refreshEvent(eventDetail)),
        getEvent: id => dispatch(getEventDetail(id))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OfficiantDetail)
