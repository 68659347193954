import React, { Component } from 'react';
import { Dimensions, PermissionsAndroid, StyleSheet, View } from 'react-native';
import { Button, Icon } from 'react-native-elements';
import Alert from '../components/Alert';
import LoadingModal from '../components/LoadingModal';
import { EventService } from '../services/events';
import { Video } from 'expo-av';
import { primaryColor } from '../configs/themeOptions';
import { Linking } from 'react-native';

const d = Dimensions.get('screen')
export default class Archive extends Component {

    state = { url: '' }

    componentDidMount = () => {
        const { eventID } = this.props.route.params
        this.playArchive(eventID)
    }

    playArchive = eventID => {
        EventService.playArchive(eventID).then(res => res.json()).then(json => {
            console.log(json)
            if (json.url) {
                this.setState({ url: json.url })
            }
            else {
                Alert.alert("Failed!", "Could not retrieve the file", [{ text: 'Okay', onPress: () => { this.props.navigation.goBack() } }])
            }
        }).catch(err => {
            console.log(err)
        })
    }

    hasAndroidPermission = async () => {
        const permission = PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE;

        const hasPermission = await PermissionsAndroid.check(permission);
        if (hasPermission) {
            return true;
        }

        const status = await PermissionsAndroid.request(permission);
        return status === 'granted';
    }

    downloadArchive = () => {
        Linking.openURL(this.state.url)
    }

    render() {
        return (
            <View style={{flex:1}}>
                {this.state.url ? (
                    <View style={{ flex:1}}>
                        <Video isMuted={false}
                            resizeMode="contain"
                            style={{ width: '100%', height: '100%' }}
                            shouldPlay 
                            useNativeControls
                            source={{ uri: this.state.url }} />

                        <View style={{ position: "absolute", top: 10, right: 0 }}>
                            <Button titleStyle={{ fontSize: 12 }} onPress={this.downloadArchive} buttonStyle={{ backgroundColor: "transparent" }} titleStyle={{color:primaryColor}} title=" Download" icon={<Icon color={primaryColor} name="cloud-download" type="material-icon" />} />
                        </View>
                    </View>
                ) : <LoadingModal />}
            </View>
        )
    }
}

var styles = StyleSheet.create({
    backgroundVideo: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
});