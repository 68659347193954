import React, { Component } from 'react';
import { Alert, Image, Share, StyleSheet, View } from 'react-native';
import { Button, Card, Icon } from 'react-native-elements';
import { ScrollView, TouchableOpacity } from 'react-native';
import Swipeable from 'react-native-gesture-handler/Swipeable';
import { connect } from 'react-redux';
import { color2, color3, color5, primaryColor, secondaryColor, successColor } from "../configs/themeOptions";
import { EventService } from '../services/events';
import { APP_BASE, imagePath, joinURL } from '../services/masters';
import { formatDate, getInitials, randomColor } from '../utils/convrters';
import Text from '../components/WebwedText'
import { getEvents } from '../stores/actions';
import { Dimensions } from 'react-native';

const placeholder = require('../assets/images/placeholder.png');

class EventItem extends Component {

    state = { status: 'not_accepted' }
    handleEventView = () => {
        const eventID = this.props.event.id;
        this.props.navigation.push('EventDetailTabs', { name: this.props.event.title, screen: 'Details', params: { eventID: eventID } });
    }

    accept = () => {
        EventService.acceptInvite(this.props.event.id).then(res => res.json()).then(json => {
            if (json.message) {
                Alert.alert("Success!!", json.message, [{
                    text: 'Okay', onPress: () => {
                        this.setState({ status: 'ACCEPTED' })
                        this.Swipeable.close()
                        this.props.getEvents()
                    }
                }])
            }
            else {
                Alert.alert("Failed!!", json.error)
            }
        }).catch(err => {
            Alert.alert("Failed!!", err)

        })
    }
    decline = () => {
        EventService.declineInvite(this.props.event.id).then(res => res.json()).then(json => {
            if (json.message) {
                Alert.alert("Success!!", "You have successfully declined.", [{
                    text: 'Okay', onPress: () => {
                        this.setState({ status: 'DECLINED' })
                        this.Swipeable.close()
                        this.props.getEvents()
                    }
                }])
            }
            else {
                Alert.alert("Failed!!", json.error)
            }
        }).catch(err => {
            Alert.alert("Failed!!", err)

        })
    }

    renderRightActions = (progress, dragX) => {

        return (
            <View style={{ backgroundColor: '#044988', justifyContent: "center", paddingHorizontal: 10 }}>
                {this.state.status == 'not_accepted' ?
                    <View>
                        <View style={{ alignItems: "center" }}>
                            <Text style={{ color: "white" }}>Respond to the</Text>
                            <Text style={{ color: "white" }}>Invitation with </Text>
                        </View>
                        <Button
                            containerStyle={{ padding: 0, margin: 10 }}
                            buttonStyle={{ paddingHorizontal: 8, paddingVertical: 3, borderRadius: 15, backgroundColor: color2 }}
                            titleStyle={{ fontSize: 12, fontFamily: '"Roboto","Helvetica Neue", sans-serif' }}
                            title='Accept' type="solid" onPress={this.accept} />
                        <Button
                            containerStyle={{ padding: 0, margin: 10 }}
                            buttonStyle={{ paddingHorizontal: 8, paddingVertical: 3, borderRadius: 15, backgroundColor: "tomato" }}
                            titleStyle={{ fontSize: 12, fontFamily: '"Roboto","Helvetica Neue", sans-serif' }}
                            title='Decline' type="solid" onPress={this.decline} />
                        {/* <Button type="outline" raised title="Accept" containerStyle={{ margin: 10 }} titleStyle={{ fontFamily: '"Roboto","Helvetica Neue", sans-serif' }} onPress={this.accept} />
                        <Button titleStyle={{ fontFamily: '"Roboto","Helvetica Neue", sans-serif' }} type="outline" raised title="Unable to attend" containerStyle={{ margin: 10 }} onPress={this.decline} /> */}
                    </View>
                    :
                    <View>
                        <View style={{ alignItems: "center" }}>
                            <Text style={{ color: '#FFF' }}>You have already {this.state.status.toLowerCase()} </Text>
                        </View>
                    </View>}
            </View>
        );
    };

    isInvited = () => {
        return this.props.event.host.uid != this.props.user.uid && this.props.event.invitations.some((v) => (v.email == this.props.user.email))
    }

    isHost = () => {
        // console.log(this.props.event.host.uid, " *** ", this.props.user.uid)
        const invite = this.props.event.invitations.filter((v, i) => (v.email == this.props.user.email && v.role == 'officiant'))
        return this.props.event.host.uid == this.props.user.uid || invite.length > 0

    }

    hasNotResponded = () => {
        return this.props.event.host.uid != this.props.user.uid && this.props.event.invitations.some((v) => (v.email == this.props.user.email && v.invite_status == 'EventInvites.not_accepted'))
    }

    getInviteStatus = () => {
        const invited = this.props.event.invitations.filter(v => (v.email == this.props.user.email))
        if (invited.length > 0) {
            console.log("st: ", invited[0]['invite_status'].split(".").pop())
            return invited[0]['invite_status'].split(".").pop()
        }
        else {
            return '';
        }
    }

    share = async () => {
        try {
            const message = "Please come and join us and be the part of our special moments: " + this.props.event.title + " \n"
            const url = joinURL + this.props.event.id
            const result = await Share.share({
                message: message + " " + url
            });
            if (result.action === Share.sharedAction) {
                if (result.activityType) {
                    // shared with activity type of result.activityType
                } else {
                    // shared
                }
            } else if (result.action === Share.dismissedAction) {
                // dismissed
            }
        } catch (error) {
            Alert.alert(error.message);
        }
    }

    openStream = () => {
        console.log(this.props.event.invitations)
        EventService.getDetails(this.props.event.id).then(res => res.json()).then(json => {
            const eventDetail = json.event_details
            const user_email = this.props.user.email;
            const participant_emails = eventDetail.participants.filter((v, i) => (v.email == user_email))
            if (participant_emails.length > 0) {
                this.props.navigation.navigate('LiveStream', { name: eventDetail.title, 'eventDetail': eventDetail, 'eventID': eventDetail.id, isParticipant: true });
            }
            else {
                this.props.navigation.navigate("Viewer", { viewerName: this.props.user.email, eventID: eventDetail.code });
            }
        }).catch(err => {
            Alert('Failed!!', 'Opps, something went wrong, please try again later')
        })

    }

    componentDidMount() {
        this.setState({ status: this.getInviteStatus() })
    }

    render() {
        const windowWidth = Dimensions.get('window').width;
        const windowHeight = Dimensions.get('window').height;
        let containerStyle = {padding:0,marginBottom:20}
        console.log("Widh: ",windowWidth)
        if(windowWidth/500 >= 3){
            containerStyle['flexBasis'] = '31%' 
        }
        else{
            if(windowWidth/300 >= 2){
                containerStyle['flexBasis'] = '45%' 
            }
            else{
                containerStyle['flexBasis'] = '90%' 
            }
        }

        return (

            <Card backgroundColor="#d3dbdf" containerStyle={{ padding: 0 }} >

                {this.isHost() ? (<View>
                    <TouchableOpacity style={{ flex: 1 }} onPress={e => { this.handleEventView(this.props.event.id) }}><Image source={this.props.event.cover ? { uri: imagePath+ "/" + this.props.event.cover } : placeholder} resizeMode="cover" style={styles.cardImage} /></TouchableOpacity>
                    <Text style={styles.eventTitle}>{this.props.event.title}</Text>
                    <Text style={[styles.eventDate, { marginBottom: 10, fontSize: 14 }]}>Event ID: #{this.props.event.code}</Text>
                    <Text style={styles.eventDate}>{formatDate(this.props.event.event_time, false, ' ', true, 12)}</Text>
                    <ScrollView horizontal={true} style={{ flexDirection: 'row', paddingVertical: 20, paddingHorizontal: 10 }}>
                        {this.props.event.invitations.map((v, i) => (
                            <TouchableOpacity
                                key={i}
                                style={[{ backgroundColor: randomColor() }, styles.participant]}>
                                <Text style={{ color: "#FFF" }}>{getInitials(v.name)}</Text>
                            </TouchableOpacity>
                        ))}

                    </ScrollView>
                </View>) :

                    (<Swipeable
                        ref={o => this.Swipeable = o}
                        renderRightActions={this.renderRightActions}
                        rightThreshold={25}
                        overshootLeft={false}
                    >
                        <View style={{
                            flex: 1,
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                            backgroundColor: 'white',
                        }}>
                            <TouchableOpacity style={{ flex: 1 }} onPress={e => { this.openStream() }}><Image source={this.props.event.cover ? { uri: imagePath + this.props.event.cover } : placeholder} resizeMode="cover" style={styles.cardImage} /></TouchableOpacity>
                            <Text style={styles.eventTitle}>{this.props.event.title}</Text>
                            <Text style={[styles.eventDate, { marginBottom: 10, fontSize: 14 }]}>Event ID: #{this.props.event.code}</Text>
                            <Text style={styles.eventDate}>{formatDate(this.props.event.event_time, false, ' ', true, 12)}</Text>
                            {this.isInvited() &&
                                <View style={styles.invited}>
                                    {this.state.status == 'not_accepted' ?
                                        <Button
                                            disabledStyle={{ backgroundColor: color3 }}
                                            disabledTitleStyle={{ color: "#FFF" }}
                                            containerStyle={{ padding: 0, margin: 0 }}
                                            buttonStyle={{ paddingHorizontal: 8, paddingVertical: 3, borderRadius: 15 }}
                                            titleStyle={{ fontSize: 12, fontFamily: "Roboto_400Regular" }}
                                            title='INVITED YOU' disabled type="solid" /> :
                                        this.state.status.toUpperCase() == 'ACCEPTED' ? <Button
                                            disabledStyle={{ backgroundColor: successColor }}
                                            disabledTitleStyle={{ color: "#FFF" }}
                                            containerStyle={{ padding: 0, margin: 0 }}
                                            buttonStyle={{ paddingHorizontal: 8, paddingVertical: 3, borderRadius: 15 }}
                                            titleStyle={{ fontSize: 12 }}
                                            title={this.state.status.toUpperCase()} disabled type="solid" /> :
                                            <Button
                                                disabledStyle={{ backgroundColor: '#F051B9' }}
                                                disabledTitleStyle={{ color: "#FFF" }}
                                                containerStyle={{ padding: 0, margin: 0 }}
                                                buttonStyle={{ paddingHorizontal: 8, paddingVertical: 3, borderRadius: 15 }}
                                                titleStyle={{ fontSize: 12, fontFamily: "Roboto_400Regular" }}
                                                title={this.state.status.toUpperCase()} disabled type="solid" />
                                    }
                                    {this.isHost() && <TouchableOpacity onPress={this.share}>
                                        <Icon type="ionicon" name="md-redo" reverse color={secondaryColor} size={12} />
                                    </TouchableOpacity>}
                                </View>}
                            <ScrollView horizontal={true} style={{ flexDirection: 'row', paddingVertical: 20, paddingHorizontal: 10 }}>
                                {this.props.event.invitations.map((v, i) => (
                                    <TouchableOpacity
                                        key={i}
                                        style={[{ backgroundColor: randomColor() }, styles.participant]}>
                                        <Text style={{ color: "#FFF" }}>{getInitials(v.name)}</Text>
                                    </TouchableOpacity>
                                ))}

                            </ScrollView>
                            <View style={{ justifyContent: "center", alignItems: "center" }}>
                                <View style={{ borderColor: primaryColor, justifyContent: "center", padding: 5, alignItems: "center", flexDirection: "row", borderWidth: 1, marginVertical: 5, borderRadius: 5 }}>
                                    <Icon style={{ fontWeight: "bold" }} type="iocnicon" name="arrow-back" size={12} /><Text style={{ color: primaryColor, fontSize: 12, fontFamily: "Roboto_700Bold" }}>SWIPE LEFT TO REPLY OR ACCEPT</Text>
                                </View>
                            </View>
                        </View>
                    </Swipeable>)
                }

            </Card>

        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getEvents: () => dispatch(getEvents()),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EventItem);

const styles = StyleSheet.create({
    eventTitle: {
        color: color5,
        margin: 0,
        fontFamily: '"Roboto","Helvetica Neue", sans-serif',
        paddingVertical: 8,
        fontSize: 24,
        paddingHorizontal: 20
    },
    invited: {
        marginTop: 10,
        marginLeft: 20,
        marginRight: 15,
        flexDirection: "row",
        justifyContent: "space-between"
    },
    eventDate: {
        color: color5,
        margin: 0,
        fontSize: 12,
        paddingHorizontal: 20
    },
    participant: {
        borderWidth: 1,
        borderColor: 'rgba(255,255,255,0.2)',
        alignItems: 'center',
        justifyContent: 'center',
        width: 50,
        height: 50,
        padding: 5,
        borderRadius: 50,
        marginRight: 10
    },
    cardImage: { width: '100%', alignSelf: "center", height: 250 },
    eventList: { backgroundColor: '#d3dbdf' }
}
)
