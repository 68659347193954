import React, { Component } from 'react';
import { Dimensions, Image, StyleSheet, View } from 'react-native';
import { Icon, Input } from 'react-native-elements';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { connect } from 'react-redux';
import Alert from '../components/Alert';
// import { OTSession, OTSubscriber, OTSubscriberView, OT } from 'opentok-react-native';
import { EventService } from '../services/events';
import EventNotStartedModal from './EventNotStartedModal';
import Text from '../components/WebWedText'

// import EmojiSelector, { Categories } from 'react-native-emoji-selector'
const OT = require('@opentok/client');
const d = Dimensions.get('screen')

class Viewer extends Component {

    state = {
        sessionID: '',
        apiKey: '',
        token: '',
        publishAudio: true,
        eventNotStarted: true,
        cameraPosition: 'back',
        videoSource: 'camera',
        totalViewers: 0,
        signal: {
            data: 0,
            value: 0,
            type: '',
        },
        text: '',
        messages: [],
        eventID: '',
        eventDetail: '',
        emoji: false,
        connections: {}
    }

    constructor(props) {
        super(props)
        this.vRef = React.createRef()
    }

    componentDidMount = () => {
        this.startSession()
    }

    startSession = () => {

        const eventID = this.props.route.params.eventID
        const viewerName = this.props.route.params.viewerName

        EventService.joinAsGuest(eventID, viewerName).then(res => res.json()).then(json => {
            if (json.status) {
                switch (json.status) {
                    case 2:
                        // this.setState({ eventNotStarted: true, eventDetail: json.event_details })
                        this.initSession(json, eventID)
                        this.props.navigation.setOptions({ title: json.event_details.title })
                        break;
                    case 3:
                        // you are not invited
                        // show modal with message event not started yet
                        Alert.alert("Not Invited", "Oops! seems you are not invited", [{
                            text: "Okay",
                            onPress: () => this.props.navigation.goBack(),
                            style: "cancel"
                        },
                        ])
                        break;
                    case 4:
                        // event is over
                        // show modal with message event is over
                        Alert.alert("Event Over", "Oh! you are late, event is over.", [{
                            text: "Cancel",
                            onPress: () => this.props.navigation.goBack(),
                            style: "cancel"
                        },
                        ])
                        break;
                    case 5:
                        // server error
                        // show alert and redirect to details page
                        Alert.alert("Failed!", "Something went wrong", [{
                            text: "Cancel",
                            onPress: () => this.props.navigation.goBack(),
                            style: "cancel"
                        },
                        ])
                        break;
                    default:
                        break;
                }
            }
            else {
                // console.log(json)
                this.initSession(json, eventID)
                this.props.navigation.setOptions({ title: json.event_details.title })

            }
        }).catch(err => {
            console.log(err)
        })


    }


    initSession = (json, eventID) => {
        let subidx = 0
        this.setState({
            sessionID: json.session_details.session_id,
            apiKey: json.session_details.key,
            token: json.session_details.token,
            eventID: eventID,
            eventDetail: json.event_details
        })
        this.session = OT.initSession(json.session_details.key, json.session_details.session_id);

        this.session.on('streamCreated', event => {
            const size = this.getSubscriberSize(subidx)
            const subs = this.session.subscribe(event.stream, 'subscribers', {
                insertMode: 'append',
                width: size.width,
                height: size.height
            }, err => console.log("Err", err));

            subidx += 1

            subs.on('connected', () => {

                this.setState({ totalViewers: this.state.totalViewers + 1, eventNotStarted: false })
            })

            subs.on('destroyed', () => {
                let eventNotStarted = false
                if (this.state.totalViewers == 1) {
                    eventNotStarted = true
                }
                this.setState({ totalViewers: this.state.totalViewers - 1, eventNotStarted: eventNotStarted })

            })
        });


        this.session.on('signal', event => {
            if (event.data) {
                if (event.data == 'EVENTCOMPLETE') {
                    this.props.navigation.replace('EventCompleted', { eventID: this.state.eventDetail.id })
                }
            }
        })

        this.session.connect(json.session_details.token, (error) => {
            // If the connection is successful, publish to the session
            if (error) {
                console.log(error);
            }
        });
    }

    startTimeout = eventDetail => {
        const remainingSec = parseInt(eventDetail.package.allotted_time) * 60 - eventDetail.consumed_seconds
        setTimeout(() => {
            this.session.signal({
                type: 'EVENTCOMPLETE',
                data: 'EVENTOVER',
            }, err => {
                if (err) {
                    console.log("Signal failed...")
                }
            })
        }, remainingSec * 1000)
    }

    getSubscriberSize = (idx) => {
        const dim = Dimensions.get('window')

        switch (this.state.totalViewers) {
            case 1:
            case 0:
                return { width: dim.width, height: dim.height, rowBasis: '100%' }
            case 2:
                return { width: dim.width, height: dim.height / 2, rowBasis: '100%' }
            case 3:
                if (idx == 0) {
                    return { width: dim.width, height: dim.height / 2, rowBasis: '100%' }
                }
                else {
                    return { width: dim.width / 2, height: dim.height / 2, rowBasis: '50%' }
                }
            case 4:
                return { width: dim.width / 2, height: dim.height / 2, rowBasis: '50%' }
            case 5:
                if (idx < 2) {
                    return { width: dim.width / 2, height: dim.height / 2, rowBasis: '50%' }
                }
                else {
                    return { width: dim.width / 3, height: dim.height / 2, rowBasis: '33%' }
                }
            case 6:
                return { width: dim.width / 3, height: dim.height / 3, rowBasis: '33%' }
            case 7:
            case 8:
            case 9:
                return { width: dim.width / 3, height: dim.height / 3, rowBasis: '33%' }
            default:
                return { width: dim.width / 4, height: dim.height / 4, rowBasis: '25%' }

        }
    }

    getDim = (total, dim, idx) => {
        switch (total) {

            case 1:
                return { w: dim.width, h: dim.height }
                break

            case 2:
                return { w: dim.width, h: dim.height / 2 }
                break

            case 3:
                if (idx < 2) {
                    return { w: dim.width / 2, h: dim.height / 2 }
                }
                else {
                    return { w: dim.width, h: dim.height / 2 }
                }
                break

            case 4:
                return { w: dim.width / 2, h: dim.height / 2 }
                break

            case 5:
                if (idx < 3) {
                    return { w: dim.width / 3, h: dim.height / 2 }
                }
                else {
                    return { w: dim.width / 2, h: dim.height / 2 }
                }
                break

            case 6:
                return { w: dim.width / 3, h: dim.height / 2 }
                break

            case 7:
                if (idx < 6) {
                    return { w: dim.width / 3, h: dim.height / 3 }
                }
                else {
                    return { w: dim.width, h: dim.height / 3 }
                }
                break

            default:
                return { w: dim.width / 4, h: dim.height / 4 }
        }
    }

    sendSignal = () => {
        if (this.state.text) {

            this.session.signal({
                type: 'msg',
                data: {
                    message: this.state.text,
                    sentTime: new Date()
                }
            }, error => {
                if (error) {
                    console.log('Error sending signal:', error.name, error.message);
                }
            });
            this.setState({
                text: '',
            });
        }
    }

    sendEmoji = emoji => {
        this.setState({
            emoji: false,
            signal: {
                type: 'emoji',
                data: emoji,
                sentTime: new Date()
            }
        });
    }

    componentWillUnmount() {
        this.session?.disconnect()
    }

    render() {

        return (
            <View style={{ flex: 1, position: "relative" }} ref={this.vRef} collapsable={false}>

                {this.state.sessionID.length > 0 && (

                    <View nativeID="subscribers" style={{ left: 0, right: 0, top: 0, bottom: 0, position: "absolute" }}></View>
                )}

                {this.state.eventNotStarted && <EventNotStartedModal eventDetail={this.state.eventDetail} />}
                {!this.state.eventNotStarted && <View style={{ position: "absolute", top: 30, left: 10, flexDirection: "row" }}>
                    <Icon color="white" size={18} type="material-icon" name="group" /><Text style={{ color: "#FFF", marginLeft: 5 }}>
                        {parseInt(this.state.totalViewers)}</Text>
                </View>}
                {!this.state.eventNotStarted &&
                    <View style={styles.control}>

                        <View style={{ position: "absolute", top: 0, right: 10, width: 60, height: 60, flexDirection: "row" }}>
                            <Image source={require('../assets/images/webwedlogo.png')} style={{ width: 60, height: 60 }} resizeMode="center" />
                        </View>

                        <View>
                            <View>
                                {this.state.messages.map((v, i) => {
                                    return <View key={i} style={v.type == 'emoji' ? { backgroundColor: "transparent", padding: 10, margin: 10 } : { backgroundColor: "rgba(90,171,211,0.3)", padding: 10, borderRadius: 10, margin: 10 }}>
                                        {v.type == 'emoji' ? <Text style={{ color: "#FFF", fontSize: 64 }}>{v.data}</Text> : <Text style={{ color: "#FFF" }}>{v.data}</Text>}
                                    </View>
                                })}
                            </View>
                            <Input
                                placeholder="Type your message"
                                onSubmitEditing={this.sendSignal}
                                value={this.state.text}
                                disabled={false}
                                inputContainerStyle={{ backgroundColor: "#FFF", borderRadius: 10, padding: 5 }}
                                inputStyle={{ zIndex: 100 }}
                                onChangeText={t => this.setState({ text: t })}
                                rightIcon={<TouchableOpacity><Icon type="material-community" name="sticker-emoji" onPress={() => { this.setState({ emoji: true }) }} color="#5aabd3" /></TouchableOpacity>} />
                        </View>
                        {this.state.emoji &&
                            <EmojiSelector
                                // showSearchBar={true}
                                showTabs={true}
                                showHistory={true}
                                // showSectionTitles={true}
                                onEmojiSelected={this.sendEmoji} />
                        }
                    </View>}
            </View>
        )
    }
}


const mapStateToProps = state => {
    return {
        user: state.user,
        publishVideo: state.publishVideo
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Viewer);

const styles = StyleSheet.create(
    {
        container: { flex: 1, alignItems: "center" },
        subscriber: { width: 100, height: 100 },
        control: {
            backgroundColor: 'transparent',
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 10000
        }
    }
)