import React, { Component } from 'react'
import { ActivityIndicator, Modal, View } from 'react-native'
import Text from '../components/WebwedText'
import { color1, color3 } from '../configs/themeOptions'

export default class LoadingModal extends Component {
    render() {
        const spinColor = this.props.spinColor ? this.props.spinColor : color3;
        const title = this.props.title ? this.props.title : 'Please wait...';
        return (
            <Modal
                transparent={true}
                animationType="slide"
            >
                <View style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "#000", opacity: 0.5 }}>
                    <ActivityIndicator size="large" color={spinColor} />
                    <Text style={[{ color: color3 }, this.props.titleStyle]}>{title}</Text>
                </View>
            </Modal>
        )
    }
}
