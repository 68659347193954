import React, { Component } from 'react';
import { Image, Share, StyleSheet, View } from 'react-native';
import { Button, Card, Icon } from 'react-native-elements';
import { ScrollView, TouchableOpacity } from 'react-native-gesture-handler';
import { connect } from 'react-redux';
import { primaryColor, secondaryColor, successColor } from "../configs/themeOptions";
import { APP_BASE } from '../services/masters';
import { getEventDetail } from '../stores/actions';
import { formatDate, getInitials, randomColor } from '../utils/convrters';
import Alert from './Alert';
import Text from '../components/WebWedText'

const placeholder = require('../assets/images/placeholder.png');

class ArchiveItem extends Component {

    openArchive = () => {
        const eventID = this.props.event.id;
        // this.props.getEventDetail(eventID);
        
        this.props.navigation.push('PlayArchive', { title:this.props.event.title, image: this.props.event.cover, name: this.props.event.title, eventID: eventID })


        // this.props.navigation.push('EventDetailTabs', { name: this.props.event.title, screen: 'Details', params: { eventID: eventID } });
    }

    isInvited = () => {
        return this.props.event.host.uid != this.props.user.uid && this.props.event.invitations.some((v) => (v.email == this.props.user.email))
    }

    isHost = () => {
        return this.props.event.host.uid == this.props.user.uid
    }

    hasNotResponded = () => {
        return this.props.event.host.uid != this.props.user.uid && this.props.event.invitations.some((v) => (v.email == this.props.user.email && v.invite_status == 'EventInvites.not_accepted'))
    }

    getInviteStatus = () => {
        const invited = this.props.event.invitations.filter(v => (v.email == this.props.user.email))
        if (invited.length > 0) {
            return invited[0]['invite_status'].split(".")[1]
        }
        else {
            return false;
        }
    }

    share = async () => {
        try {
            const result = await Share.share({
                message: this.props.event.title,
                url: APP_BASE + "/events/" + this.props.event.id,
            });
            if (result.action === Share.sharedAction) {
                if (result.activityType) {
                    // shared with activity type of result.activityType
                } else {
                    // shared
                }
            } else if (result.action === Share.dismissedAction) {
                // dismissed
            }
        } catch (error) {
            Alert.alert(error.message);
        }
    }

    render() {
        return (

            <Card backgroundColor="#d3dbdf" containerStyle={{ padding: 0 }} >

                {this.isHost() ? (<View>
                    <View style={{ position: "absolute", left: 0, right: 0, top: '25%', justifyContent: "center", alignItems: "center", zIndex: 1000 }}>
                        <TouchableOpacity onPress={this.openArchive}>
                            <Icon type="ionicon" name="md-play" size={32} color={primaryColor} reverse />
                        </TouchableOpacity>
                    </View>
                    <Image source={this.props.event.photo_url ? this.props.event.photo_url : placeholder} resizeMode="cover" style={styles.cardImage} />
                    <Text style={styles.eventTitle}>{this.props.event.title}</Text>
                    <Text style={styles.eventDate}>{formatDate(this.props.event.event_time, false, ' ', true, 12)}</Text>
                    <ScrollView horizontal={true} style={{ flexDirection: 'row', paddingVertical: 20, paddingHorizontal: 10 }}>
                        {this.props.event.invitations.map((v, i) => (
                            <TouchableOpacity
                                key={i}
                                style={[{ backgroundColor: randomColor() }, styles.participant]}>
                                <Text style={{ color: "#FFF" }}>{getInitials(v.name)}</Text>
                            </TouchableOpacity>
                        ))}

                    </ScrollView>
                </View>) :

                    (<>
                        <View style={{ position: "absolute", left: 0, right: 0, top: '25%', justifyContent: "center", alignItems: "center", zIndex: 1000 }}>
                            <TouchableOpacity onPress={this.openArchive}>
                                <Icon type="ionicon" name="md-play" size={32} color={primaryColor} reverse />
                            </TouchableOpacity>
                        </View>
                        <Image source={this.props.event.photo_url ? this.props.event.photo_url : placeholder} resizeMode="cover" style={styles.cardImage} />
                        <Text style={styles.eventTitle}>{this.props.event.title}</Text>
                        <Text style={styles.eventDate}>{formatDate(this.props.event.event_time, false, ' ', true, 12)}</Text>
                        {this.isInvited() &&
                            <View style={styles.invited}>
                                {this.hasNotResponded() ?
                                    <Button
                                        containerStyle={{ padding: 0, margin: 0 }}
                                        buttonStyle={{ padding: 5 }}
                                        titleStyle={{ fontSize: 12 }}
                                        title="INVITED YOU"
                                        disabled
                                        type="clear" /> :
                                    this.getInviteStatus().toUpperCase() == 'ACCEPTED' ? <Button
                                        disabledStyle={{ backgroundColor: successColor }}
                                        disabledTitleStyle={{ color: "#FFF" }}
                                        containerStyle={{ padding: 0, margin: 0 }}
                                        buttonStyle={{ paddingHorizontal: 8, paddingVertical: 3, borderRadius: 15 }}
                                        titleStyle={{ fontSize: 12 }}
                                        title={this.getInviteStatus().toUpperCase()} disabled type="solid" /> :
                                        <Button
                                            disabledStyle={{ backgroundColor: '#F051B9' }}
                                            disabledTitleStyle={{ color: "#FFF" }}
                                            containerStyle={{ padding: 0, margin: 0 }}
                                            buttonStyle={{ paddingHorizontal: 8, paddingVertical: 3, borderRadius: 15 }}
                                            titleStyle={{ fontSize: 12 }}
                                            title={this.getInviteStatus().toUpperCase()} disabled type="solid" />
                                }
                                <TouchableOpacity onPress={this.share}>
                                    <Icon type="ionicon" name="md-redo" reverse color={secondaryColor} size={12} />
                                </TouchableOpacity>
                            </View>}
                        <ScrollView horizontal={true} style={{ flexDirection: 'row', paddingVertical: 20, paddingHorizontal: 10 }}>
                            {this.props.event.invitations.map((v, i) => (
                                <TouchableOpacity
                                    key={i}
                                    style={[{ backgroundColor: randomColor() }, styles.participant]}>
                                    <Text style={{ color: "#FFF" }}>{getInitials(v.name)}</Text>
                                </TouchableOpacity>
                            ))}

                        </ScrollView>

                    </>)
                }

            </Card>

        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getEventDetail: eventID => dispatch(getEventDetail(eventID))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ArchiveItem);

const styles = StyleSheet.create({
    eventTitle: {
        color: "#043f7b",
        margin: 0,
        fontWeight: "bold",
        paddingVertical: 8,
        fontSize: 24,
        paddingHorizontal: 20
    },
    invited: {
        marginTop: 10,
        marginLeft: 20,
        marginRight: 15,
        flexDirection: "row",
        justifyContent: "space-between"
    },
    eventDate: {
        color: "#043f7b",
        margin: 0,
        paddingHorizontal: 20
    },
    participant: {
        borderWidth: 1,
        borderColor: 'rgba(255,255,255,0.2)',
        alignItems: 'center',
        justifyContent: 'center',
        width: 50,
        height: 50,
        padding: 5,
        borderRadius: 50,
        marginRight: 10
    },
    cardImage: { width: '100%', alignSelf: "center",height:250 },
    eventList: { backgroundColor: '#d3dbdf' }
}
)