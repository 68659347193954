import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
import { primaryColor } from '../configs/themeOptions';
import Text from '../components/WebWedText'
import { RFValue } from "react-native-responsive-fontsize";


export default class PackageListItem extends Component {

    minToHr(minutes) {
        const hours = Math.floor(minutes / 60);
        const remaining_minutes = minutes % 60;
        let hour_minutes = ""
        if (hours > 0) {

            hour_minutes = hours + " Hour"+(hours>1 ? 's ':' ');
        }
        if (remaining_minutes > 0) {
            hour_minutes += remaining_minutes + " Minutes";
        }
        return hour_minutes;
    }

    render() {
        return (
            <View style={styles.container}>
                <Text style={[styles.text, { color: this.props.color,alignSelf:"center",fontSize:RFValue(12,768),textAlign:"center" }]}>{this.props.package.title}</Text>
                <Text style={[styles.text, { color: this.props.color,fontSize:12 }]}>({this.minToHr(this.props.package.allotted_time)})</Text>
                <Text style={[styles.text, { color: this.props.color }]}>${this.props.package.price}</Text>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    text: {
        color: primaryColor,
        fontSize: 16
    }
})