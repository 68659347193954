import * as firebase from 'firebase';

export const legalURL = "https://webwedmobile.com/legal/";
export const API_BASE = "https://hbb4ols941.execute-api.us-east-1.amazonaws.com/prod"
export const APP_BASE = "https://webapp.webwedmobile.com"
export const imagePath = 'https://webwedmobile-app.s3.amazonaws.com'
export const joinURL = 'https://manage.webwedmobile.com/join/'

export const MastersService = {
    fetchPackageAddons: async () => {
        
        const token = await firebase.auth().currentUser.getIdTokenResult();
        return fetch(API_BASE + "/package-addons/", {
            method: "GET",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token.token

            }
        })
    },
    officiants: async () => {
        const token = await firebase.auth().currentUser.getIdTokenResult();

        return fetch(API_BASE + "/officiants", {
            method: "GET",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token.token
            }
        })
    },
    getRoles: async () => {
        const token = await firebase.auth().currentUser.getIdTokenResult();

        return fetch(API_BASE + "/roles", {
            method: "GET",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token.token
            }
        })
    },
    getCourts: async () => {
        const token = await firebase.auth().currentUser.getIdTokenResult();

        return fetch(API_BASE + "/courts", {
            method: "GET",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token.token
            }
        })
    },
}
