import React, { Component } from 'react'
import { View } from 'react-native'
// import VideoPlayer from 'react-native-video-controls'

export default class PlayRecord extends Component {
    render() {
        console.log(this.props.route.params.uri)
        return (
            <View style={[{ width: '100%', height: '100%' }]}>
                <VideoPlayer
                    onBack={() => this.props.navigation.goBack()}
                    fullscreen
                    navigator={this.props.navigator}
                    source={{ uri: this.props.route.params.uri }}
                />
            </View>
        )
    }
}
