import AsyncStorage from "@react-native-community/async-storage"
import { API_BASE } from "./masters";
import * as firebase from 'firebase';
import { Storage } from "./storage";
import { EventService } from "./events";

export const UserService = {
    createProfile: async (user) => {
        const uid_token = await firebase.auth().currentUser.getIdTokenResult();
        return fetch(API_BASE + "/profile", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + uid_token.token
            },
            method: 'POST',
            body: JSON.stringify(user)
        })
    },

    updateUserProfile: async user => {
        const uid_token = await firebase.auth().currentUser.getIdTokenResult();

        return fetch(API_BASE + "/profile", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + uid_token.token
            },
            method: 'PUT',
            body: JSON.stringify(user)
        })
    },

    getProfile: async () => {
        try {
            const uid_token = await firebase.auth().currentUser.getIdTokenResult();
            
            // const uid_token = '';
            return fetch(API_BASE + "/profile",
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + uid_token.token
                    }
                },
            )
            // Storage.setProfile(json.user);
        }
        catch (e) {
            console.log("Err...",e)
            return e;
        }
    },
    signOut: () => {
        return firebase.auth().signOut();
    },
    getOrders: async () => {
        const uid_token = await firebase.auth().currentUser.getIdTokenResult();
        return fetch(API_BASE + "/orders", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + uid_token.token
            }
        })
    },
    getNotifications: async () => {
        const uid_token = await firebase.auth().currentUser.getIdTokenResult();
        return fetch(API_BASE + "/notifications", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + uid_token.token
            }
        })
    }
}