import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
import WebwedLogo from '../components/WebwedLogo'
import {  Icon, Button } from 'react-native-elements'
import Text from '../components/WebWedText'
import { color4, color5, primaryColor } from '../configs/themeOptions'
import AnimatingHeart from '../components/AnimatingHeart'

export default class EventCompleted extends Component {
    close = () => {
        this.props.navigation.replace('Dashboard')
    }
    render() {
        return (
            <View style={styles.container}>
                <WebwedLogo />
                <View style={{ alignItems: "center" }}>
                    <Text style={{ color: "#FFF", marginBottom: 10,fontSize:32 }}>Congratulations!</Text>
                    <Text style={{ color: "#FFF" }}>Appy Ever After.</Text>
                    <View style={{ margin: 20 }}>
                        <AnimatingHeart />
                    </View>
                </View>
                <View style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
                    <Button buttonStyle={{ backgroundColor: "#075193",padding:15 }} title="Close" onPress={this.close} />
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: color4,
        justifyContent: "center",
        alignItems: "center"
    }
})