import React, { Component } from 'react'
import { View, Linking } from 'react-native'
import { MastersService, legalURL } from '../services/masters'
import { ListItem } from 'react-native-elements'
import { connect } from 'react-redux'
import Text from '../components/WebWedText'

class MarriageLicense extends Component {
    render() {
        return (
            <View style={{ flex: 1 }}>
                <Text style={{ margin: 20 }}>
                    You must complete all required documentation in your marriage application prior to being permitted to use this feature. Please read the full
                    <Text style={{ color: "#023C6F", fontSize: 16 }} onPress={() => { Linking.openURL(legalURL) }}> Terms of service</Text> before completing.
                </Text>
                {this.props.eventDetail.application.hisotry && <ListItem title={this.props.eventDetail.application.history[0].action} subtitle={this.props.eventDetail.application.history[0].created_at} bottomDivider />}

            </View>
        )
    }
}

const mapStateToProps = state => {
    return {
        eventDetail: state.eventDetail
    }
}


export default connect(mapStateToProps, null)(MarriageLicense);