import React, { Component } from 'react'
import { ScrollView } from 'react-native'
import { Image, View } from 'react-native'
import { ListItem } from 'react-native-elements'
import LoadingModal from '../components/LoadingModal'
import { UserService } from '../services/user'
import { formatDate } from '../utils/convrters'

const default_icon = require('../assets/images/notification_icon.png');
class NotificationsList extends Component {

    state = { notifications: false }
    componentDidMount() {
        UserService.getNotifications().then(res => res.json()).then(json => {
            // console.log(json)
            this.setState({ notifications: json.notifications })
        }).catch(err => console.log(err))
    }

    render() {

        return (
            <ScrollView>
                <View style={{ flex: 1, marginBottom: 20 }}>
                    {this.props.loading && <LoadingModal />}
                    {this.state.notifications && this.state.notifications.map((v, i) => (<ListItem key={i} bottomDivider>
                        <Image source={default_icon} style={{ width: 50, height: 50, borderRadius: 25 }} />
                        <ListItem.Content>
                            <ListItem.Title style={{ fontFamily: '"Roboto","Helvetica Neue", sans-serif' }}>
                                {v.title}
                            </ListItem.Title>
                            <ListItem.Subtitle style={{ fontFamily: '"Roboto","Helvetica Neue", sans-serif' }}>
                                {v.body}
                            </ListItem.Subtitle>
                        </ListItem.Content>
                        <ListItem.Subtitle style={{ fontFamily: '"Roboto","Helvetica Neue", sans-serif' }}>
                            {formatDate(v.created_at)}
                        </ListItem.Subtitle>
                    </ListItem>))}
                </View>
            </ScrollView>
        )
    }
}

export default NotificationsList
