import React, { Component } from 'react';
import { Image, ScrollView, View } from 'react-native';
import { Icon, ListItem } from 'react-native-elements';
import { connect } from 'react-redux';
import Alert from '../components/Alert';
import LoadingModal from '../components/LoadingModal';
import { EventService } from '../services/events';


class ImportContact extends Component {
    state = { contacts: [], roles: [], role: {}, participantName: '', participantEmail: '', phone: '',is_participant:false }

    remainingRoles = () => {
        let roles = ["participant1", "participant2", "bride", "groom"];

        const existing_roles = this.props.eventDetail.participants.map((v, i) => (v.role));
        for (let i = 0; i < existing_roles.length; i++) {
            const index = roles.indexOf(existing_roles[i]);
            if (index > -1) {
                roles.splice(index, 1);
            }
        }
        this.setState({ roles: roles })
    }

    componentDidMount = () => {
        this.setState({ contacts: this.props.route.params.contacts,is_participant:this.props.route.params.is_participant })
    }

    selectContact = contact => {
        const phone = contact.phoneNumbers ? contact.phoneNumbers[0].number : '';
        
        const guest = {
            name: contact.firstName + " " + contact.lastName,
            email: contact.emails[0].email,
            phone: phone,
            is_participant: this.state.is_participant
        }
        this.saveInvite(guest)
    }
    saveInvite = (guest) => {
        const eventID = this.props.eventDetail.id
        
        if (this.state.role != 'select role') {
            guest["role"] = this.state.roles[0];
        }

        EventService.inviteGuest(eventID, guest).then(res => res.json()).then(json => {
            if (json.message) {

                let eventDetail = this.props.eventDetail;
                this.props.getEventDetail(eventDetail.id);
                this.remainingRoles();
                Alert.alert("Success!", json.message, [
                    { text: "Okay", onPress: () => this.props.navigation.goBack() }
                ]);


            }
            else {
                Alert.alert("Error!", json.error);
                this.props.navigation.goBack()
            }
        }).catch(err => {
            Alert.alert("Oops!", `Something was not expected ${err}`);
        })
    }
    render() {
        
        return (
            <View>
                <ScrollView style={{ backgroundColor: "#f1f1f1" }}>
                    <View>
                        <View>
                            {this.state.loading ? (<LoadingModal title="Please wait..." />) : this.state.contacts.map((v, i) => {
                                return (<ListItem
                                    key={i}
                                    title={v.firstName + " " + v.lastName}
                                    subtitle={v.emails ? v.emails[0].email : ''}
                                    rightAvatar={v.imageAvailable ? <Image source={{ uri: v.thumbnailPath }} /> : <Icon type="ionicon" name="md-arrow-dropright" />}
                                    onPress={() => { this.selectContact(v) }}
                                    bottomDivider />)
                            })}

                        </View>
                    </View>
                </ScrollView>
            </View>
        )
    }
}

const mapStateToProps = state => {
    return {
        eventDetail: state.eventDetail
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getEventDetail: eventID => dispatch(getEventDetail(eventID))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ImportContact)