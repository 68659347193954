import React, { Component } from 'react'
import { StyleSheet } from 'react-native'
import { ListItem } from 'react-native-elements'
import { ScrollView } from 'react-native'
import { connect } from 'react-redux'
import { getOrders } from '../stores/actions'
import { formatDate } from '../utils/convrters'
import { View } from 'react-native'
import WebWedText from '../components/WebwedText'
import { color1, color4, color5 } from '../configs/themeOptions'

class OrdersTab extends Component {

  gotoDetails = (v) => {
    this.props.navigation.push('Orders', { order: v, name: v.event.title });
  }

  componentDidMount() {
    this.props.getOrders()
  }
  render() {
    const orders = this.props.orders;
    return (
      <ScrollView style={styles.container}>
        {orders && orders.map((v, i) => (

          <ListItem key={i} bottomDivider onPress={() => { this.gotoDetails(v) }}>
            <ListItem.Content>
              <ListItem.Title style={{fontFamily:'"Roboto","Helvetica Neue", sans-serif'}}>
                {v.event.title}
              </ListItem.Title>
              <ListItem.Subtitle style={{fontFamily:'"Roboto","Helvetica Neue", sans-serif'}}>{formatDate(v.created_at)}</ListItem.Subtitle>
            </ListItem.Content>
            <ListItem.Subtitle style={{fontFamily:'"Roboto","Helvetica Neue", sans-serif'}}>
              {"$ " + v.txn_price}
            </ListItem.Subtitle>
          </ListItem>))}
          {orders.length == 0 && <View style={{flex:1,padding:10,justifyContent:'center'}}>
            <WebWedText style={{color: "tomato" }}>No orders yet!</WebWedText>
            </View>}
      </ScrollView>
    )
  }
}

const styles = StyleSheet.create({
  title: {
    padding: 10,
    fontSize: 18,
    color: "#023C6F"
  },
  container: {
    flex: 1
  }
})

const mapStateToProps = state => {
  return {
    orders: state.orders
  }
}

const maptDispatchToProps = dispatch => {
  return {
    getOrders: () => dispatch(getOrders())
  }
}

export default connect(mapStateToProps, maptDispatchToProps)(OrdersTab);
