import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
import {Image} from 'react-native-elements'

export default class WebwedLogo extends Component {
    render() {
        return (
            <View style={styles.container}>
                <Image source={require('../assets/images/webwedlogo.png')} resizeMode="contain" style={{width:110,height:52}} />
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {flexDirection:"row", justifyContent: "center", alignItems: "center", marginTop: 40 },
    img: {
        width: 100,
    }
})