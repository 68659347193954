import React, { Component } from 'react';
import { Platform, View } from 'react-native';
import { Button } from 'react-native-elements';
import { WebView } from 'react-native-webview';
import { connect } from 'react-redux';
import Alert from '../components/Alert';
import { EventService } from '../services/events';
import Text from '../components/WebWedText'




class MarriageEducation extends Component {
    state = {
        url: ''
    }

    handleTextChange = v => {
        this.setState({ url: v, err: !v ? "link required" : '' })
    }

    submitApplication = () => {
        EventService.preMaritalEducationCertificate(this.props.eventDetail.id).then(res => res.json()).then(json => {
            Alert.alert("Success!!", json.message);
            this.props.onSubmitCompletion();
        }).catch(err => {
            Alert.alert("Failed!!", "Oops!! something went wrong");
        })
    }

    render() {

        return (
            <View style={{ flex: 1 }}>
                <View style={{ margin: 10 }}>
                    <Text>Watch the video series by clicking the link below. When you complete the video training you will be awarded your certification for completing your free training.</Text>
                </View>
                <View style={{ flex: 1, marginBottom: 20 }}>
                    {Platform.OS !== 'web' && <WebView
                        style={{ flex: 1, justifyContent: "center" }}
                        javaScriptEnabled={true}
                        source={{ uri: 'https://www.youtube.com/embed/j6E2FHVcawE' }}
                    />}

                    {Platform.OS === 'web' && <iframe width="100%" height="100%" src='https://www.youtube.com/embed/j6E2FHVcawE'></iframe>}
                </View>

                <View style={{ marginTop: 20 }}>
                    <View style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
                        <Button title="Completed Training" disabled={this.state.err} buttonStyle={{ padding: 10 }} onPress={this.submitApplication} />
                    </View>
                </View>
            </View>

        )
    }
}

const mapStateToProps = state => {
    return {
        eventDetail: state.eventDetail,
        loading: state.loading
    }
}

export default connect(mapStateToProps, null)(MarriageEducation);
